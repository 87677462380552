import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='container-fluid justify-content-center'>
        <nav className='pull-left'>
          <ul className='nav'>

          </ul>
        </nav>
        <div className='copyright'>
          {new Date().getFullYear()} Ministry of Finance &mdash;<Link to='/'> DigiPay</Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
