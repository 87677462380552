import React from 'react';
// import {Link} from 'react-router-dom'
import appConstants from '../../constants/constants'
import { downloadReports } from '../../state/reports/reportSlice';
import moment from 'moment'
import '../../assets/css/pagination.css'

const ReportTable = ({ data }) => {
  const	downloadData = (url) => {
		downloadReports(url);
	}

  const viewData = data => data.map((l, i) => l && (
  	<tr key={i}>
			<td>{l.report_type.toUpperCase()}</td>
			<td>{moment(l.created_at).format("YYYY/MM/DD")}</td>
			<td>
			<button type="button" onClick={(e) => {downloadData(l.file)}} className="btn btn-link" data-original-title="Remove"><i className="fa fa-download"></i></button>
			</td>
		</tr>
	))

	return(
	  <table id="basic-datatables" className="display table table-striped table-hover">
			<thead>
				<tr>
				 {appConstants.REPORT_COLUMNS.map((item) => <th>{item.title}</th>)}
				</tr>
			</thead>
			<tfoot>
				<tr>
				 {appConstants.REPORT_COLUMNS.map((item) => <th>{item.title}</th>)}
				</tr>
			</tfoot>
			<tbody>
				{viewData(data)}
			</tbody>
		</table>
	)
}
export default ReportTable
