
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import LandingPage from '../wrappers/landing-page'
import { userLogin } from '../state/users/userSlice';
import cookie from "react-cookies";
import { errorLog } from '../state/root/rootSlice';

export const ROUTE_OPEN = true

const LoginScreen = ({props = {}}) => {
  // const [open, toggleOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  // const loginSuccess = useSelector((state) => state.users.success)
  // const userData = useSelector((state) => state.users.currentUser)

  const passwordRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   console.log('UserSlice RESPONSE ', userData)
  //   if(loginSuccess) {
  //     const roles = userData.roles.map((r) => r.name);
  //     sessionStorage.setItem("user", JSON.stringify(userData));
  //     cookie.save(
  //       "rememberFinanceObj",
  //       JSON.stringify({ remember_token: userData.remember_token }),
  //       { path: "/" }
  //     );

  //     if (roles.includes("read") || roles.includes("agency_invoice")) {
  //       navigate("/invoices");
  //     } else if (userData.roles.length == 1 && userData.roles[0].name == "agency_cashier") {
  //       navigate("/invoices");
  //     } else if (userData.roles.length == 1 && userData.roles[0].name == "technology") {
  //       navigate("/revenue-line-items");
  //     } else {
  //       navigate("/dashboard");
  //     }
  //   } else {
  //     sessionStorage.clear()
  //   }
  // }, [loginSuccess, userData, navigate])

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(userLogin({email, password, remember_me: rememberMe})).unwrap()
      .then((res) => {
        // console.log('handleSubmit LOGIN', res);
        const user = res.data.user;
        const roles = res.data.user.roles.map((r) => r.name);
        sessionStorage.setItem("user", JSON.stringify(user));
        // console.log('UserSession', sessionStorage);
        cookie.save(
          "rememberFinanceObj",
          JSON.stringify({ remember_token: user.remember_token }),
          { path: "/" }
        );

        if (roles.includes("read") || roles.includes("agency_invoice")) {
          navigate("/invoices");
        } else if (user.roles.length == 1 && user.roles[0].name == "agency_cashier") {
          navigate("/invoices");
        } else if (user.roles.length == 1 && user.roles[0].name == "technology") {
          navigate("/revenue-line-items");
        } else {
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        // console.log('Cleared Session', err);
        dispatch(errorLog(err))
        sessionStorage.clear()
      })
  }

  const togglePasswordVisibility = () => {
    const input = passwordRef.current

    if (input) {
      const attr = input.getAttribute('type') == 'password' ? 'text' : 'password'
      input.setAttribute('type', attr)
    }
  }

  return (
    <LandingPage
      title={'Open for Business 24x7'}
      subtitle={"The subway system of The Bahamas' financial services."}
      // success_message={props.success_message}
      // error={props.error}
    >
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email" className="placeholder bg-white"><b>Email</b></label>
          <input id="email"
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            type="text"
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password" className="placeholder bg-white"><b>Password</b></label>
          <Link to={"/forget-password"} className="link float-end">Forget Password ?</Link>
          <div className="position-relative">
            <input
              ref={passwordRef}
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              className="form-control"
              required
            />
            <div className="show-password">
              <i className="fas fa-eye" onClick={() => togglePasswordVisibility()}></i>
            </div>
          </div>
        </div>
        <div className="form-group form-action-d-flex mb-3">
          <div className="custom-control custom-checkbox" style={{display: 'flex', alignItems: 'center'}}>
            <input type="checkbox"
              checked={rememberMe}
              onChange={e => setRememberMe(e.target.checked)}
              className="custom-control-inputx form-check-input"
              style={{marginRight: '6px'}}
              name="remember_me"
              id="remember_me"
            />
            <label className="custom-control-label m-0" htmlFor="remember_me">Remember Me</label>
          </div>
          <input type="submit" className="btn btn-secondary col-md-5 float-end mt-3 mt-sm-0 fw-bold" value="Sign In" />
        </div>
      </form>
    </LandingPage>
  )
}

// const mapStateToProps = state => ({
//   farmerUser: state.login,
//   error: state.error,
//   user: state.user,
//   success_message: state.success_message,
//   currentUser: state.currentUser,
// });

export default LoginScreen
