import React from 'react'

// import CoatOfArms from '@images/coat-of-arms.png'
import CoatOfArms from '../assets/images/coat-of-arms.png'

const COAT_STYLES = {
  width: '15rem',
  marginBottom: '4rem',
}

const props = ({title, subtitle, children}) => (
  <div className="wrapper wrapper-login wrapper-login-full p-0">
    <div className="login-aside w-50 d-flex flex-column align-items-center justify-content-center text-center bg-secondary-gradient">
    <img src={CoatOfArms} alt="The Bahamas' Coat Of Arms" className='block mx-auto' style={COAT_STYLES} />
      <h1 className="title fw-bold text-white mb-3">{title}</h1>
      <p className="subtitle text-white op-7">{subtitle}</p>
    </div>
    <div className="login-aside w-50 d-flex align-items-center justify-content-center bg-white">
      <div className="container container-login container-transparent animated fadeIn">
        <h5 className="text-center" style={{ color: '#116A41', marginBottom: '1.5rem' }}><i className="fas fa-tree"></i>&nbsp;<b>DigiPay</b></h5>

        <div className="login-form">{children}</div>
      </div>
    </div>
  </div>
)

export default props
