import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios";

const headers = () => {
  let user = sessionStorage.getItem("user");
  user = JSON.parse(user);
  return {
    "Content-Type": "application/json",
    "Authorization": user && user.auth_token
  };
}

const initialState = {
  authenticated: false,
  loading: false,
  error: undefined,
  data: null, //[],
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    errorLog: (state, action) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = undefined;
    },
    stopLoader: (state) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
    // Get Dashboard Data
      .addCase(getDbData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDbData.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Dashboard successfully loadded';
        // console.log(action);
        state.data = action.payload.dbData.data;
        state.dTransactions = action.payload.transactions.data.transactions;
      })
      .addCase(getDbData.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.data = [];
        state.dTransactions = [];
        // console.log('error', action);
        state.error = action.payload.error;
      })
  }
})

export const { clearError, stopLoader, errorLog } = dashboardSlice.actions
export default dashboardSlice.reducer;

// function getDashboardData() {
//   return axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/dashboards`,{headers: headers()})
// }

// function getRecentData() {
//   return axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/transactions/recent`,{headers: headers()})
// }

export const getDbData = createAsyncThunk(
  'dashboard/getDbData',
  async (_t, { rejectWithValue }) => {
    let dbRes
    let rdRes
    try {
      dbRes = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/dashboards`,{headers: headers()})
      // console.log(dbRes)
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }

    try {
      rdRes = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/transactions/recent`,{headers: headers()})
      // console.log(rdRes)
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }

    return { dbData: dbRes, transactions: rdRes }
  }
)

// export function getDbData() {
//   return function (dispatch) {
//     dispatch(getRequest())
//     axios.all([getDashboardData(), getRecentData()])
//     .then(axios.spread(function (dbData, recentData) {
//       dispatch(getDbDataSuccess({dbData: dbData.data, transactions: recentData.data.transactions}))
//     })).catch(error=> {
//         dispatch(createError(error))
//     })
//   };
// }
