import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cookie from 'react-cookies'

import AppRouter from './routes'
import MyAlert from '../shared/alert'
import Loader from '../shared/loader'
import Header from '../screens/_partials/header'
import Footer from '../screens/_partials/footer'
import SidePane from '../screens/_partials/sidebar'
import { listKeys } from '../helpers/extract-keys'

import { rememberToken } from '../state/users/userSlice'
import { useNavigate } from 'react-router'

const Main = () => {
  const [toggled, setToggled] = useState(false);
  const [topToggle, setTopToggle] = useState(false);
  const error = useSelector((state) => state.root.error)
  const successMessage = useSelector((state) => state.root.success_message)
  const rmLoading = useSelector((state) => state.users.rm_loading)
  const userFetch = useSelector((state) => state.users.currentUser)
  const userData = userFetch || (sessionStorage.user && JSON.parse(sessionStorage.user));
  // const rememberedUser = useSelector((state) => state.users.rememberedUser)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const page = document.documentElement
  const alert_key = listKeys('error', 'success_message').join(':')

  useEffect(() => {
    const rememberObj = cookie.load('rememberFinanceObj') || {}

    if (!sessionStorage.user && rememberObj?.remember_token) {
      dispatch(rememberToken(rememberObj.remember_token)).unwrap()
        .then(() => {
          sessionStorage.setItem("user", JSON.stringify(userData));
          navigate('/dashboard');
        })
        .catch(() => {
          cookie.remove("rememberFinanceObj", { path: "/" });
          navigate('/login');
        })
    }
  }, [dispatch, navigate, userData])

  useEffect(() => {
    if (error && typeof(error) == 'object' && error?.error?.toLowerCase() == 'unauthorized') { navigate('/logout'); }
    if (error && typeof(error) == 'string' && error?.toLowerCase() == 'unauthorized') { navigate('/logout'); }

    // console.log('err', error);
  }, [error, navigate])

  // useEffect(() => {
  //   if(rememberedUser === 'yes') {
  //     sessionStorage.setItem("user", JSON.stringify(userData));
  //     navigate('/dashboard');
  //   } else if(rememberedUser === 'no') {
  //     cookie.remove("rememberFinanceObj", { path: "/" });
  //     navigate('/login');
  //   }
  // }, [rememberedUser, navigate])


  const togglePanel = () => {
    setToggled(!toggled)
    page.classList.toggle('nav_open', !toggled)
  }

  const openTopBar = () => {
    setTopToggle(!topToggle )
    page.classList.toggle('topbar_open', !toggled)
  }

  const component = userData ? (
    <div className={`wrapper ${toggled ? 'sidebar_minimize' : ''}`}>
      <Header toggled={toggled} togglePanel={togglePanel} openTopBar={openTopBar} />
      <SidePane user={userData} toggled={toggled} topToggle={topToggle} />
      <div className="main-panel">
        <AppRouter user={userData}/>
        <Footer />
      </div>
    </div>
  ) : <AppRouter user={userData} />

  return rmLoading ? <Loader loading={true} /> : (
      <React.Fragment>
        <MyAlert key={alert_key} error={error} successMessage={successMessage} />
        {component}
      </React.Fragment>
    )
}

export default Main

