import React from 'react'
// import Swal from 'sweetalert2'

import appConstants from '../../constants/constants'
import { Link } from 'react-router-dom'
// import { voidTransaction, downloadTransactions } from '/state/transactions/transactionSlice';

import 'react-confirm-alert/src/react-confirm-alert.css'
import '../../assets/css/pagination.css'


// const UsersTable = ({ data, showTransaction }) => {
const UsersTable = ({ data }) => {
  const addActionData = item => (
    <div>
      <Link to={`edit?id=${item.id}`} className="btn btn-link btn-primary btn-lg" >
        <i className="fa fa-edit"></i>
      </Link>
      {/* <button type="button" onClick={(e) => { this.removeData(e.target.dataset.id) }} data-toggle="tooltip" title="" className="btn btn-link btn-danger" data-original-title="Remove">
        <i className="fa fa-times" data-id={item.id}></i>
      </button> */}
    </div>
  )

  const tableRows = data => data?.map((item) => item && (
      <tr key={item.id}>
        <td>{item.first_name}</td>
        <td>{item.last_name}</td>
        <td>{item.email}</td>
        <td>{item.company_name || ''}</td>
        <td>{addActionData(item)}</td>
      </tr>
    )
  )

  const headings = appConstants.USERS_COLUMNS.map((h, i) => (
    <th key={`head-${i}`}>{h.title}</th>
  ))

  return (
    <React.Fragment>

      <table id="basic-datatables" className="display table table-striped table-hover">
        <thead>
          <tr>{headings}</tr>
        </thead>
        <tfoot>
          <tr>{headings}</tr>
        </tfoot>
        <tbody>{tableRows(data)}</tbody>
      </table>
    </React.Fragment>
  )
}

export default UsersTable
