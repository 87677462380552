import React from 'react'

const Header = ({toggled, openTopBar, togglePanel}) => {
  // const [open, toggleOpen] = useState(false);
  // const user = sessionStorage.user && JSON.parse(sessionStorage.user)

  const logo = toggled
    ? <i style={{ color: 'white', marginLeft: 12, fontSize: 19 }} className='fas fa-tree'></i>
    : <h5 style={{ color: 'white' }}><i className='fas fa-tree'></i>&nbsp;<b>DigiPay</b></h5>

  return (
    <div className='main-header'>
      <div className='logo-header' style={{backgroundColor: '#00665d'}}>
        {logo}
        <button onClick={togglePanel} className='navbar-toggler sidenav-toggler ms-auto' type='button' data-toggle='collapse' data-target='collapse' aria-expanded='false' aria-label='Toggle navigation'>
          <span className='navbar-toggler-icon'>
            <i className='fas fa-bars'></i>
          </span>
        </button>
        <button onClick={openTopBar} className='topbar-toggler more'><i className='fas fa-ellipsis-v'></i></button>
        <div className='nav-toggle'>
          <button onClick={togglePanel} className={`btn btn-toggle toggle-sidebar ${toggled ? 'toggled' : ''}`}>
            <i className={toggled ? 'fas fa-ellipsis-v' : 'fas fa-bars'}/>
          </button>
        </div>
      </div>

      <nav className='navbar navbar-header navbar-expand-lg' style={{backgroundColor: '#00665d'}}>
        <div className='container-fluid'>
          <ul className='navbar-nav topbar-nav ms-md-auto align-items-center'>
            <li>
              <a href='/logout' style={{ color: 'white', fontSize: 20 }}>
                <i className='fas fa fa-sign-out-alt' aria-hidden='true'></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default Header;
