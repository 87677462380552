import React, { useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { ValidatorForm } from 'react-form-validator-core'

import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';

import { saveTransaction } from '../state/transactions/transactionSlice';
import { errorLog } from '../state/root/rootSlice';
import appConstants from '../constants/constants'

import formatPhoneNumber from '../helpers/normalize-phone'
import Loader from '../shared/loader';
import InputField from '../shared/form-fields/input';
import { useNavigate } from 'react-router';

const Field = ({ name, label, required, children }) => (
  <div className='form-group form-show-validation row'>
    <label
      htmlFor={name}
      className='col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end'
    >
      {label}
      {required !== false ? <span className="required-label">*</span> : null}
    </label>
    <div className='col-lg-4 col-md-9 col-sm-8'>
      {children}
    </div>
  </div>
)

const CreditCardPayment = () => {
  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps
  } = usePaymentInputs();

  const [form, setForm] = useState({ invoice_number: '', first_name: '', last_name: '', email: '', 'card-name': '' });
  const [card, setCard] = useState({
    values: {
      'card-amount': '',
      'card-number': '',
      'card-cvv': '',
      'card-exp': '',
    },
    valid: {
      'card-number': false,
      'card-cvv': false,
      'card-exp': false,
    },
    error: null
  });
  const [invoice, setInvoice] = useState({ long_desc: '' });
  // const [nonce, setNonce] = useState(0);
  const nonce = 0;
  const loading = useSelector(state => state.root.loading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // user = sessionStorage.user && JSON.parse(sessionStorage.user)
  // const successMessage = useSelector(state => state.root.success_message);
  const BASE_URL = process.env.REACT_APP_API_HOST + '/' + appConstants.API_V

  // componentDidUpdate ({ success_message }) {
  //   success_message && resetForm()
  // }
  // const resetForm = () => {
  //   setForm({ invoice_number: '', first_name: '', last_name: '', email: '', 'card-name': '' });
  //   setNonce(nonce + 1);
  // }

  // useEffect(() => {
  //   resetForm();
  // }, [successMessage])



  const getPropName = name => {
    switch (name) {
      case 'cardNumber':
        return 'card-number'
      case 'cardCVC':
        return 'card-cvv'
      case 'cardExpiry':
        return 'card-exp'
      default:
    }
  }

  const fetch = {
    token: async () => {
      try {
        const { data } = await axios.get(BASE_URL + '/fetch_guest_token')
        return data.token
      } catch (err) {
        dispatch(errorLog(err.response.data.error));
      }
    },

    invoice: async ({ target }) => {
      const Authorization = await fetch.token()

      try {
        const { data } = await axios.get(
          `${BASE_URL}/revenue_line_items/by_invoice_number/${target.value}`,
          { headers: { Authorization } }
        )

        const inv = data?.revenue_line_item

        if(inv){
          setCard({...card, values: { ...card.values, 'card-amount': data?.invoice_amount.toFixed(2)}});
          setInvoice(inv);
        }
      } catch (err) {
        dispatch(errorLog(err.response.data.error));
      }
    },
  }

  const on = {
    change: ({ target }) => {
      setForm({ ...form, [target.name]: target.value})
    },

    cc: name => ev => {
      const val = ev.target.value

      setCard({...card, values: { ...card.values, [name]: val }, valid: { ...card.valid, [name]: !!val } })
    },

    error: (err) => {
      setCard({ ...card, valid: { ...card.valid, [getPropName(err.inputName)]: false }, error: err});
    },

    submit: async (ev) => {
      ev.preventDefault()
      if (form.mobile_number)
        form.mobile_number = formatPhoneNumber(JSON.stringify(form.mobile_number))
      if (loading) return

      if (Object.values(card.values).every(v => !v)) {
        dispatch(errorLog('Please Enter Card Information'));
        // dispatch(errorLog({
        //   response: {
        //     data: {
        //       response: 'Please Enter Card Information'
        //     }
        //   }
        // }))
        return
      }

      if (Object.values(card.valid).some(v => !v)) return
      const data = {
        ...form,
        ...card.values,
        'transaction_mode': "agency",
        'third_party_type': "credit",
        'amount': parseFloat(card.values['card-amount']),
        'revenue_line_item_id': invoice.id,
      }

      dispatch(saveTransaction(data)).unwrap()
      .then(() => {
        navigate('/transactions')
      }).catch((err) => dispatch(errorLog(err)))
    },
  }

  const useDefaultEmail = () => {
    setForm({...form, email: 'digipayusers@bahamas.gov.bs'});
  }

  // const shouldForwardProp = (propName, target) => {
  //   if (typeof target === 'string') {
  //     return isPropValid(propName)
  //   }

  //   return true
  // }

  return (
    <React.Fragment >
      <Loader loading={loading} />
      <div className="content" key={nonce}>
        <div className="page-inner">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Debit/Credit Card</div>
                </div>
                <ValidatorForm method="post" onSubmit={on.submit} >
                  <div className="card-body">
                    <div>Invoice Details</div>
                    <hr />

                    <Field name='invoice_number' label='Agency Invoice Number'>
                      <InputField
                        onChange={on.change}
                        onBlur={fetch.invoice}
                        name="invoice_number"
                        value={form?.invoice_number || ''}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        className="form-control"
                        placeholder="Invoice Number"
                      />
                    </Field>

                    <Field name='revenue_line_item' label='Revenue Line Item'>
                      <InputField readOnly value={invoice?.long_desc} className="form-control" />
                    </Field>

                    <Field name='card_amount' label='Amount'>
                      <InputField readOnly value={card?.values['card-amount']} className="form-control" />
                    </Field>

                    <div>Customer Info</div>
                    <hr />

                    <Field name='first_name' label='First Name'>
                      <InputField
                        onChange={on.change}
                        name="first_name"
                        value={form?.first_name || ''}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        className="form-control"
                        placeholder="First Name"
                      />
                    </Field>

                    <Field name='last_name' label='Last Name'>
                      <InputField
                        onChange={on.change}
                        name="last_name"
                        value={form?.last_name || ''}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        className="form-control"
                        placeholder="Last Name"
                      />
                    </Field>

                    <Field name='email' label='Email'>
                      <InputField
                        onChange={on.change}
                        name="email"
                        value={form?.email || ''}
                        validators={['required', 'isEmail']}
                        errorMessages={['This field is required', 'Must be a valid email address']}
                        className="form-control"
                        placeholder="Email"
                      />
                      <a role="button" onClick={useDefaultEmail}>Default Email</a>
                    </Field>

                    <Field name='sms' required={false} label='Mobile Number'>
                      <InputField
                        onChange={on.change}
                        name="mobile_number"
                        value={form?.mobile_number || ''}
                        className="form-control"
                        placeholder="Mobile Number"
                        type="number"
                      />
                      <p>All phone numbers must start with 1242</p>
                    </Field>

                    <Field name='sms' required={false} label='Additional Notes'>
                      <textarea
                        onChange={on.change}
                        name="notes"
                        maxLength="250"
                        value={form?.notes || ''}
                        className="form-control"
                        placeholder="Additional Notes"
                        type="text"
                      />
                    </Field>

                    <div>Card Info</div>
                    <hr />

                    <Field name='card-name' label='Cardholder Name'>
                      <InputField
                        onChange={on.change}
                        name="card-name"
                        value={form['card-name'] || ''}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        className="form-control"
                        placeholder="Cardholder Name"
                        autoComplete='off'
                      />
                    </Field>

                    <Field name='cc_info' label='Debit/Credit Card'>
                      {/* <CCInput
                        fieldClassName='input'
                        cardNumberInputProps={{ value: card.values['card-number'], onChange: on.cc('card-number'), autoComplete: 'off' }}
                        cardExpiryInputProps={{ value: card.values['card-exp'], onChange: on.cc('card-exp'), autoComplete: 'off' }}
                        cardCVCInputProps={{ value: card.values['card-cvv'], onChange: on.cc('card-cvv'), autoComplete: 'off' }}
                        onError={on.error}
                      /> */}
                      {/* <PaymentInputsContainer>
                        {({ meta, getCardNumberProps, getExpiryDateProps, getCVCProps }) => (
                          <div className='input'>
                            <input {...getCardNumberProps({ onChange: on.cc('card-number') })} value={card.values['card-number']} />
                            <input {...getExpiryDateProps({ onChange: on.cc('card-exp') })} value={card.values['card-exp']} />
                            <input {...getCVCProps({ onChange: on.cc('card-cvv') })} value={card.values['card-cvv']} />
                            {meta.isTouched && meta.error && <span>Error: {meta.error}</span>}
                          </div>
                        )}
                      </PaymentInputsContainer> */}
                      <PaymentInputsWrapper {...wrapperProps}>
                        <svg {...getCardImageProps({ images })} />
                        <input {...getCardNumberProps({ onChange: on.cc('card-number') })} value={card.values['card-number']} />
                        <input {...getExpiryDateProps({ onChange: on.cc('card-exp') })} value={card.values['card-exp']} />
                        <input {...getCVCProps({ onChange: on.cc('card-cvv') })} value={card.values['card-cvv']} />
                      </PaymentInputsWrapper>
                    </Field>
                  </div>

                  <div className="card-action">
                    <div className="row">
                    <div className='col-lg-7 text-end'>
                        <input
                          style={{backgroundColor: '#00665d'}}
                          className={`btn me-2 text-white`}
                          type='submit'
                          value='Submit'
                          disabled={loading}
                        />
                        {/*<a className="btn btn-danger" onClick={()=> this.props.history.push('/inventory')} href="javascript:void(0)">Cancel</a>*/}
                      </div>
                    </div>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

// loading: transactions.loading,
// transaction: transactions.transaction,
// success_message: transactions.success_message,
// error: transactions.error

export default CreditCardPayment
