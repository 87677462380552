import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, updateProfile } from '../state/users/userSlice';
import _ from 'lodash'
import $ from 'jquery'
let invobj = {}


const Profile = () => {
	// const [open, toggleOpen] = useState(false);
	// const [userId, setUserId] = useState(sessionStorage.user && JSON.parse(sessionStorage.user).id);
	// const [user, setUser] = useState({});
	// const [data, setData] = useState([]);
	const [formData, setFormData] = useState({});
	const userFetch = useSelector((state) => state.users.userData);
	const user = userFetch || (sessionStorage.user && JSON.parse(sessionStorage.user));
	const userId = user?.id || (sessionStorage.user && JSON.parse(sessionStorage.user)).id;

	const formRef = useRef();

	const dispatch = useDispatch();

	// componentDidUpdate(nextProps) {
	// 	this.setState({ loading: nextProps.loading, success: nextProps.success, success_message: nextProps.success_message })
	// 	if (nextProps.user !== undefined) {
	// 		this.setState({ user: nextProps.user })
	// 	}
	// }

	useEffect(() => {
		dispatch(getUser())
	}, [dispatch])


	const handleChange = (event) => {
		invobj[event.target.name] = event.target.value;
		setFormData({ formData: invobj });
	}

	const inputFocus = (event) => {
		$('.form-group-default').removeClass('active')
		event.target.parentElement.classList.add('active')
	}

	const handleUpdate = (e) => {
		e.preventDefault()
		dispatch(updateProfile({ id: userId, data: formData }))
	}

	const resetForm = () => {
		formRef.current.reset();
		dispatch(getUser(userId))
	}

	return (
		<React.Fragment>
			{_.isEmpty(user) ? null : <div className="content">
				<div className="page-inner">
					<h4 className="page-title">User Profile</h4>
					<div className="row">
						<div className="col-md-8">
							<div className="card card-with-nav">
								<div className="card-header">
									<div className="row row-nav-line">
										<ul className="nav nav-tabs nav-line nav-color-secondary" role="tablist">
											<li className="nav-item"> <a className="nav-link active show" data-toggle="tab" href="#home" role="tab" aria-selected="true">Profile</a> </li>
										</ul>
									</div>
								</div>
								<div className="card-body">
									<form ref={formRef} id="form-description" onSubmit={handleUpdate}>
										<div className="row mt-3">
											<div className="col-md-6">
												<div className="form-group form-group-default">
													<label>First Name</label>
													<input type="text" onFocus={(e) => inputFocus(e)} onChange={(e) => handleChange(e)} className="form-control" name="first_name" placeholder="Name" defaultValue={`${user.first_name}`} />
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group form-group-default">
													<label>Last Name</label>
													<input type="text" onFocus={(e) => inputFocus(e)} onChange={(e) => handleChange(e)} className="form-control" name="last_name" placeholder="Name" defaultValue={`${user.last_name}`} />
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="form-group form-group-default">
													<label>Email</label>
													<input type="email" onFocus={(e) => inputFocus(e)} onChange={(e) => handleChange(e)} className="form-control" name="email" placeholder="Name" defaultValue={`${user.email}`} />
												</div>
											</div>
										</div>

										<div className="text-end mt-3 mb-3">
											<button type="submit" className="btn btn-success me-1">Save</button>
											<button className="btn btn-danger" onClick={() => resetForm()}>Reset</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="card card-profile">
								<div className="card-header" style={{ backgroundImage: "url('https://via.placeholder.com/325x215')" }}>
									<div className="profile-picture">
										<div className="avatar avatar-xl">
											<img src="https://via.placeholder.com/60x60" alt="..." className="avatar-img rounded-circle" />
										</div>
									</div>
								</div>
								<div className="card-body">
									<div className="user-profile text-center">
										<div className="name">{`${user.first_name} ${user.last_name}`}</div>
										<div className="job">Frontend Developer</div>
										<div className="desc">A man who hates loneliness</div>
										<div className="social-media">
											<a className="btn btn-info btn-twitter btn-sm btn-link" href="#">
												<span className="btn-label just-icon"><i className="flaticon-twitter"></i> </span>
											</a>
											<a className="btn btn-danger btn-sm btn-link" rel="publisher" href="#">
												<span className="btn-label just-icon"><i className="flaticon-google-plus"></i> </span>
											</a>
											<a className="btn btn-primary btn-sm btn-link" rel="publisher" href="#">
												<span className="btn-label just-icon"><i className="flaticon-facebook"></i> </span>
											</a>
											<a className="btn btn-danger btn-sm btn-link" rel="publisher" href="#">
												<span className="btn-label just-icon"><i className="flaticon-dribbble"></i> </span>
											</a>
										</div>
										<div className="view-profile">
											<a href="#" className="btn btn-secondary btn-block">View Full Profile</a>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="row user-stats text-center">
										<div className="col">
											<div className="number">125</div>
											<div className="title">Post</div>
										</div>
										<div className="col">
											<div className="number">25K</div>
											<div className="title">Followers</div>
										</div>
										<div className="col">
											<div className="number">134</div>
											<div className="title">Following</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>}

		</React.Fragment>
	);
}

export default Profile








