import React from 'react'
import '../assets/css/loader.css'

const Loader = props => props.loading ? (
  <div className="loader-container">
    {/* <div className='animate-spin'>
      <i className='fas fa-circle-notch'/>
    </div> */}
    <div className="spinner-border text-info text-center" style={{fontSize: '30px', padding: '100px'}} role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
  // <div className="modal" tabIndex="-1">
  //   <div className="modal-dialog modal-dialog-centered">
  //     <div className="modal-content">
  //       <div className="modal-body">
  //         <div className='animate-spin'>
  //           <i className='fas fa-circle-notch'/>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </div>
) : null

export default Loader