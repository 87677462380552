
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import LandingPage from '../wrappers/landing-page'
import { forgetPassword } from '../state/users/userSlice'
import { useNavigate } from 'react-router'
import { errorLog } from '../state/root/rootSlice'

export const ROUTE_OPEN = true

const ForgotPassword = () => {
  // const [open, toggleOpen] = useState(false);
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (ev) => {
    ev.preventDefault()
    
    dispatch(forgetPassword({ email })).unwrap()
      .then(() => {
        navigate('/login');
      })
      .catch((err) => {
        dispatch(errorLog(err))
      })
  }

  return (
    <LandingPage
      title='Open for Business 24x7'
      subtitle="The subway system of The Bahamas' financial services."
      // success_message={props.success_message}
      // error={props.error}
    >
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email" className="placeholder bg-white"><b>Email</b></label>
          <input id="email"
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            type="text"
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <input type="submit" className="btn btn-primary" value="Send" required />
        </div>
      </form>
    </LandingPage>
  )
}

export default ForgotPassword
