import { useEffect } from 'react'
import { clearError } from '../state/root/rootSlice';
import { useDispatch } from 'react-redux'

import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast',
  },
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
})

export default function Alert ({ successMessage, error }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      if (typeof error == 'object') {
        // toastr.error('Error', error?.error)
        Toast.fire({
          icon: 'error',
          iconColor: 'red',
          title: error?.error,
        })
      } else {
        // toastr.error('Error', error)
        Toast.fire({
          icon: 'error',
          iconColor: 'red',
          title: error,
        })
      }
      setTimeout(function () {
        dispatch(clearError());
      }, 4000);
    }

    if (successMessage) {
      // toastr.success('Success', successMessage)
      Toast.fire({
        icon: 'success',
        iconColor: 'green',
        title: successMessage,
      })
      setTimeout(function () {
        dispatch(clearError());
      }, 2000);
    }
  })

  return null
}
