import React from 'react'
import { Navigate } from "react-router-dom";

// const AuthGuard = (Component) => {
//   const Authentication = () => {
//     const navigate = useNavigate();
//     const location = useLocation();
//     // const user = useSelector(state => state.users.currentUser);
  
//     // if authenticated == false redirect to '/signin'

//     useEffect(() => {
//       const unauthorizedView = props => {
//         if (location.pathname === '/login') {
//           navigate(-1)
//         }
//       }
  
//       if (!sessionStorage.getItem('user')) {
//         navigate('/login')
//       } else {
//         unauthorizedView(this.props)
//       }
//     }, [navigate, location])

//     return <Component {...this.props} />
//   }

//   // authenticated: state.dashboard.authenticated

//   return Authentication
// }

// export default AuthGuard

const AuthGuard = ({ user, children }) => {
  if (!user?.roles) {
    // console.log('protected');
    return <Navigate to="/login" replace />
  }

  return children;
};

export default AuthGuard