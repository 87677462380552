import React from "react";
// import $ from "jquery";
// import { Link } from "react-router-dom";
import CsvDownloader from "use-csv-downloader";
// import { confirmAlert } from "react-confirm-alert";

import appConstants from "../../constants/constants";
import { dateTimeOnly } from "../../helpers/tools";

import { downloadInvoices } from "../../state/invoices/invoiceSlice";

import "react-confirm-alert/src/react-confirm-alert.css";
import "../../assets/css/pagination.css";

const downloadCSV = CsvDownloader({});

const Table = ({ data, fullData, sortedBy, setSortedBy, sortByLatest, setSortByLatest }) => {
    // const dataMap = data.map(x => ({
    //   invoice_reference: x.invoice_reference,
    //   reference_code: x.reference_code,
    //   location: x.location,
    //   created_at: convertDate(x.created_at),
    //   revenue_line_item: x.revenue_line_item.long_desc,
    //   email: x.email,
    //   first_name: x.first_name,
    //   last_name: x.last_name,
    //   amount: x.amount && parseFloat(x.amount).toFixed(2),
    //   status: x.status,
    //   void: x.void || null,
    //   mobile_number: x.mobile_number,
    //   notes: x.notes,
    //   download: null,
    // }));


  const downloadData = (url) => {
    downloadInvoices(url);
  }

  const convertDate = date => new Date(date).toLocaleString()

  const generateCSV = () => {
    const { INVOICES_HEADERS } = appConstants;
    // console.log(INVOICES_HEADERS);
    // let rows = null;
    // console.log('datatable', dt.current);
    // rows = dt.current?.context[0].aiDisplay.map((i) => data[i]);

    const csv = (fullData || fullData.reverse())
      .map((row) => ({
        ...row,
        created_at: dateTimeOnly(row.created_at),
        long_desc: row.revenue_line_item && row.revenue_line_item.long_desc,
      }))
      .map((row) => {
        return INVOICES_HEADERS.reduce(
          (o, { label, key }) => ({
            ...o,
            [label]: key == "void" ? (row[key] ? "YES" : "NO") : row[key],
          }),
          {}
        );
      });
    // console.log(csv);
    downloadCSV(csv, `invoices-${Date.now()}.csv`);
  };

  const checkmark = val => {
    const classes = !!val
      ? 'fas fa-check text-green-600 text-success'
      : 'fas fa-times text-red-600 text-danger'

    return <div className='text-center'><i className={classes} /></div>
  }

  const tableRows = data => data.map((invoice, index) => {
    const isPaid = () => {
      return (invoice.status == 'paid' ? convertDate(invoice.updated_at) : 'N/A')
    }

    return invoice && (
    <tr key={index}>
      <td>{invoice.invoice_reference}</td>
      <td>{invoice.reference_code}</td>
      <td>{invoice.location}</td>
      <td>{convertDate(invoice.created_at)}</td>
      <td>
        {invoice.revenue_line_item && invoice.revenue_line_item.long_desc}
      </td>
      <td>{invoice.email}</td>
      <td>{invoice.first_name}</td>
      <td>{invoice.last_name}</td>
      <td>${invoice.amount && parseFloat(invoice.amount).toFixed(2)}</td>
      <td>{checkmark(invoice.status == 'paid')}</td>
      {/* <td>{invoice?.transaction ? convertDate(invoice?.transaction?.updated_at) : 'N/A'}</td> */}
      <td>{isPaid()}</td>
      <td>{invoice.void && checkmark(invoice.void)}</td>
      <td>{invoice.mobile_number}</td>
      <td>{invoice.notes}</td>
      <td>
        <button
          data-action
          type="button"
          onClick={(e) => {
            downloadData(invoice.invoice);
          }}
          className="btn btn-link"
          data-original-title="Remove"
        >
          <i className="fa fa-download"></i>
        </button>
      </td>
    </tr>)
  })

  // Handle Sorting Functions
  const handleSort = (sortValue) => {
    setSortedBy(sortValue);

    // Reset Sort Direction to latest once a new column is selected
    if (sortedBy != sortValue) {
      setSortByLatest(true);
    } else {
      setSortByLatest(!sortByLatest);
    }
  }

  const floatStyle = {textAlign: 'start', backgroundColor: "transparent", border: '0', fontWeight: '600'}
  const exceptionColumn = ['download', 'notes', 'mobile_number']

  const showSortButton = (h) => {
    if (exceptionColumn.includes(h.data)) {
      return h.title
    } else {
      return <button
        style={floatStyle}
        data-value={h.data}
        onClick={(ev) => handleSort(ev.target.getAttribute('data-value')) }>
        {h.title}
        {sortedBy == h.data ? '↓' : '↑'}
      </button>
    }
  }

  const headings = appConstants.INVOICES_COLUMNS.map((h, i) => (
    <th key={i} className="th-custom" style={{position: 'relative'}}>
      {showSortButton(h)}
    </th>
  ));

  return (
    <React.Fragment>
      <button
        className="btn btn-sm btn-primary"
        onClick={generateCSV}
        style={{ marginBottom: "1.5rem" }}
      >
        <i className='fas fa-download'/>
        &nbsp;&nbsp;&nbsp;&nbsp;Download CSV
      </button>

      <table
        id="basic-datatables"
        className="display table table-striped table-hover"
      >
        <thead>
          <tr>{headings}</tr>
        </thead>
        <tfoot>
          <tr>{headings}</tr>
        </tfoot>
        <tbody>{tableRows(data)}</tbody>
      </table>
    </React.Fragment>
  );
}

export default Table;
