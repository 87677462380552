import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios";

const headers = () => {
  let user = sessionStorage.getItem("user");
  user = JSON.parse(user);
  return {
    "Content-Type": "application/json",
    "Authorization": user && user.auth_token
  };
}

const initialState = {
  authenticated: false,
  loading: false,
  success: false,
  success_message: undefined,
  error: undefined,
  data: null, //[],
  company: null,
  companies: null, //[],
}

const companySlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    errorLog: (state, action) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = undefined;
    },
    stopLoader: (state) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
    // Get Companies
      .addCase(getCompanies.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.companies = action.payload.data.companies;
        // console.log(action);
      })
      .addCase(getCompanies.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.companies = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Get Companies
      .addCase(getCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.company = action.payload.data.company;
        // console.log(action);
      })
      .addCase(getCompany.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.company = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Save Company
      .addCase(saveCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully created company.';
        state.company = action.payload.data;
        // console.log(action);
      })
      .addCase(saveCompany.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.company = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Update Company
      .addCase(updateCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully updated company.';
        state.company = action.payload.data;
        // console.log(action);
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.company = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Remove Company
      .addCase(removeCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully removed company.';
        // console.log(action);
        state.companies = action.payload.data.filter((item) => item.id != action.payload.data.id);
      })
      .addCase(removeCompany.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.companies = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
  }
})

export const { clearError, stopLoader, errorLog } = companySlice.actions
export default companySlice.reducer;

 
export const getCompanies = createAsyncThunk(
  'companies/getCompanies',
  async (_t, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/companies`, { headers: headers() })
      // axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/users`,{headers: headers()})
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const getCompany = createAsyncThunk(
  'companies/getCompany',
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/companies/${id}`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const saveCompany = createAsyncThunk(
  'companies/saveCompany',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/companies`, data, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const updateCompany = createAsyncThunk(
  'companies/updateCompany',
  async ({id, data}, { rejectWithValue }) => {
    try {
      const res = await axios.patch(`${process.env.REACT_APP_API_HOST}/api/v1/companies/${id}`, data, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const removeCompany = createAsyncThunk(
  'companies/removeCompany',
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.delete(`${process.env.REACT_APP_API_HOST}/api/v1/companies/${id}`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)
