import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ValidatorForm } from 'react-form-validator-core'

import EmptyTable from '../../shared/empty-table'
import Loader from '../../shared/loader'
import DateTimeField from '../../shared/form-fields/date'
import { getInvoices } from '../../state/invoices/invoiceSlice'
import appConstants from '../../constants/constants'
import InvoiceTable from './_table'
import PageIndex from '../../shared/pageIndex'

const Invoice = () => {
  const today = new Date()
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [maxPages, setMaxPages] = useState(1);
  const [dataIndexed, setDataIndexed] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [sortedBy, setSortedBy] = useState('created_at');
  const [sortByLatest, setSortByLatest] = useState(true);

  // const [isOpen, toggleIsOpen] = useState(false);
  // const [currentList, setCurrentList] = useState({});
  const [dateRange, setDateRange] = useState({ date_start: today, date_end: tomorrow });
  // const [datesClean, setDatesClean] = useState(true);

  const dispatch = useDispatch();
  // const user = useSelector(state => state.users.currentUser);
  const data = useSelector(state => state.invoices.invoices);
  const loading = useSelector(state => state.root.loading);
  const user = sessionStorage.user && JSON.parse(sessionStorage.user);
  const roles = user?.roles?.map(r => r.name);

  // Data Sorter by Both column data values and latest or oldest record (alphabetical order or opposite)
  const sortByFunction = useCallback(() => {
    const sortedFunc = (column, _column_2) => {
      if (_column_2) {
        if (sortByLatest) return dataFiltered?.toSorted(function(a, b) {
          return (a[column][_column_2]===null)-(b[column][_column_2]===null) || +(a[column][_column_2]>b[column][_column_2])||-(a[column][_column_2]<b[column][_column_2]);
        });
        return dataFiltered?.toSorted(function(a, b) {
          return (a[column][_column_2]===null)-(b[column][_column_2]===null) || -(a[column][_column_2]>b[column][_column_2])||+(a[column][_column_2]<b[column][_column_2]);
        });
      } else {
        if (sortByLatest) return dataFiltered?.toSorted(function(a, b) {
          return (a[column]===null)-(b[column]===null) || +(a[column]>b[column])||-(a[column]<b[column]);
        });
        return dataFiltered?.toSorted(function(a, b) {
          return (a[column]===null)-(b[column]===null) || -(a[column]>b[column])||+(a[column]<b[column]);
        });
      }
    }

    switch(sortedBy) {
      case 'created_at':
        if (sortByLatest) return dataFiltered?.toSorted((a, b) => new Date(b?.created_at) - new Date(a?.created_at))
        return dataFiltered?.toSorted((a, b) => new Date(a?.created_at) - new Date(b?.created_at))
      case 'invoice_reference':
        return sortedFunc('invoice_reference')
      case 'reference_code':
        return sortedFunc('reference_code')
      case 'location':
        return sortedFunc('location')
      case 'revenue_line_item':
        return sortedFunc('revenue_line_item', 'long_desc')
      case 'email':
        return sortedFunc('email')
      case 'first_name':
        return sortedFunc('first_name')
      case 'last_name':
        return sortedFunc('last_name')
      case 'amount':
        if (sortByLatest) return dataFiltered?.toSorted((a, b) => a?.amount - b?.amount)
        return dataFiltered?.toSorted((a, b) => b?.amount- a?.amount)
      case 'status':
        return sortedFunc('status')
      case 'paid_date':
        const distantFuture = new Date(8640000000000000)

        if (sortByLatest) {
          return dataFiltered?.toSorted((a, b) => {
            const a_date = a?.status == 'paid' ? a.updated_at : distantFuture
            const b_date = b?.status == 'paid' ? b.updated_at : distantFuture

            return new Date(b_date) - new Date(a_date)
          })
        }
        return dataFiltered?.toSorted((a, b) => {
          const a_date = a?.status == 'paid' ? a.updated_at : distantFuture
          const b_date = b?.status == 'paid' ? b.updated_at : distantFuture

          return new Date(a_date) - new Date(b_date)
        })
      // case 'paid_date':
      //   const distantFuture = new Date(8640000000000000)

      //   if (sortByLatest) {
      //     return dataFiltered?.toSorted((a, b) => {
      //       const a_date = a?.transaction ? a.transaction.updated_at : distantFuture
      //       const b_date = b?.transaction ? b.transaction.updated_at : distantFuture

      //       return new Date(b_date) - new Date(a_date)
      //     })
      //   }
      //   return dataFiltered?.toSorted((a, b) => {
      //     const a_date = a?.transaction ? a.transaction.updated_at : distantFuture
      //     const b_date = b?.transaction ? b.transaction.updated_at : distantFuture

      //     return new Date(a_date) - new Date(b_date)
      //   })
      case 'void':
        return sortedFunc('void')
      default:
        if (sortByLatest) return dataFiltered?.toSorted((a, b) => new Date(b?.created_at) - new Date(a?.created_at))
        return dataFiltered?.toSorted((a, b) => new Date(a?.created_at) - new Date(b?.created_at))
    }
  }, [dataFiltered, sortedBy, sortByLatest])

  // Initialize
  useEffect(() => {
    // console.log('UserSession', sessionStorage);
    dispatch(getInvoices({start: dateRange['date_start'], end: dateRange['date_end']}))
    // .unwrap()
    // .then((res) => {
    //   console.log('getInvoices', res);
    //   toggleIsOpen(false);
    // })
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    const startingIndex = (page - 1) * perPage;
    const endingIndex = startingIndex + perPage;
    if (dataFiltered && dataFiltered.length) {
      let dataFilterSorted = sortByFunction();
      // console.log('DataFilterSorted', dataFilterSorted);

      if (dataFilterSorted) {
        setDataIndexed(dataFilterSorted?.map((x, i) => {
          if(i < endingIndex && i >= startingIndex){ return x }
          else return null
        }))
      }
    } else {
      let dataSorted = sortByFunction();
      // console.log('dataSorted', dataSorted);

      if (dataSorted) {
        setDataIndexed(dataSorted?.map((x, i) => {
          if(i < endingIndex && i >= startingIndex){ return x }
          else return null
        }))
      }
    }
  }, [perPage, page, data, dataFiltered, sortByFunction])

  const on = {
    date: (name) => (d) => {
      setDateRange({ ...dateRange, [name]: formatDate(d) });
      // setDatesClean(false);
      // console.log(dateRange, 'Formatted Date', formatDate(d));
    },
  }

  const searchDateRange = () => {
    // console.log('Search Date', dateRange);
    dispatch(getInvoices({ start: dateRange['date_start'], end: dateRange['date_end'] }))
    // setDatesClean(true)
  }

  const formatDate = (date) => typeof date == 'string'
    ? new Date(date.replace(/^(.{4})(.{2})(.{2})$/, '$1-$2-$3'))
    : date

  const styles = {
    form: {
      display: 'inline-flex',
      alignItems: 'center',
      marginLeft: 'auto',
      marginRight: '1.5rem',
    },
    label: {
      margin: 0,
    },
  }

  const filters = (
    <ValidatorForm className='d-inline-flex align-items-center ms-auto me-4' onSubmit={searchDateRange}>
      <label htmlFor='date_start' style={styles.label}>
        From&nbsp;&nbsp;
      </label>
      <DateTimeField
        name='date_start'
        className='form-control'
        onChange={on.date('date_start')}
        selected={dateRange.date_start}
        value={dateRange.date_start}
        validators={['required']}
        errorMessages={['Required']}
        dateFormat='do MMMM, yyyy'
        dropdownMode='select'
        maxDate={dateRange.date_end}
      />
      <label htmlFor='date_end' style={styles.label}>
        &nbsp;&nbsp;&nbsp;&nbsp;To&nbsp;&nbsp;
      </label>
      <DateTimeField
        name='date_end'
        className='form-control'
        onChange={on.date('date_end')}
        selected={dateRange.date_end}
        value={dateRange.date_end}
        validators={['required']}
        errorMessages={['Required']}
        dateFormat='do MMMM, yyyy'
        dropdownMode='select'
        minDate={dateRange.date_start}
        maxDate={dateRange.date_end}
      />
      <button type='submit'
        style={{backgroundColor: '#00665d'}}
        className={`refresh-btn btn ms-3 text-white`}
        disabled={loading}>
        <i className='fas fa-sync' />
      </button>
    </ValidatorForm>
  )

  const table = !loading && dataIndexed && dataIndexed.length ? (
      <InvoiceTable
        data={dataIndexed}
        fullData={data}
        sortedBy={sortedBy}
        setSortedBy={setSortedBy}
        sortByLatest={sortByLatest}
        setSortByLatest={setSortByLatest}
        // removeInvoice={removeData}
        // downloadInvoices={downloadInvoices}
      />
    ) : (
      <EmptyTable columns={appConstants.INVOICES_COLUMNS} />
    )

  return (
    <React.Fragment>
      <Loader loading={loading} />

      <div className='content'>
        <div className='page-inner'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='card'>
                <div className='card-header'>
                  <div className='d-flex flex-row align-items-center'>
                    <h4 className='card-title'>Invoices</h4>
                    {filters}
                    {roles?.indexOf('read') != -1 ? null : (
                      <a
                        className='btn btn-primary btn-round'
                        href='/invoices/new'
                      >
                        <i className='fa fa-plus'></i>
                        &nbsp;&nbsp;&nbsp;&nbsp;Create Invoice
                      </a>
                    )}
                  </div>
                </div>
                <div className='card-body'>
                  <div className='table-responsive'>{table}</div>
                </div>
                <div className='card-footer'>
                  <PageIndex data={data} dataFiltered={dataFiltered} setDataFiltered={setDataFiltered} page={page} setPage={setPage} maxPages={maxPages} setMaxPages={setMaxPages} perPage={perPage} setPerPage={setPerPage}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Invoice

