import React, { Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import NotFound from '../screens/_404'
import AuthGuard from '../wrappers/auth-guard'
import Loader from '../shared/loader'
import Login from '../screens/login'
import ForgetPassword from '../screens/forget-password'
import ResetPassword from '../screens/reset-password'
import Logout from '../screens/logout'
import Cash from '../screens/cash'
import Cheque from '../screens/cheque'
import CreditCard from '../screens/credit-card'
import Dashboard from '../screens/dashboard'
import HeadForm from '../screens/heads/form'
import Head from '../screens/heads/index'
import Invoice from '../screens/invoices/index'
import InvoiceForm from '../screens/invoices/form'
import LineItemForm from '../screens/line-items/form'
import LineItem from '../screens/line-items/index'
import RevenueLineItem from '../screens/revenue-line-items'
import CompanyForm from '../screens/ministries/form'
import Company from '../screens/ministries/index'
import PayeeForm from '../screens/payee/new'
import Payee from '../screens/payee/index'
import Payment from '../screens/payment'
import Profile from '../screens/profile'
import ReplayEmail from '../screens/replay-email'
import ReplaySms from '../screens/replay-sms'
import ReportForm from '../screens/report/form'
import Report from '../screens/report/index'
import Search from '../screens/search/index'
import SearchInvoice from '../screens/search/invoices'
import SearchTransaction from '../screens/search/transactions'
import Swipe from '../screens/swipe'
import TransactionForm from '../screens/transactions/form'
import Transaction from '../screens/transactions/index'
import UserForm from '../screens/users/form'
import Users from '../screens/users/index'

/**
 * Files placed in src/screens will automatically be added as routes
 * Files/Folders that begin with an underscore (_) will be ignored
 * By defualt, the path would be `/${filename}` or `/${subfolder}/${filename}`
 * To set a different pathname, export ROUTE_PATHS from the file.
 * To enable multiple paths pointing to the same component,
 * ROUTE_PATHS is always an array
 * e.g:
 *    export const ROUTE_PATHS = ['/foobar', '/hello']
 *
 *    export default class Component extends React.Component {
 *      ....
 *    }
 *
 * By default, route components will be wrapped by AuthGuard and use exact matching
 * To not wrap a component, export ROUTE_OPEN as a truthy value
 * To not use exact matching, export ROUTE_EXACT as a falsey value
 * e.g:
 *    export const ROUTE_OPEN = true
 *    export const ROUTE_EXACT = false
 *
 *    export default class Component extends React.Component {
 *      ....
 *    }
 *
 */


const DecidePath = ({user}) => {
  const roles = user?.roles
  // const location = useLocation()

  if (roles) {
    return roles.find(r => ['revenue', 'treasury'].includes(r.name))
      ? <Navigate to='/invoices' />
      : <Navigate to='/dashboard' />
  }

  return <Navigate to='/login' />
}

const AppRouter = ({user}) => {
  // const [toggled, setToggled] = useState(false);
  // const navigate = useNavigate();

  // const allComponents = getScreenRoutes(true)

  // useEffect(() => {
  //   const roles = user?.roles
  //   console.log('roles', roles)
    
  //   if (roles) {
  //     return roles.find(r => ['revenue', 'treasury'].includes(r.name))
  //       ? navigate('/invoices')
  //       : navigate('/dashboard')
  //   } else {
  //     navigate('/login')
  //   }
  // }, [user, navigate])

  // const togglePanel = () => setToggled(!toggled);

  return (
    <Suspense fallback={<Loader loading={true} />}>
      <Routes>
        <Route
          exact
          path='/'
          authenticated={!!user}
          element={<DecidePath user={user}/>}
        />

        {/* {allComponents} */}
        <Route element={<NotFound/>} />
        <Route 
          exact 
          path='/cash' 
          element={
            <AuthGuard user={user}>
              <Cash/>
            </AuthGuard>}
        />
        <Route exact path='/cheque' element={<AuthGuard user={user}><Cheque/></AuthGuard>}/>
        <Route exact path='/credit-card' element={<AuthGuard user={user}><CreditCard/></AuthGuard>}/>
        <Route exact path='/dashboard' element={<AuthGuard user={user}><Dashboard/></AuthGuard>}/>
        <Route exact path='/forget-password' element={<ForgetPassword/>}/>
        <Route exact path='/heads/form' element={<HeadForm/>}/>
        <Route exact path='/heads/new' element={<HeadForm/>}/>
        <Route exact path='/heads/edit' element={<HeadForm/>}/>
        <Route exact path='/heads' element={<Head/>}/>
        {/* <Route exact path='/invoices/form' element={<AuthGuard user={user}><InvoiceForm/></AuthGuard>}/> */}
        <Route exact path='/invoices/new' element={<AuthGuard user={user}><InvoiceForm/></AuthGuard>}/>
        <Route exact path='/invoices/edit' element={<AuthGuard user={user}><InvoiceForm/></AuthGuard>}/>
        <Route exact path='/invoices' element={<AuthGuard user={user}><Invoice/></AuthGuard>}/>
        {/* <Route exact path='/line-items/form' element={<AuthGuard user={user}><LineItemForm/></AuthGuard>}/> */}
        <Route exact path='/line-items/edit' element={<AuthGuard user={user}><LineItemForm/></AuthGuard>}/>
        <Route exact path='/line-items/new' element={<AuthGuard user={user}><LineItemForm/></AuthGuard>}/>
        <Route exact path='/line-items' element={<AuthGuard user={user}><LineItem/></AuthGuard>}/>
        <Route exact path='/login' element={<Login/>}/>
        <Route exact path='/logout' element={<Logout/>}/>
        {/* <Route exact path='/ministries/form' element={<AuthGuard user={user}><CompanyForm/></AuthGuard>}/> */}
        <Route exact path='/ministries/new' element={<AuthGuard user={user}><CompanyForm/></AuthGuard>}/>
        <Route exact path='/ministries/edit' element={<AuthGuard user={user}><CompanyForm/></AuthGuard>}/>
        <Route exact path='/ministries' element={<AuthGuard user={user}><Company/></AuthGuard>}/>
        <Route exact path='/payee' element={<AuthGuard user={user}><Payee/></AuthGuard>}/>
        <Route exact path='/payee/new' element={<AuthGuard user={user}><PayeeForm/></AuthGuard>}/>
        <Route exact path='/payment' element={<AuthGuard user={user}><Payment/></AuthGuard>}/>
        <Route exact path='/profile' element={<AuthGuard user={user}><Profile/></AuthGuard>}/>
        <Route exact path='/replay-email' element={<AuthGuard user={user}><ReplayEmail/></AuthGuard>}/>
        <Route exact path='/replay-sms' element={<AuthGuard user={user}><ReplaySms/></AuthGuard>}/>
        <Route exact path='/report/form' element={<AuthGuard user={user}><ReportForm/></AuthGuard>}/>
        <Route exact path='/report/generate' element={<AuthGuard user={user}><ReportForm/></AuthGuard>}/>
        <Route exact path='/report' element={<AuthGuard user={user}><Report/></AuthGuard>}/>
        <Route exact path='/reset-password/:token' element={<ResetPassword/>}/>
        <Route exact path='/revenue-line-items' element={<AuthGuard user={user}><RevenueLineItem/></AuthGuard>}/>
        <Route exact path='/search' element={<AuthGuard user={user}><Search/></AuthGuard>}/>
        <Route exact path='/search/invoices' element={<AuthGuard user={user}><SearchInvoice/></AuthGuard>}/>
        <Route exact path='/search/transactions' element={<AuthGuard user={user}><SearchTransaction/></AuthGuard>}/>
        <Route exact path='/swipe' element={<AuthGuard user={user}><Swipe/></AuthGuard>}/>
        {/* <Route exact path='/transactions/form' element={<AuthGuard user={user}><TransactionForm/></AuthGuard>}/> */}
        <Route exact path='/transactions/new' element={<AuthGuard user={user}><TransactionForm/></AuthGuard>}/>
        <Route exact path='/transactions/edit' element={<AuthGuard user={user}><TransactionForm/></AuthGuard>}/>
        <Route exact path='/transactions' element={<AuthGuard user={user}><Transaction/></AuthGuard>}/>
        {/* <Route exact path='/users/form' element={<AuthGuard user={user}><UserForm/></AuthGuard>}/> */}
        <Route exact path='/users/new' element={<AuthGuard user={user}><UserForm/></AuthGuard>}/>
        <Route exact path='/users/edit' element={<AuthGuard user={user}><UserForm/></AuthGuard>}/>
        <Route exact path='/users' element={<AuthGuard user={user}><Users/></AuthGuard>}/>
      </Routes>
    </Suspense>
  )
}

// class AppRouter extends React.Component {
//   state = {
//     toggled: false,
//   }

//   shouldComponentUpdate = nextProps => {
//     const curr = this.props.location.pathname
//     const next = nextProps.location.pathname

//     if (curr == '/login' && ['/dashboard', '/invoices'].includes(next)) {
//       return false
//     }

//     return curr != next
//   }

//   togglePanel = () => this.setState({ toggled: !this.state.toggled })

//   render = () => (
//     <Suspense fallback={<Loader loading={true} />}>
//       <Routes>
//         <Route
//           exact
//           path='/'
//           authenticated={!!global.RG.currentUser}
//           element={() => {
//             const roles = global.RG.currentUser?.roles

//             if (roles) {
//               return roles.find(r => ['revenue', 'treasury'].includes(r.name))
//                 ? <Navigate to='/invoices' />
//                 : <Navigate to='/dashboard' />
//             }

//             return <Navigate to='/login' />
//           }}
//         />

//         {/* {getScreenRoutes(true)} */}
//         <Route exact path='/login' element={<Login/>}/>
//         <Route element={<NotFound/>} />
//       </Routes>
//     </Suspense>
//   )
// }

// const getScreenRoutes = () => {
//   const context = require.context('@screens', true, /\.js$/);
//   const contextKeys = context.keys();
//   const routeComponents = []

//   // console.log('CONTEXT', context);

//   contextKeys.forEach((k, index) => {
//     if (/\/_/.test(k)) return index
//     // console.log('CONTEXT KEY', k);

//     const { default:def, ROUTE_PATHS, ROUTE_OPEN, ROUTE_EXACT } = context(k)
//     // console.log('CONTEXT FNC', context(k));


//     // if (ROUTE_PATHS) {
//     //   const adding = ROUTE_PATHS.map(path => React.createElement(Route, {
//     //     key: path,
//     //     path,
//     //     element,
//     //     exact: ROUTE_EXACT ? ROUTE_EXACT : true
//     //   }))
      
//     //   return adding
//     // }
    
//     const path = k
//       .replace(/\.js$/, '')
//       .replace(/^\.\//, '/')
//       .replace('/index', '')
    
//     let element = React.createElement(def, {key: path});
//     element = ROUTE_OPEN ? element : AuthGuard(element)
//     routeComponents.push(React.createElement(Route, {
//       key: path,
//       path,
//       element,
//       exact: ROUTE_EXACT ? ROUTE_EXACT : true
//     }))

//     // console.log('ROUTES', routeComponents, 'KEYS', k);
//   });
//   console.log('ROUTES', routeComponents);

//   return routeComponents;
// }

// const getScreenRoutesx = () => {
//   // const path = '/login';
//   // const element = <Login/>
//   // const loginRoute = React.createElement(Route, {
//   //   key: path,
//   //   path,
//   //   element,
//   //   exact: true
//   // })

//   // console.log(loginRoute)

//   // return loginRoute
//   const context = require.context('@screens', true, /\.js$/)
//   console.log('CONTEXT', context);
//   // const allRoutes = [];
  
//   return context.keys().reduce((routes, key) => {
//     // Ignore files files/folders that start with an underscore
//     if (/\/_/.test(key)) return routes
//     // if(key !== '/login') return routes + key;

//     const { default:def, ROUTE_PATHS, ROUTE_OPEN, ROUTE_EXACT } = context(key)
//     const element = ROUTE_OPEN ? def : AuthGuard(def)

//     if (ROUTE_PATHS) {
//       const adding = ROUTE_PATHS.map(path => React.createElement(Route, {
//         key: path,
//         path,
//         element,
//         exact: ROUTE_EXACT ? ROUTE_EXACT : true
//       }))
      
//       return [...routes, ...adding]
//     }
    
//     const path = key
//       .replace(/\.js$/, '')
//       .replace(/^\.\//, '/')
//       .replace('/index', '')
    
//       routes.push(React.createElement(Route, {
//       key: path,
//       path,
//       element,
//       exact: ROUTE_EXACT ? ROUTE_EXACT : true
//     }))

//     console.log('ROUTES', routes, 'KEYS', key);

//     return routes
//   }, [])
// }

// export default withRouter(AppRouter)
export default AppRouter
