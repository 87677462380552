import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ValidatorForm } from 'react-form-validator-core'

import EmptyTable from '../../shared/empty-table'
import Loader from '../../shared/loader'
import DateTimeField from '../../shared/form-fields/date'
import { getTransactions } from '../../state/transactions/transactionSlice'
import appConstants from '../../constants/constants'
import TransactionTable from './_table'
import PageIndex from '../../shared/pageIndex'

const Transaction = () => {
  const today = new Date()
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)

  // const [isOpen, toggleIsOpen] = useState(false);
  // const [currentList, setCurrentList] = useState({});
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [maxPages, setMaxPages] = useState(1);
  const [dataIndexed, setDataIndexed] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [currentTransaction, setCurrentTransaction] = useState({});
  const [dateRange, setDateRange] = useState({ date_start: today, date_end: tomorrow });
  const [sortedBy, setSortedBy] = useState('created_at');
  const [sortByLatest, setSortByLatest] = useState(true);
  // const [datesClean, setDatesClean] = useState(true);
  // const nonce = 0;

  const dispatch = useDispatch();
  // const user = useSelector(state => state.users.currentUser);
  const data = useSelector(state => state.transactions.transactions);
  const loading = useSelector(state => state.root.loading);
  // const user = sessionStorage.user && JSON.parse(sessionStorage.user);
  // const roles = user?.roles?.map(r => r.name);

  // Data Sorter by Both column data values and latest or oldest record (alphabetical order or opposite)
  const sortByFunction = useCallback(() => {
    const sortedFunc = (column, _column_2) => {
      if (_column_2) {
        if (sortByLatest) return dataFiltered?.toSorted(function(a, b) {
          return (a[column][_column_2]===null)-(b[column][_column_2]===null) || +(a[column][_column_2]>b[column][_column_2])||-(a[column][_column_2]<b[column][_column_2]);
        });
        return dataFiltered?.toSorted(function(a, b) {
          return (a[column][_column_2]===null)-(b[column][_column_2]===null) || -(a[column][_column_2]>b[column][_column_2])||+(a[column][_column_2]<b[column][_column_2]);
        });
      } else {
        if (sortByLatest) return dataFiltered?.toSorted(function(a, b) {
          return (a[column]===null)-(b[column]===null) || +(a[column]>b[column])||-(a[column]<b[column]);
        });
        return dataFiltered?.toSorted(function(a, b) {
          return (a[column]===null)-(b[column]===null) || -(a[column]>b[column])||+(a[column]<b[column]);
        });
      }
    }

    switch(sortedBy) {
      case 'paid_date':
        const distantFuture = new Date(8640000000000000)

        if (sortByLatest) {
          return dataFiltered?.toSorted((a, b) => {
            const a_date = a?.m_status == 'success' ? a.created_at : distantFuture
            const b_date = b?.m_status == 'success' ? b.created_at : distantFuture

            return new Date(b_date) - new Date(a_date)
          })
        }
        return dataFiltered?.toSorted((a, b) => {
          const a_date = a?.m_status == 'success' ? a.created_at : distantFuture
          const b_date = b?.m_status == 'success' ? b.created_at : distantFuture

          return new Date(a_date) - new Date(b_date)
        })
      case 'created_at':
        if (sortByLatest) return dataFiltered?.toSorted((a, b) => new Date(b?.created_at) - new Date(a?.created_at))
        return dataFiltered?.toSorted((a, b) => new Date(a?.created_at) - new Date(b?.created_at))
      case 'invoice_reference':
        return sortedFunc('invoice_reference')
      case 'invoice_refernce':
        return sortedFunc('invoice_refernce')
      case 'invoice_location':
        return sortedFunc('invoice_location')
      case 'revenue_line_item':
        return sortedFunc('revenue_line_item', 'long_desc')
      case 'invoice_email':
        return sortedFunc('invoice_email')
      case 'cashier':
        return sortedFunc('cashier')
      case 'transaction_first_name':
        return sortedFunc('transaction_first_name')
      case 'transaction_last_name':
        return sortedFunc('transaction_last_name')
      case 'amount_charged':
        if (sortByLatest) return dataFiltered?.toSorted((a, b) => a?.amount_charged - b?.amount_charged)
        return dataFiltered?.toSorted((a, b) => b?.amount_charged- a?.amount_charged)
      case 'm_status':
        return sortedFunc('m_status')
      case 'auth_dmsg':
        return sortedFunc('auth_dmsg')
      case 'transaction_mode':
        return sortedFunc('transaction_mode')
      case 'third_party_type':
        return sortedFunc('third_party_type')
      case 'third_party_reference':
        return sortedFunc('third_party_reference')
      case 'void':
        return sortedFunc('void')
      default:
        if (sortByLatest) return dataFiltered?.toSorted((a, b) => new Date(b?.created_at) - new Date(a?.created_at))
        return dataFiltered?.toSorted((a, b) => new Date(a?.created_at) - new Date(b?.created_at))
    }
  }, [dataFiltered, sortedBy, sortByLatest])

  // initialize
  useEffect(() => {
    dispatch(getTransactions({start: dateRange['date_start'], end: dateRange['date_end']}));
    // .unwrap()
    // .then(() => {
    //   toggleIsOpen(false);
    // })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const startingIndex = (page - 1) * perPage;
    const endingIndex = startingIndex + perPage;
    if (dataFiltered && dataFiltered.length) {
      let dataFilterSorted = sortByFunction();
      // console.log('DataFilterSorted', dataFilterSorted);

      if (dataFilterSorted) {
        setDataIndexed(dataFilterSorted?.map((x, i) => {
          if(i < endingIndex && i >= startingIndex){ return x }
          else return null
        }))
      }
    } else {
      let dataSorted = sortByFunction();
      // console.log('dataSorted', dataSorted);

      if (dataSorted) {
        setDataIndexed(dataSorted?.map((x, i) => {
          if(i < endingIndex && i >= startingIndex){ return x }
          else return null
        }))
      }
    }
  }, [perPage, page, data, dataFiltered, sortByFunction])

  const on = {
    date: (name) => (d) => {
      setDateRange({ ...dateRange, [name]: formatDate(d) })
      // setDatesClean(false)
      // console.log(dateRange);
    },
  }

  const searchDateRange = () => {
    dispatch(getTransactions({start: dateRange['date_start'], end: dateRange['date_end']}))
    // setDatesClean(true)
  }

  const formatDate = (date) => typeof date == 'string'
    ? new Date(date.replace(/^(.{4})(.{2})(.{2})$/, '$1-$2-$3'))
    : date

  // const handleAction = (id, action) => {
  //   if (action == 'edit') {
  //     const current_list = currentList.find(the => the.id === id)
  //     toggleIsOpen(true);
  //     setCurrentList(current_list)
  //   }
  // }

  // const reqcloseModal = () => {
  //   toggleIsOpen(!isOpen);
  //   setCurrentList({})
  // }

  const viewTransaction = () => {
    const rows = Object.keys(currentTransaction).map((item, i) => (
      <tr key={i}>
        <td>{item.replace('_', ' ').spaceBeforeCap()}</td>
        <td>{currentTransaction[item]}</td>
      </tr>
    ))

    return (
      <div
        className='modal fade'
        id='TransactionModal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                View Transaction
              </h5>
              <button
                type='button'
                className='close'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <table className='table'>
                <tbody>
                  {rows}
                </tbody>
              </table>
            </div>
            <div className='modal-footer'></div>
          </div>
        </div>
      </div>
    )
  }

  const showTransaction = (current_transaction) => {
    setCurrentTransaction(current_transaction);
  }

  // const { date_start, date_end } = dateRange

  const styles = {
    form: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    label: {
      margin: 0,
    },
  }

  const filters = (
    <ValidatorForm className='d-inline-flex align-items-center' onSubmit={searchDateRange}>
      <label htmlFor='date_start' style={styles.label}>
        From&nbsp;&nbsp;
      </label>
      <DateTimeField
        name='date_start'
        className='form-control'
        onChange={on.date('date_start')}
        selected={dateRange.date_start}
        value={dateRange.date_start}
        validators={['required']}
        errorMessages={['Required']}
        dateFormat='do MMMM, yyyy'
        dropdownMode='select'
        maxDate={dateRange.date_end}
      />
      <label htmlFor='date_end' style={styles.label}>
        &nbsp;&nbsp;&nbsp;&nbsp;To&nbsp;&nbsp;
      </label>
      <DateTimeField
        name='date_end'
        className='form-control'
        onChange={on.date('date_end')}
        selected={dateRange.date_end}
        value={dateRange.date_end}
        validators={['required']}
        errorMessages={['Required']}
        dateFormat='do MMMM, yyyy'
        dropdownMode='select'
        minDate={dateRange.date_start}
        maxDate={dateRange.date_end}
      />
      <button
        type='submit'
        style={{backgroundColor: '#00665d'}}
        className={`refresh-btn btn ms-3 text-white`}
        disabled={loading}>
        <i className='fas fa-sync' />
      </button>
    </ValidatorForm>
  )

  const table = !loading && dataIndexed && dataIndexed.length ? (
      <TransactionTable
        // {...state}
        data={dataIndexed}
        fullData={data}
        showTransaction={showTransaction}
        sortedBy={sortedBy}
        setSortedBy={setSortedBy}
        sortByLatest={sortByLatest}
        setSortByLatest={setSortByLatest}
        // voidTransaction={voidTransaction}
        // downloadTransactions={downloadTransactions}
      />
    ) : (
      <EmptyTable columns={appConstants.TRANSACTIONS_COLUMNS} />
    )

  return (
    <React.Fragment>
      <Loader loading={loading} />

      <div className='content'>
        <div className='page-inner'>
          <div className='page-header'></div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='card'>
                <div className='card-header'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h4 className='card-title'>Transactions</h4>
                    {filters}
                    {/*<a className='btn btn-primary btn-round ms-auto' href='/Transaction/new'>
                        <i className='fa fa-plus'></i>
                        Add Row
                      </a>*/}
                  </div>
                </div>
                <div className='card-body'>
                  <div className='table-responsive'>{table}</div>
                </div>
                <div className='card-footer'>
                  <PageIndex data={data} dataFiltered={dataFiltered} setDataFiltered={setDataFiltered} page={page} setPage={setPage} maxPages={maxPages} setMaxPages={setMaxPages} perPage={perPage} setPerPage={setPerPage}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        {viewTransaction()}
      </div>
    </React.Fragment>
  )
}

// const mapStateToProps = ({ transactions, cash }) => ({
//   data: transactions.transactions,
//   error: transactions.error,
//   success: transactions.success,
//   loading: transactions.loading,
//   success_message: transactions.success_message || cash.success_message,
// })

export default Transaction
