
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import EmptyTable from '../../shared/empty-table'
import { getCompanies, removeCompany } from '../../state/companies/companySlice'
import appConstants from '../../constants/constants'
import CompanyTable from './_table'
import PageIndex from '../../shared/pageIndex'

const Company = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [maxPages, setMaxPages] = useState(1);
  const [dataIndexed, setDataIndexed] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);

	const dispatch = useDispatch();
  const data = useSelector(state => state.companies.companies);
	const loading = useSelector(state => state.root.loading);


  useEffect(() => {
    dispatch(getCompanies())
  }, [dispatch])

  useEffect(() => {
    const startingIndex = (page - 1) * perPage;
    const endingIndex = startingIndex + perPage;
    dataFiltered.length ?
    setDataIndexed(dataFiltered?.map((x, i) => {
      if(i < endingIndex && i >= startingIndex){ return x }
      else return null
    }))
    :
    setDataIndexed(data?.map((x, i) => {
      if(i < endingIndex && i >= startingIndex){ return x }
      else return null
    }))
  }, [perPage, page, data, dataFiltered])
  // console.log('dataIndexed', dataIndexed);

  const removeData = (id) => {
    dispatch(removeCompany(id))
  }

  const table = !loading && dataIndexed && dataIndexed.length ? (
    <CompanyTable
      data={dataIndexed}
			removeCompany={(id) => removeData(id)}
    />
  ) : <EmptyTable columns={appConstants.COMPANIES_COLUMNS} />

  return (
    <React.Fragment>
      <div className="content">
        <div className="page-inner">
          <div className="page-header">
            {/*<h4 className="page-title">Company table</h4>*/}
            {/*<ul className="breadcrumbs">
                <li className="nav-home">
                  <a href="#">
                    <i className="flaticon-home"></i>
                  </a>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow"></i>
                </li>
                <li className="nav-item">
                  <a href="#">Tables</a>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow"></i>
                </li>
                <li className="nav-item">
                  <a href="#">Datatables</a>
                </li>
              </ul> */}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <h4 className="card-title">Ministries</h4>
                    <a className="btn btn-primary btn-round ms-auto" href="/ministries/new">
                      <i className="fa fa-plus"></i>
                      Add Row
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    {table}
                  </div>
                </div>
                <div className='card-footer'>
                  <PageIndex data={data} dataFiltered={dataFiltered} setDataFiltered={setDataFiltered} page={page} setPage={setPage} maxPages={maxPages} setMaxPages={setMaxPages} perPage={perPage} setPerPage={setPerPage}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

// function mapStateToProps(state) {
//   return {
//     data: state.companies.companies,
//     error: state.companies.error,
//     success_message: state.companies.success_message,
//     success: state.companies.success
//   }
// }

export default Company
