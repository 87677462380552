import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';
class InputField extends ValidatorComponent {
    renderValidatorComponent() {
      const { errorMessages, validators, requiredError, validatorListener,withRequiredValidator, ...rest } = this.props;
      rest['className'] = this.state.isValid ? rest['className'] : rest['className']+' input_error'
      return (
          <div>
              <input
                  {...rest}
                  ref={(r) => { this.input = r; }}
              />
              {this.errorText()}
            {/*labelError(this.state.isValid,this.input)*/}
             
          </div>
      );
  }

  errorText() {
   const { isValid } = this.state;

    if (isValid) {
        return null;
    }else{
    	 return (
        <div style={{ color: '#f25961',fontSize: '80%', fontWeight: 600}}>
            {this.getErrorMessage()}
        </div>
    	);
    }
  }
}

export default InputField


