import React from 'react'
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'
import './global'
 
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

// eslint-disable-next-line
String.prototype.spaceBeforeCap = function () {
  return String(this).toLowerCase().replace(/(^| )(\w)/g, x => x.toUpperCase())
}

// app and admin routes
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <App />
  </Router>
);

serviceWorker.unregister()