import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import EmptyTable from "../../shared/empty-table";
import Loader from "../../shared/loader";
// import { downloadInvoices } from "../../state/invoices/invoiceSlice";
// import { downloadTransactions, voidTransaction } from "../../state/transactions/transactionSlice";
import { getData } from "../../state/search/searchSlice";
import appConstants from "../../constants/constants";
import TransactionTable from "./_transaction.table";
import { useLocation, useNavigate } from "react-router";
import PageIndex from "../../shared/pageIndex";

const SearchTransaction = () => {
  const [currentTransaction, setCurrentTransaction] = useState({});
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [maxPages, setMaxPages] = useState(1);
  const [dataIndexed, setDataIndexed] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [sortedBy, setSortedBy] = useState('created_at');
  const [sortByLatest, setSortByLatest] = useState(true);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector(state => state.search.data);
  const loading = useSelector(state => state.root.loading);
  // const search_target = useSelector(state => state.search.search_target);

  // Data Sorter by Both column data values and latest or oldest record (alphabetical order or opposite)
  const sortByFunction = useCallback(() => {
    const sortedFunc = (column, _column_2) => {
      if (_column_2) {
        if (sortByLatest) return dataFiltered?.toSorted(function(a, b) {
          return (a[column][_column_2]===null)-(b[column][_column_2]===null) || +(a[column][_column_2]>b[column][_column_2])||-(a[column][_column_2]<b[column][_column_2]);
        });
        return dataFiltered?.toSorted(function(a, b) {
          return (a[column][_column_2]===null)-(b[column][_column_2]===null) || -(a[column][_column_2]>b[column][_column_2])||+(a[column][_column_2]<b[column][_column_2]);
        });
      } else {
        if (sortByLatest) return dataFiltered?.toSorted(function(a, b) {
          return (a[column]===null)-(b[column]===null) || +(a[column]>b[column])||-(a[column]<b[column]);
        });
        return dataFiltered?.toSorted(function(a, b) {
          return (a[column]===null)-(b[column]===null) || -(a[column]>b[column])||+(a[column]<b[column]);
        });
      }
    }

    switch(sortedBy) {
      case 'paid_date':
        const distantFuture = new Date(8640000000000000)

        if (sortByLatest) {
          return dataFiltered?.toSorted((a, b) => {
            const a_date = a?.m_status == 'success' ? a.created_at : distantFuture
            const b_date = b?.m_status == 'success' ? b.created_at : distantFuture

            return new Date(b_date) - new Date(a_date)
          })
        }
        return dataFiltered?.toSorted((a, b) => {
          const a_date = a?.m_status == 'success' ? a.created_at : distantFuture
          const b_date = b?.m_status == 'success' ? b.created_at : distantFuture

          return new Date(a_date) - new Date(b_date)
        })
      case 'created_at':
        if (sortByLatest) return dataFiltered?.toSorted((a, b) => new Date(b?.created_at) - new Date(a?.created_at))
        return dataFiltered?.toSorted((a, b) => new Date(a?.created_at) - new Date(b?.created_at))
      case 'invoice_reference':
        return sortedFunc('invoice_reference')
      case 'invoice_refernce':
        return sortedFunc('invoice_refernce')
      case 'invoice_location':
        return sortedFunc('invoice_location')
      case 'revenue_line_item':
        return sortedFunc('revenue_line_item', 'long_desc')
      case 'invoice_email':
        return sortedFunc('invoice_email')
      case 'cashier':
        return sortedFunc('cashier')
      case 'transaction_first_name':
        return sortedFunc('transaction_first_name')
      case 'transaction_last_name':
        return sortedFunc('transaction_last_name')
      case 'amount_charged':
        if (sortByLatest) return dataFiltered?.toSorted((a, b) => a?.amount_charged - b?.amount_charged)
        return dataFiltered?.toSorted((a, b) => b?.amount_charged- a?.amount_charged)
      case 'm_status':
        return sortedFunc('m_status')
      case 'auth_dmsg':
        return sortedFunc('auth_dmsg')
      case 'transaction_mode':
        return sortedFunc('transaction_mode')
      case 'third_party_type':
        return sortedFunc('third_party_type')
      case 'third_party_reference':
        return sortedFunc('third_party_reference')
      case 'void':
        return sortedFunc('void')
      default:
        if (sortByLatest) return dataFiltered?.toSorted((a, b) => new Date(b?.created_at) - new Date(a?.created_at))
        return dataFiltered?.toSorted((a, b) => new Date(a?.created_at) - new Date(b?.created_at))
    }
  }, [dataFiltered, sortedBy, sortByLatest])

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = {
      query_type: params.get('query_type'),
      query: params.get('query'),
      search_target: 'transaction',
    };

    if (!query.query_type || !query.query) {
      navigate('/search');
    }

    dispatch(getData(query));
  }, [navigate, dispatch, location])

  useEffect(() => {
    const startingIndex = (page - 1) * perPage;
    const endingIndex = startingIndex + perPage;
    if (dataFiltered && dataFiltered.length) {
      let dataFilterSorted = sortByFunction();
      // console.log('DataFilterSorted', dataFilterSorted);

      if (dataFilterSorted) {
        setDataIndexed(dataFilterSorted?.map((x, i) => {
          if(i < endingIndex && i >= startingIndex){ return x }
          else return null
        }))
      }
    } else {
      let dataSorted = sortByFunction();
      // console.log('dataSorted', dataSorted);

      if (dataSorted) {
        setDataIndexed(dataSorted?.map((x, i) => {
          if(i < endingIndex && i >= startingIndex){ return x }
          else return null
        }))
      }
    }
  }, [perPage, page, data, dataFiltered, sortByFunction])

  // useEffect(() => {
  //   const startingIndex = (page - 1) * perPage;
  //   const endingIndex = startingIndex + perPage;
  //   dataFiltered.length ?
  //   setDataIndexed(dataFiltered?.map((x, i) => {
  //     if(i < endingIndex && i >= startingIndex){ return x }
  //     else return null
  //   }))
  //   :
  //   setDataIndexed(data?.map((x, i) => {
  //     if(i < endingIndex && i >= startingIndex){ return x }
  //     else return null
  //   }))
  // }, [perPage, page, data, dataFiltered])

  const viewTransaction = () => {
    const rows = Object.keys(currentTransaction).map((item) => (
      <tr>
        <td>{item.replace('_', ' ').spaceBeforeCap()}</td>
        <td>{currentTransaction[item]}</td>
      </tr>
    ))

    return (
      <div
        className='modal fade'
        id='TransactionModal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                View Transaction
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <table className='table'>{rows}</table>
            </div>
            <div className='modal-footer'></div>
          </div>
        </div>
      </div>
    )
  }

  const showTransaction = (current_transaction) => {
    setCurrentTransaction(current_transaction);
  }

  const table = !loading && dataIndexed && dataIndexed.length ? (
      <TransactionTable
        data={dataIndexed}
        // fullData={data}
        sortedBy={sortedBy}
        setSortedBy={setSortedBy}
        sortByLatest={sortByLatest}
        setSortByLatest={setSortByLatest}
        showTransaction={showTransaction}
        // voidTransaction={voidTransaction}
        // downloadTransactions={ddownloadTransactions}
      />
    ) : (
      <EmptyTable columns={appConstants.TRANSACTIONS_COLUMNS} />
    );

  return (
    <React.Fragment>
      <Loader loading={loading} />

      <div className="content">
        <div className="page-inner">
          <div className="page-header"></div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <h4 className="card-title">Results</h4>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">{table}</div>
                </div>
                <div className='card-footer'>
                  <PageIndex data={data} dataFiltered={dataFiltered} setDataFiltered={setDataFiltered} page={page} setPage={setPage} maxPages={maxPages} setMaxPages={setMaxPages} perPage={perPage} setPerPage={setPerPage}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {viewTransaction()}
    </React.Fragment>
  );
}

// const mapStateToProps = ({ search }) => ({
//   data: search.data,
//   error: search.error,
//   success_message: search.success_message,
//   success: search.success,
//   search_target: search.search_target,
//   loading: search.loading,
// });

export default SearchTransaction
