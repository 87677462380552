import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios";

const headers = () => {
  let user = sessionStorage.getItem("user");
  user = JSON.parse(user);
  return {
    "Content-Type": "application/json",
    Authorization: user.auth_token,
  };
}

const initialState = {
  authenticated: false,
  loading: false,
  data: null, //[], 
  currentUser: null,
  userData: null,
  rolesData: null,
  rememberedUser: 'pending',
  success_message: undefined,
  success: false,
  error: undefined ,
  rm_loading: false
}

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    errorLog: (state, action) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = undefined;
    },
    getRmRequest: (state) => {
      state.rm_loading = true;
    },
    stopLoader: (state) => {
      state.rm_loading = false;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
    // UserLogin
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = "Successfully logged in.";
        // state.data = action.payload.data;
        state.currentUser = action.payload.data.user;
        // console.log(action);
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.currentUser = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // UserLogout
      .addCase(userLogout.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLogout.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = "Successfully logged out.";
        // state.data = action.payload.data;
        state.currentUser = null;
        // console.log(action);
      })
      .addCase(userLogout.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.currentUser = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // RememberToken
      .addCase(rememberToken.pending, (state) => {
        state.rm_loading = true;
      })
      .addCase(rememberToken.fulfilled, (state, action) => {
        state.rm_loading = false;
        state.loading = false;
        state.success = true;
        state.success_message = "Welcome back";
        // state.data = action.payload.data;
        state.currentUser = action.payload.data.user;
        // state.rememberedUser = 'yes';
        // console.log(action);
      })
      .addCase(rememberToken.rejected, (state, action) => {
        state.rm_loading = false;
        state.loading = false;
        state.success = false;
        state.currentUser = null;
        // state.rememberedUser = 'no';
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Forget Password
      .addCase(forgetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = "Password reset link has been sent please check your mail";
        // console.log(action);
      })
      .addCase(forgetPassword.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Reset Password
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = "Password has been updated";
        // console.log(action);
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Get User
      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.error = undefined;
        state.userData = action.payload.data;
        // console.log(action);
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.currentUser = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Get Users
    .addCase(getUsers.pending, (state) => {
      state.loading = true;
    })
    .addCase(getUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.success_message = undefined;
      state.error = undefined;
      // console.log(action);
      state.userData = action.payload.data.companies;
    })
    .addCase(getUsers.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.currentUser = null;
      // console.log('error', action);
      state.error = action.payload.error;
    })
    // Get Roles
    .addCase(getRoles.pending, (state) => {
      state.loading = true;
    })
    .addCase(getRoles.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.success_message = undefined;
      state.error = undefined;
      state.rolesData = action.payload.data.roles;
      // console.log(action);
    })
    .addCase(getRoles.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.rolesData = null;
      // console.log('error', action);
      state.error = action.payload.error;
    })
    // Save User
    .addCase(saveUser.pending, (state) => {
      state.loading = true;
    })
    .addCase(saveUser.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.success_message = 'User successfully created';
      state.error = undefined;
      state.company = action.payload.data;
      // console.log(action);
    })
    .addCase(saveUser.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.company = null;
      // console.log('error', action);
      state.error = action.payload.error;
    })
    // Update User
    .addCase(updateUser.pending, (state) => {
      state.loading = true;
    })
    .addCase(updateUser.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.success_message = 'User successfully updated';
      state.error = undefined;
      state.company = action.payload.data;
      // console.log(action);
    })
    .addCase(updateUser.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.company = null;
      // console.log('error', action);
      state.error = action.payload.error;
    })
    // Update User Profile
    .addCase(updateProfile.pending, (state) => {
      state.loading = true;
    })
    .addCase(updateProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.success_message = 'Successfully updated';
      state.error = undefined;
      state.userData = action.payload.data.user;
      // console.log(action);
    })
    .addCase(updateProfile.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.userData = null;
      // console.log('error', action);
      state.error = action.payload.error;
    })
  }
})

export const { clearError, getRmRequest, stopLoader, errorLog } = userSlice.actions
export default userSlice.reducer;

  // case actionTypes.GET_ALL_USERS:
  //   return{...state,loading: false, usersData:  action.payload.response }
 
export const userLogin = createAsyncThunk(
  'users/userLogin',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/users/signin`, data)
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const rememberToken = createAsyncThunk(
  'users/rememberToken',
  async (token, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/users/get_user_remember_token?remember_token=${token}`)
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const userLogout = createAsyncThunk(
  'users/userLogout',
  async (_t, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/users/signout`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const forgetPassword = createAsyncThunk(
  'users/forgetPassword',
  async (email, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/passwords`, { user: email })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const resetPassword = createAsyncThunk(
  'users/resetPassword',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/passwords/update_by_token`, { user: data })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const getUser = createAsyncThunk(
  'users/getUser',
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/users/${id}`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const getUsers = createAsyncThunk(
  'users/getUsers',
  async (_t, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/users`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const getRoles = createAsyncThunk(
  'users/getRoles',
  async (_t, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/users/roles`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const saveUser = createAsyncThunk(
  'users/saveUser',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/users`, { user: data }, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async ({id, data}, { rejectWithValue }) => {
    try {
      const res = await axios.patch(`${process.env.REACT_APP_API_HOST}/api/v1/users/${id}`, { user: data }, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)


export const updateProfile = createAsyncThunk(
  'users/updateProfile',
  async ({id, data}, { rejectWithValue }) => {
    try {
      const res = await axios.patch(`${process.env.REACT_APP_API_HOST}/api/v1/users/${id}`, { user: data }, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)
