import React from 'react';
// import {Link} from 'react-router-dom'
import appConstants from '../../constants/constants'
import '../../assets/css/pagination.css'

const RevenueLineItemTable = ({ data }) => {
  const viewData = data => data.map(l => l && (
  	<tr key={l.id}>
			<td>{l.account_id}</td>
			<td>{l.long_desc}</td>
			<td>{l.batch_name}</td>
		</tr>
	))

	return (
		<table id="basic-datatables" className="display table table-striped table-hover" >
			<thead>
				<tr>
					{appConstants.REVENUE_LINE_ITEMS_COLUMNS.map((item, i) => <th key={`head-${i}`}>{item.title}</th>)}
				</tr>
			</thead>
			<tfoot>
				<tr>
					{appConstants.REVENUE_LINE_ITEMS_COLUMNS.map((item, i) => <th key={`foot-${i}`}>{item.title}</th>)}
				</tr>
			</tfoot>
			<tbody>
				{viewData(data)}
			</tbody>
		</table>
	)
}

export default RevenueLineItemTable
