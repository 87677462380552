import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import EmptyTable from '../../shared/empty-table'
import Loader from '../../shared/loader'
import { getUsers } from '../../state/users/userSlice';
import appConstants from '../../constants/constants'
import UsersTable from './_table'
import PageIndex from '../../shared/pageIndex';

const Users = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [maxPages, setMaxPages] = useState(1);
  const [dataConstruct, setDataConstruct] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [dataIndexed, setDataIndexed] = useState([]);
  // const today = new Date()
  // const [isOpen, toggleIsOpen] = useState(false);
	// const [currentList, setCurrentList] = useState({});
  // const [currentTransaction, setCurrentTransaction] = useState({})
  // const [dateRange, setDateRange] = useState({ date_start: today, date_end: today });
  // const nonce = 0;
	// const [data, setData] = useState([]);

	const dispatch = useDispatch();
  const data = useSelector(state => state.users.userData);
  const loading = useSelector(state => state.root.loading);

  useEffect(() => {
    dispatch(getUsers());
    // .unwrap()
    // .then(() => {
    //   toggleIsOpen(false);
    // })
  }, [dispatch])

  useEffect(() => {
    if (!data?.length) { return }

    const dataCons = data?.map(company => company.users.map( x => ({
      id: x.id,
      first_name: x.first_name,
      last_name: x.last_name,
      email: x.email,
      company_name: company.name_of_company,
    }))).flat()
    setDataConstruct(dataCons)
  }, [data])
    // console.log('dataConstruct', dataConstruct);

  useEffect(() => {
    const startingIndex = (page - 1) * perPage;
    const endingIndex = startingIndex + perPage;
    dataFiltered.length ?
    setDataIndexed(dataFiltered?.map((x, i) => {
      if(i < endingIndex && i >= startingIndex){ return x }
      else return null
    }))
    :
    setDataIndexed(dataConstruct?.map((x, i) => {
      if(i < endingIndex && i >= startingIndex){ return x }
      else return null
    }))
  }, [perPage, page, dataConstruct, dataFiltered])
    // console.log('dataIndexed', dataIndexed);

  const table = !loading && dataIndexed && dataIndexed.length ? (
    <UsersTable
      data={dataIndexed}
      // showTransaction={showTransaction}
    />
  ) : <EmptyTable columns={appConstants.USERS_COLUMNS} />

  return (
    <React.Fragment>
      <Loader loading={loading} />

      <div className="content">
        <div className="page-inner">
          <div className="page-header"></div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="card-title">Users</h4>
                    <a className="btn btn-primary btn-round" href="/users/new">
                      <i className="fa fa-plus"></i> Create User
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    {table}
                  </div>
                </div>
                <div className='card-footer'>
                <PageIndex data={dataConstruct} dataFiltered={dataFiltered} setDataFiltered={setDataFiltered} page={page} setPage={setPage} maxPages={maxPages} setMaxPages={setMaxPages} perPage={perPage} setPerPage={setPerPage}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

// const mapStateToProps = ({ users }) => ({
//   data: users.userData,
//   error: users.error,
//   success: users.success,
//   loading: users.loading,
//   success_message: users.success_message,
// })

export default Users
