var crypto = require('crypto');
var enckey = process.env && process.env.REACT_APP_ENC_KEY
var cryptkey = crypto.createHash('sha256').update(enckey, 'ascii').digest();
var algorithm = 'aes-256-cbc';
var iv = '6363636363458459';

var SimpleEncrypt = (function () {
  function encrypt(data) {
  	var cipher = crypto.createCipheriv(algorithm,cryptkey,iv)
  	var crypted = cipher.update(data,'utf-8',"base64")
  	crypted += cipher.final("base64");
  	return crypted;
  }

  function decrypt(data) {
    var cipher = crypto.createDecipheriv(algorithm,cryptkey,iv)  
    var crypted = cipher.update(data, "base64", "utf-8") 
    crypted += cipher.final("utf-8");
    return crypted;
  }

  return {
    encrypt: encrypt,
    decrypt: decrypt  
  };
}());


export default SimpleEncrypt