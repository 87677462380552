import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { ValidatorForm } from 'react-form-validator-core'

import { saveTransaction } from '../state/transactions/transactionSlice'
import appConstants from '../constants/constants'

import Loader from '../shared/loader'
import InputField from '../shared/form-fields/input'
import formatPhoneNumber from '../helpers/normalize-phone'
import { errorLog } from '../state/root/rootSlice'

const Field = ({ name, label, required, children }) => (
  <div className='form-group form-show-validation row'>
    <label
      htmlFor={name}
      className='col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end'
    >
      {label}
      {required !== false ? <span className="required-label">*</span> : null}
    </label>
    <div className='col-lg-4 col-md-9 col-sm-8'>
      {children}
    </div>
  </div>
)

const CardSwipeForm = () => {
  const [form, setForm] = useState({
    invoice_number: '',
    first_name: '',
    last_name: '',
    email: '',
    amount: '',
    'card-name': '',
    'card-number' : '',
    'card-cvv'    : '000',
    'card-exp'    : '',
  })
  const [invoice, setInvoice] = useState({ long_desc: '' });
  const [nonce, setNonce] = useState(0);
  const [swipe, setswipe] = useState('');
  const successMessage = useSelector(state => state.root.success_message);
  const loading = useSelector(state => state.root.loading);

  const dispatch = useDispatch();
  
  // const user = sessionStorage.user && JSON.parse(sessionStorage.user)
  const BASE_URL = process.env.REACT_APP_API_HOST + '/' + appConstants.API_V



  useEffect(() => {
    const resetForm = () => {
      setForm({ 
        invoice_number: '',
        first_name: '',
        last_name: '',
        email: '',
        amount: '',
        'card-name': '',
        'card-number' : '',
        'card-cvv'    : '000',
        'card-exp'    : '',
      });
      setNonce(nonce => nonce + 1);
    }

    resetForm();
  }, [successMessage])

  const fetch = {
    token: async () => {
      try {
        const { data } = await axios.get(BASE_URL + '/fetch_guest_token')
        return data.token
      } catch (err) {
        dispatch(errorLog(err.response.data.error));
      }
    },

    invoice: async ({ target }) => {
      const Authorization = await fetch.token()

      try {
        const { data } = await axios.get(
          `${BASE_URL}/revenue_line_items/by_invoice_number/${target.value}`,
          { headers: { Authorization } }
        )

        const inv = data?.revenue_line_item

        if(inv) {
          const amt = parseFloat(data.invoice_amount.toFixed(2));
          setForm({ ...form, amount: amt, 'card-amount': amt })
          setInvoice(inv);
        }
      } catch (err) {
        dispatch(errorLog(err.response.data.error));
      }
    },
  }

  const on = {
    change: ({ target }) => {
      setForm({ ...form, [target.name]: target.value})
    },

    swipe: ({ target }) => {
      const str = swipe + target.value;
      // console.log(str);
      setswipe(swipe + target.value);
      // const str = swipe = swipe + target.value
      setswipe('')

      // Attempt #1 (Default)
      try {
        const cc = {}
        cc['card-number'] = str.match(/\?;(\d+)=/).pop()
        cc['card-exp']    = str.match(/=(\d{4})/).pop().replace(/(\d{2})(\d{2})/, '$2/$1')
        cc['card-name']   = str.match(/\^(.+)\^/)?.pop().split('/').reverse().join(' ').toUpperCase() || ''
        setswipe('')
        // console.log(cc);

        setForm({ ...form, ...cc })
      } catch (err) {
        // console.log(err);
      }

      // Attempt #2 (Scotia Cards)
      try {
        const cc = {}
        cc['card-name']   = str.match(/^([\w']+).+\^([\w-]+)\s?/).slice(1, 3).join(' ').toUpperCase()
        cc['card-number'] = str.match(/\?%B(\d+)\^/i).pop()
        cc['card-exp']    = str.match(/\w+\s{0,}\^(\d{4})/).pop().replace(/(\d{2})(\d{2})/, '$2/$1')
        setswipe('')
        // console.log(cc);

        setForm({ ...form, ...cc })
      } catch (err) {
        // console.log(err);
      }

      target.value = '';
    },

    submit: async ev => {
      ev.preventDefault()
      // console.log(form);

      if (loading) return
      if (form.mobile_number) {
        setForm({ ...form, mobile_number: formatPhoneNumber(form.mobile_number) })
      }

      const data = {
        ...form,
        'transaction_mode':"agency",
        'third_party_type' :"credit",
        'revenue_line_item_id': invoice.id,
      }

      dispatch(saveTransaction(data))
    },
  }

  const blockSubmission = ev => {
    if (ev.keyCode == 13) {
      ev.preventDefault()
      return false
    }
  }

  const useDefaultEmail = () => {
    setForm({...form, email: 'digipayusers@bahamas.gov.bs'});
  }

  return (
    <React.Fragment >
      <Loader loading={loading}/>
      <div className="content" key={nonce}>
        <div className="page-inner">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Debit/Credit Card</div>
                </div>
                <ValidatorForm method="post" onSubmit={on.submit} >
                  <div className="card-body">
                    <div>Invoice Details</div>
                    <hr />

                    <Field name='invoice_number' label='Agency Invoice Number'>
                      <InputField
                        onChange={on.change}
                        onBlur={fetch.invoice}
                        name="invoice_number"
                        value={form?.invoice_number || ''}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        className="form-control"
                        placeholder="Invoice Number"
                      />
                    </Field>

                    <Field name='revenue_line_item' label='Revenue Line Item'>
                      <InputField readOnly value={invoice?.long_desc} className="form-control"/>
                    </Field>

                    <Field name='card_amount' label='Amount'>
                      <InputField readOnly value={form?.amount} className="form-control"/>
                    </Field>

                    <div>Customer Info</div>
                    <hr />

                    <Field name='first_name' label='First Name'>
                      <InputField
                        onChange={on.change}
                        name="first_name"
                        value={form?.first_name || ''}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        className="form-control"
                        placeholder="First Name"
                      />
                    </Field>

                    <Field name='last_name' label='Last Name'>
                      <InputField
                        onChange={on.change}
                        name="last_name"
                        value={form?.last_name || ''}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        className="form-control"
                        placeholder="Last Name"
                      />
                    </Field>

                    <Field name='email' label='Email'>
                      <InputField
                        onChange={on.change}
                        name="email"
                        value={form?.email || ''}
                        validators={['required', 'isEmail']}
                        errorMessages={['This field is required', 'Must be a valid email address']}
                        className="form-control"
                        placeholder="Email"
                      />
                      <a role="button" onClick={useDefaultEmail}>Default Email</a>
                    </Field>

                    <Field name='sms' required={false} label='Mobile Number'>
                      <InputField
                        onChange={on.change}
                        name="mobile_number"
                        value={form?.mobile_number || ''}
                        className="form-control"
                        placeholder="Mobile Number"
                        type="number"
                      />
                      <p>All phone numbers must start with 1242</p>
                    </Field>

                    <Field name='notes' required={false} label='Additional Notes'>
                      <textarea
                        onChange={on.change}
                        name="notes"
                        maxLength="250"
                        value={form?.notes || ''}
                        className="form-control"
                        placeholder="Additional Notes"
                        type="text"
                      />
                    </Field>

                    <div>Card Info</div>
                    <hr />

                    <Field name='cc_info' label='Debit/Credit Card' required={false}>
                      <input
                        placeholder='Awaiting Card Swipe...'
                        name='cc_info'
                        className='form-control'
                        // value={swipe}
                        onBlur={on.swipe}
                        onKeyUp={blockSubmission}
                        onKeyDown={blockSubmission}
                      />
                    </Field>

                    <Field name='card-name' label='Cardholder Name'>
                      <InputField
                        name="card-name"
                        className='form-control'
                        value={form['card-name'] || ''}
                        // validators={['required']}
                        errorMessages={['This field is required']}
                        autoComplete='off'
                        readOnly
                      />
                    </Field>

                    <Field name='card-number' label='Card Number'>
                      <InputField
                        name="card-number"
                        className='form-control'
                        value={form['card-number'] || ''}
                        // validators={['required']}
                        errorMessages={['This field is required']}
                        autoComplete='off'
                        readOnly
                      />
                    </Field>

                    <Field name='card-exp' label='Card Expiry'>
                      <InputField
                        name="card-exp"
                        className='form-control'
                        value={form['card-exp'] || ''}
                        // validators={['required']}
                        errorMessages={['This field is required']}
                        autoComplete='off'
                        readOnly
                      />
                    </Field>
                  </div>

                  <div className="card-action">
                    <div className="row">
                      <div className='col-lg-7 text-end'>
                        <input
                          style={{backgroundColor: '#00665d'}}
                          className={`btn me-2 text-white`}
                          type="submit"
                          value="Submit"
                          disabled={loading} />
                      </div>
                    </div>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

// state.transactions,
// 'loading',
// 'transaction',
// 'success_message',
// 'error',

export default CardSwipeForm
