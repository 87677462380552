import { useEffect } from 'react'
import { userLogout } from '../state/users/userSlice'
import { clearError } from '../state/root/rootSlice'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import cookie from "react-cookies";

export const ROUTE_OPEN = true

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(userLogout()).unwrap()
      .then(() => {
        sessionStorage.clear();
        cookie.remove("rememberFinanceObj", { path: "/" });
        navigate('/login');
        dispatch(clearError());
      })
      .catch(() => {
        sessionStorage.clear();
        cookie.remove("rememberFinanceObj", { path: "/" });
        navigate('/login');
        dispatch(clearError());
      })
  })

  return null
}

export default Logout
