import React, { useEffect, useState } from 'react';
import Select from 'react-dropdown-select';
import '../assets/css/pagination.css'


const PageIndex = ({ data, dataFiltered, setDataFiltered, setPage, page, maxPages, setMaxPages, setPerPage, perPage }) => {
  const [searchFilter, setSearchFilter] = useState('');

  const handleIndexChange = (e, nextPage = false) => {
    e.preventDefault();
    if(!nextPage && ((page - 1) !== 0)) setPage(page - 1);
    if(nextPage && ((page + 1) <= maxPages)) setPage(page + 1);
  };

  useEffect(() => {
    setPage(1);
    dataFiltered?.length ?
    setMaxPages(Math.ceil(dataFiltered?.length / perPage))
    :
    setMaxPages(Math.ceil(data?.length / perPage))
  }, [data, dataFiltered, perPage, setPage, setMaxPages])

  // Handle Search Filter
  useEffect(() => {
    var result = data?.filter(function(item) {
    	for(var key in item) {
      	if(item.hasOwnProperty(key) && item[key] && item[key].toString().toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) {
        	return true;
        }
      }
      return false;
    });
    setDataFiltered(result);
  }, [searchFilter, data, setDataFiltered])

  const itemsPerPage = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ]

  const maxPageSpan = () => {
    const numberList = [];

    let startIndex = 0;
    let endIndex = maxPages;
    if(maxPages < 11){ startIndex = 0 }
    else { 
      if(page - 4 > 0){ startIndex = page - 4 }
      else { startIndex = 0 }
    }
    if(maxPages < 11){ endIndex = maxPages }
    else {
      if(page + 4 > maxPages){ endIndex = maxPages }
      else { endIndex = page + 4 }
    }

    for (let i = startIndex; i < endIndex; i ++) {
      const isActive = i == page - 1
      numberList.push(<button key={i} className={`p-1 paginate-button ${isActive && 'active-btn'}`} onClick={() => setPage(i + 1)}>{i + 1}</button>)
    }

    return numberList.length ? numberList : <span className='p-1'>1</span>
  }

  return (
    <div className='d-flex align-items-center'>
      <div className='me-4 d-flex align-items-center'>
        <span className='me-2'>Search</span>
        <input
          type='text'
          onChange={(e) => setSearchFilter(e.target.value)}
          name="searchFilter"
          value={searchFilter}
          className="form-control"
          style={{maxWidth: '120px'}}
        />
        <button className='ms-1 btn btn-secondary' onClick={() => setSearchFilter('')}>Clear</button>
      </div>
      <div className='me-2 d-flex align-items-center'>
        <span className='me-2'>Items per page</span>
        <Select
          onChange={(values) => setPerPage(values[0].value)}
          name="perPage"
          options={itemsPerPage}
          value={perPage}
          defaultValue={itemsPerPage[0]}
          className="form-control"
          dropdownPosition='auto'
        />
      </div>
      {page != 1 && <button className='p-previous' onClick={() => setPage(1)}>{'« First '}</button>}
      <button className='p-previous' onClick={(e) => handleIndexChange(e)}>{'< prev '}</button>
      {/* <span className='p-1'>{page}/{maxPages || 1}</span> */}
      {maxPageSpan()}
      <button className='p-next' onClick={(e) => handleIndexChange(e, true)}>{' next >'}</button>
      {page != maxPages && <button className='p-next' onClick={() => setPage(maxPages)}>{' Last »'}</button>}
      <div className='ms-2'>Page {page} / {maxPages}</div>
    </div>
  )
};

export default PageIndex
