export function preValue(frmVal,setVal){
	return frmVal !== undefined ? frmVal : setVal 
}

export function dateOnly(date){
  if(date.match("T")){
  	let dateStr = date && date.split('T')[0] || ""
  	let year = dateStr.substr(0,4)
  	let month = dateStr.substr(5,2)
    let dt = dateStr.substr(8,2)
  //return year+"-"+dt+"-"+month
    return dt+"-"+month+"-"+year
  }else{
    return date
  }
}


export function dateTimeOnly(date){
  if(date.match("T")){
    let dateStr = date && date.split('T')[0] || ""
    let year = dateStr.substr(0,4)
    let month = dateStr.substr(5,2)
    let dt = dateStr.substr(8,2)
    let time = date && date.split("T")[1]
    return  time === undefined ? converFormat(date) :  dt+"-"+month+"-"+year+" "+(time && time.split(".")[0])
  }else{
    return date
	  //return  time === undefined ? date :  year+"-"+dt+"-"+month+" "+(time && time.split(".")[0])
  }
}


export function converFormat(date){
  let time  = date.split(",")[1]
  let d1 = new Date(date)
  return d1.getDate().pad(2)+"-"+(d1.getMonth()+1).pad(2)+"-"+d1.getFullYear()+" "+time
}


// eslint-disable-next-line
Number.prototype.pad = function(size) {
    var s = String(this);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
}