import React from 'react';
import { Link } from 'react-router-dom'
import appConstants from '../../constants/constants'
import '../../assets/css/pagination.css'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css


const CompanyTable = ({data}) => {

	const removeData = (id) => {
		confirmAlert({
			title: '',
			message: 'Please reach out to support@velapay.com.',
			buttons: [
				{
					label: 'Ok',
					onClick: () => { }
				}
			]
		})
		//this.props.removeCompany(id)
	}

	const addActionData = item => (
			<div>
				<Link to={`ministries/edit?id=${item.id}`} className="btn btn-link btn-primary btn-lg" >
					<i className="fa fa-edit"></i>
				</Link>
				<button type="button" onClick={(e) => { removeData(e.target.dataset.id) }} data-toggle="tooltip" title="" className="btn btn-link btn-danger" data-original-title="Remove">
					<i className="fa fa-times" data-id={item.id}></i>
				</button>
			</div>
		)

	const viewData = (data) => {
		let list = data.map((company, index) => company && (
			<tr key={index}>
				<td>{company.id}</td>
				<td>{company.name_of_company}</td>
				<td>{company.island}</td>
				<td>{company.merchant_token_id}</td>
				<td>{addActionData(company)}</td>
			</tr>))
		return list
	}

	return (
		<table id="basic-datatables" className="display table table-striped table-hover" >
			<thead>
				<tr>
					{appConstants.COMPANIES_COLUMNS.map((item) => <th>{item.title}</th>)}
				</tr>
			</thead>
			<tfoot>
				<tr>
					{appConstants.COMPANIES_COLUMNS.map((item) => <th>{item.title}</th>)}
				</tr>
			</tfoot>
			<tbody>
				{viewData(data)}
			</tbody>
		</table>
	)
}

export default CompanyTable
