import JSEncrypt from 'jsencrypt';

export const encryptRsakey = async (key,data) => {
  const encryptData = await jsEncryptData(key,JSON.stringify(data))
  return encryptData
};

// Encrypt with the public key...
const jsEncryptData = (key,data) =>{
  return new Promise(async (resolve,reject)=>{
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey(key.replace(/\\n/g, '\n'));
    var encrypted = encrypt.encrypt(data);
    // Decrypt with the private key...
    resolve(encrypted)
  })
}
// 
