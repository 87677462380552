import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveUser, updateUser, getUser, getRoles } from '../../state/users/userSlice';
import { errorLog } from '../../state/root/rootSlice';
import { getCompanies } from '../../state/companies/companySlice';
import { preValue } from '../../helpers/tools';
import InputField from '../../shared/form-fields/input';
import SelectField from '../../shared/form-fields/select';
import { ValidatorForm } from 'react-form-validator-core';
import _ from 'lodash'
import { useLocation, useNavigate } from 'react-router';

export const ROUTE_PATHS = ['/users/new', '/users/edit']

let invobj = {}
var arr = []
var flag = false

const UserForm = () => {
  const location = useLocation();
  // const [selectedOption, setSelectedOption] = useState(null);
  const [formData, setFormData] = useState({ roles: [] });
  // const [userObj, setUserObj] = useState({});
  // const [roles, setRoles] = useState([]);
  const [passwordError, setPasswordError] = useState(false);
  // const user = JSON.parse(sessionStorage.user);
  const user = sessionStorage.user && JSON.parse(sessionStorage.user);
  const userFetch = useSelector(state => state.users.userData);
  // const [error, setError] = useState(undefined);
  const listId = location.search.replace('?id=', "");
  const companies = useSelector(state => state.companies.companies);
  const userObj = userFetch || user;
  // const roles = useSelector(state => state.users.rolesData)

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanies())
    dispatch(getRoles())
    if (listId){ dispatch(getUser(listId))}
  }, [dispatch, listId])


  const handleChange = (event) => {
    invobj[event.target.name] = event.target.value;
    setFormData({ ...formData, ...invobj });
  }

  const handleCheckbox = (e) => {
    // console.log("ev", e.target.checked, e.target.name)
    if (e.target.name == 'agency_cashier') {
      if (e.target.checked == false) {
        const index = arr.indexOf(3);
        if (index > -1) {
          arr.splice(index, 1);
        }
      } else {
        arr.push(3)
      }
    }
    else if (e.target.name == 'agency_accountant') {
      if (e.target.checked == false) {
        const index = arr.indexOf(4);
        if (index > -1) {
          arr.splice(index, 1);
        }
      } else {
        arr.push(4)
      }
    }
    else if (e.target.name == 'technology') {
      if (e.target.checked == false) {
        const index = arr.indexOf(9);
        if (index > -1) {
          arr.splice(index, 1);
        }
      } else {
        arr.push(9)
      }
    }
    else if (e.target.name == 'read') {
      if (e.target.checked == false) {
        const index = arr.indexOf(10);
        if (index > -1) {
          arr.splice(index, 1);
        }
      } else {
        arr.push(10)
      }
    }
    else if (e.target.name == 'inactive') {
      if (e.target.checked == false) {
        const index = arr.indexOf(8);
        if (index > -1) {
          arr.splice(index, 1);
        }
      } else {
        arr.push(8)
      }
    }
    // invobj[event.target.name] = event.target.value;
    // invobj[e.target.name] = e.target.checked
    // console.log("role arr == ", arr)
    if (e.target.checked == false) {
      const index = arr.indexOf();
      if (index > -1) {
        arr.splice(index, 1);
      }
    }

    invobj['role_ids'] = arr

    setFormData({ ...formData, ...invobj });
  }

  const selectHandle = (name, data) => {
    if (!_.isEmpty(data)) {
      invobj[name] = data[0].value;
      // console.log('SelectHandle', invobj);
      setFormData({ ...formData, ...invobj });
    }

  }

  // const handleSelectChange = selectedOption => {
  //   setSelectedOption(selectedOption)
  //   console.log(`Option selected:`, selectedOption);
  // };


  // const handleFileChange = (event) => {
  //   invobj[event.target.name] = event.target.files[0];
  //   setFormData(invobj);
  // }



  const handleSubmit = (event) => {
    event.preventDefault()

    if (formData.password == formData.password_confirmation) {
      setPasswordError(false);

      if (!listId) {
        // this.state.roles.forEach(u =>{
        // console.log('event',event.target.value,this.state.formData)
        // this.state.formData.role_ids.push(u)
        // })
        dispatch(saveUser(formData)).unwrap()
          .then(() => {
            navigate('/users');
          })
          .catch((err) => {
            dispatch(errorLog(err))
          })
      }
      else {
        // this.state.roles.forEach(u =>{
        // 	this.state.formData.role_ids.push(u)
        // })
        invobj['role_ids'] = arr
        setFormData({ ...formData, ...invobj });
        // console.log("this.", formData)
        dispatch(updateUser({ id: listId, data: formData })).unwrap()
        .then(() => {
          navigate('/users');
        })
        .catch((err) => {
          dispatch(errorLog(err))
        })
      }
    } else {
      setPasswordError(true)
    }
  }

  // const validateForm = () => {}

  // const handleMultiChange = (option) => {
  //   const optionList = option.map((op) => { return op.value })
  //   setRoles(optionList)
  // }

  let { email, password_confirmation, password, company, first_name, last_name } = userObj
  let comp = company && company.id ? { value: company.id, label: company.name_of_company } : ''
  // console.log(company);
  // console.log(comp);
  let technologyCheck = false
  let agencyCashierCheck = false
  let inactiveCheck = false
  let agencyRevenueCheck = false
  let readOnlyCheck = false

  let countryData = companies?.map((item) => ({ value: item.id, label: item.name_of_company }))
  if (userObj && userObj.roles && userObj?.roles?.length > 0) {
    for (let item of userObj.roles) {
      if (item.name == "technology") {
        technologyCheck = true
      } else if (item.name == "inactive") {
        inactiveCheck = true
      } else if (item.name == "agency_cashier") {
        agencyCashierCheck = true
      } else if (item.name == "agency_accountant") {
        agencyRevenueCheck = true
      } else if (item.name == "read") {
        readOnlyCheck = true
      } 
    }
  }
  if (userObj && userObj.roles && flag == false) {
    for (let role of userObj.roles) {
      arr.push(role.id)
    }
    flag = true
  }

  return (
    <React.Fragment>
      {((userObj && userObj.roles) || !listId) ? (
        <div className="content">
          <div className="page-inner">
            <div className="page-header">
              <h4 className="page-title">Form User</h4>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">User</div>
                  </div>
                  <ValidatorForm
                    // ref="form"
                    method="post"
                    onSubmit={(e) => handleSubmit(e)}
                  >

                    <div className="card-body">
                      {(!listId) && (
                        <div className="form-group form-show-validation row">
                          <label htmlFor="email" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">Email <span className="required-label">*</span></label>
                          <div className="col-lg-4 col-md-9 col-sm-8">
                            <InputField
                              onChange={handleChange}
                              name="email"
                              value={preValue(formData.email, email)}
                              validators={['required', 'isEmail']}
                              errorMessages={['This field is required']}
                              className="form-control"
                              placeholder="Email"
                              type="email"
                            />
                          </div>
                        </div>
                      )}
                      <div className="form-group form-show-validation row">
                        <label htmlFor="first_name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">First Name <span className="required-label">*</span></label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <InputField
                            onChange={handleChange}
                            name="first_name"
                            value={preValue(formData.first_name, first_name)}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            className="form-control"
                            placeholder="First Name"
                          />
                        </div>
                      </div>

                      <div className="form-group form-show-validation row">
                        <label htmlFor="last_name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">Last Name <span className="required-label">*</span></label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <InputField
                            onChange={handleChange}
                            name="last_name"
                            value={preValue(formData.last_name, last_name)}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            className="form-control"
                            placeholder="Last Name"
                          />
                        </div>
                      </div>

                      <div className="form-group form-show-validation row">
                        <label htmlFor="company_id" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">Company <span className="required-label">*</span></label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <SelectField
                            onChange={(values) => selectHandle('company_id', values)}
                            name="company_id"
                            options={countryData}
                            value={preValue(formData.company_id, comp)}
                            defaultValue={comp}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className='col-lg-10 text-center'>
                        <span className='mt-2'>Select/Toggle this user's active roles</span>
                      <div className="form-show-validation flex-row mt-2 mb-2">
                        <input type="checkbox" className="btn-check" name='read' id="read_only_check" autoComplete="off" onClick={handleCheckbox} defaultChecked={readOnlyCheck}/>
                        <label className="btn btn-outline-dark me-2" htmlFor="read_only_check">Read Only</label>

                        <input type="checkbox" className="btn-check" name='agency_cashier' id="agency_cashier_check" autoComplete="off" onClick={handleCheckbox} defaultChecked={agencyCashierCheck}/>
                        <label className="btn btn-outline-primary me-2" htmlFor="agency_cashier_check">Agency Cashier</label>

                        <input type="checkbox" className="btn-check" name='agency_accountant' id="agency_accountant_check" autoComplete="off" onClick={handleCheckbox} defaultChecked={agencyRevenueCheck}/>
                        <label className="btn btn-outline-primary me-2" htmlFor="agency_accountant_check">Agency Accountant</label>

                        <input type="checkbox" className="btn-check" name='technology' id="technology_check" autoComplete="off" onClick={handleCheckbox} defaultChecked={technologyCheck}/>
                        <label className='btn btn-outline-primary' htmlFor="technology_check">Technology</label>
                      </div>
                      </div>
                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">Inactive </label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <input
                            id="inactive_check"
                            name="inactive"
                            defaultChecked={inactiveCheck}
                            className="btn-check"
                            onClick={handleCheckbox}
                            type="checkbox"
                            autoComplete="off"
                          />
                          <label className='btn btn-outline-danger' htmlFor="inactive_check">Disabled</label>
                        </div>
                      </div>

                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">Password {!listId ? <span className="required-label">*</span> : ''}</label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <InputField
                            onChange={handleChange}
                            name="password"
                            value={preValue(formData.password, password)}
                            validators={listId ? [] : ['required']}
                            errorMessages={['This field is required']}
                            className="form-control"
                            placeholder="Password"
                            type="password"
                          />
                        </div>
                      </div>

                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">Confirm Password {!listId ? <span className="required-label">*</span> : ''}</label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <InputField
                            onChange={handleChange}
                            name="password_confirmation"
                            value={preValue(formData.password_confirmation, password_confirmation)}
                            validators={listId ? [] : ['required']}
                            errorMessages={['This field is required']}
                            className="form-control"
                            placeholder="Confirm Password"
                            type="password"
                          />
                        </div>
                      </div>
                      {passwordError ? <div className="required-label col-lg-10 text-center mt-2 mb-2">Password and Confirm password does not match</div> : ''}
                    </div>
                    <div className="card-action">
                      <div className="row">
                      <div className='col-lg-7 text-end'>
                        <input
                          style={{backgroundColor: '#00665d'}}
                          className={`btn me-2 text-white`}
                          type='submit'
                          value='Submit'
                        />
                        <button
                          style={{backgroundColor: '#a68b05'}}
                          className={`btn me-2 text-white`}
                          onClick={() => navigate('/users')}>Cancel</button>
                        </div>
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : '<div></div>'}
    </React.Fragment>
  );
}


// function mapStateToProps(state) {
//   return {
//     error: state.transactions.error,
//     companies: state.companies.companies || [],
//     roles: state.users.rolesData || [],
//     userObj: state.users.userData
//   }
// }


export default UserForm