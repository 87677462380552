import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ValidatorForm } from 'react-form-validator-core'

import { saveInvoice, updateInvoice, getInvoice, getLocations } from '../../state/invoices/invoiceSlice'
import { getRevenueLineItems } from '../../state/lineitems/lineItemSlice'
import formatPhoneNumber from '../../helpers/normalize-phone'
import Field from '../../shared/form-fields'

import islands from '../../constants/islands'
import { useLocation, useNavigate } from 'react-router'
import { errorLog } from '../../state/root/rootSlice'
import Swal from 'sweetalert2'

export const ROUTE_PATHS = ['/invoices/new', '/invoices/edit']

const InvoiceForm = () => {
  const location = useLocation();
  const [form, setForm] = useState({});
  // const [invoice, setInvoice] = useState(false);
  // const user = JSON.parse(sessionStorage.user);
  const listId = location.search.replace('?id=', '');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const invoice = useSelector((state) => state.invoices.invoice);
  const lineitems = useSelector((state) => state.lineitems.revenue_line_items);
  const places = useSelector((state) => state.invoices.places);

  ValidatorForm.addValidationRule('matchLineItem', val => {
    return val == form?.revenue_line_item_id
  })

  useEffect(() => {
    dispatch(getRevenueLineItems());
    dispatch(getLocations());
    listId && dispatch(getInvoice(listId));
  }, [dispatch, listId])

  // componentDidUpdate ({ invoice }) {
  //   invoice && this.setState(state => {
  //     state.invoice = true
  //     Object.assign(state.form, invoice)

  //     return state
  //   })
  // }

  useEffect(() => {
    invoice && setForm(form => ({form, invoice}))
  }, [invoice])

  const on = {
    change: ({ target }) => {
      setForm({...form, [target.name]: target.value})
    },

    file: ({ target }) => {
      setForm({...form, [target.name]: target.files?.[0]})
    },

    select: name => ([selected]) => selected && setForm({...form, [name]: selected.value}),

    submit: async ev => {
      ev.preventDefault()
      // console.log('Invoice Check', invoice);
      const lineItemOption = lineitems?.filter((item) => item.id === form.revenue_line_item_id)[0]

      await Swal.fire({
        title: "Confirm",
        html: "Are you sure you've selected the correct <b>Revenue Line Item ID</b> and the associated <b>Amount</b> to be charged?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: "Submit",
        cancelButtonText: "Cancel",
        footer: `<span><b>Revenue Line Item Number: </span><span style= 'color: green;'>${lineItemOption.long_desc}</span></br>
                 <span>Amount Charged: </span><span style= 'color: green;'>$${form.amount}</span></b>`
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (invoice) {
            dispatch(updateInvoice({ id: listId, data: {
              ...form,
              mobile_number: formatPhoneNumber(form.mobile_number),
            }})).unwrap()
            .then(() => {
              navigate('/invoices')
            })
            .catch((err) => {
              dispatch(errorLog(err))
            })
          } else {
            dispatch(saveInvoice({
              ...form,
              mobile_number: formatPhoneNumber(form.mobile_number),
              transaction_mode: 'agency'
            })).unwrap()
            .then(() => {
              navigate('/invoices')
            })
            .catch((err) => {
              dispatch(errorLog(err))
            })
          }
        }
      });
    }
  }

  const useDefaultEmail = () => setForm({...form, email: 'digipayusers@bahamas.gov.bs'})

  const options = lineitems?.map((item) => ({ value: item.id, label: item.long_desc }))
  const countryData = places?.map((item) => ({ value: item.id, label: item.name }))
  // const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  // const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return (
    <React.Fragment>
      <div className='content'>
        <div className='page-inner'>
          <div className='page-header'>
            <h4 className='page-title'>Form Invoice</h4>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='card'>
                <div className='card-header'>
                  <div className='card-title'>Invoice</div>
                </div>
                <ValidatorForm onSubmit={on.submit}>
                  <div className='card-body'>
                    <Field
                      type='text'
                      name='email'
                      onChange={on.change}
                      value={form?.email || ''}
                      validators={['required', 'isEmail']}
                      errorMessages={['This field is required', 'Must be a valid email address']}
                    >
                      <a role='button' onClick={useDefaultEmail}>Default Email</a>
                    </Field>

                    <Field
                      type='text'
                      name='first_name'
                      onChange={on.change}
                      value={form?.first_name || ''}
                    />

                    <Field
                      type='text'
                      name='last_name'
                      onChange={on.change}
                      value={form?.last_name || ''}
                    />

                    <Field
                      type='text'
                      name='mobile_number'
                      onChange={on.change}
                      value={form?.mobile_number || ''}
                      required={false}
                    >
                      <p>All phone numbers must start with 1242</p>
                    </Field>

                    <Field
                      type='text'
                      name='reference_code'
                      label='Agency Invoice Number'
                      onChange={on.change}
                      value={form?.reference_code || ''}
                    />

                    <Field
                      name='revenue_line_item_id'
                      onChange={on.select('revenue_line_item_id')}
                      options={options}
                      value={form?.revenue_line_item_id}
                    />

                    <Field
                      name='revenue_line_item_id_confirmation'
                      label='Confirm Line Item ID'
                      onChange={on.select('revenue_line_item_id_confirmation')}
                      options={options}
                      value={form?.revenue_line_item_id_confirmation}
                      validators={['required', 'matchLineItem']}
                      errorMessages={[
                        'This field is required',
                        'Please make sure both selected line items are the same.'
                      ]}
                    />

                    <Field
                      name='location'
                      label='Island'
                      options={islands}
                      onChange={on.select('location')}
                      value={form?.location}
                    />

                    <Field
                      name='place_id'
                      label='Location'
                      onChange={on.select('place_id')}
                      options={countryData}
                      value={form?.place_id}
                      required={false}
                    />
                    <Field
                      type='text'
                      name='amount'
                      inputMode='decimal'
                      onChange={on.change}
                      value={form?.amount || ''}
                      validators={['required', 'isFloat']}
                      errorMessages={['This field is required', 'This field should be numeric']}
                    />

                    <Field name='notes' label='Additional Notes' required={false}>
                      <textarea
                        name='notes'
                        onChange={on.change}
                        maxLength='250'
                        value={form?.notes}
                        className='form-control'
                        placeholder='Additional Notes'
                      />
                    </Field>

                  </div>
                  <div className='card-action'>
                    <div className='row'>
                      <div className='col-lg-7 text-end'>
                        <input
                          style={{backgroundColor: '#00665d'}}
                          className={`btn me-2 text-white`}
                          type='submit'
                          value='Submit'
                        />
                        <button
                          style={{backgroundColor: '#a68b05'}}
                          className={`btn me-2 text-white`}
                          onClick={() => navigate('/invoices')}
                        >Cancel</button>
                      </div>
                    </div>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default InvoiceForm
