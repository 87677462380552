import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveReport } from '../../state/reports/reportSlice';
import { getRevenueLineItems } from '../../state/lineitems/lineItemSlice';
import { preValue } from '../../helpers/tools'
import SelectField from '../../shared/form-fields/select'
import { ValidatorForm } from 'react-form-validator-core';
import _ from 'lodash'
import DateTimeField from '../../shared/form-fields/date'
import { useNavigate } from 'react-router';
import { errorLog } from '../../state/root/rootSlice';

export const ROUTE_PATHS = ['/report/generate']

let invobj = {}
const ReportForm = () => {
  const today = new Date()

  // const loc = useLocation();
  const [formData, setFormData] = useState({});
  // const [invoice, setInvoice] = useState({});
  const [dateRange, setDateRange] = useState({ date_start: today, date_end: today });
  // const user = useSelector(state => state.users.currentUser);
  const invoice = useSelector(state => state.invoices.invoice);
  // const listId = loc.search.replace('?id=', '');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRevenueLineItems());
  }, [dispatch])

  // const handleChange = (event) => {
  //   invobj[event.target.name] = event.target.value;
  //   setFormData(invobj);
  // }

  const formatDate = date => typeof date == 'string'
  ? new Date(date.replace(/^(.{4})(.{2})(.{2})$/, "$1-$2-$3"))
  : date

  const on = {
    date: name => d => {
      setDateRange({ [name]: formatDate(d), ...dateRange })
    },
  }

  const selectHandle = (name, data) => {
    if (!_.isEmpty(data)) {
      invobj[name] = data[0].value;
      setFormData(invobj);
    }
  }

  // componentDidUpdate(nextProps) {
  //   if (!_.isEmpty(nextProps.invoice)) {
  //     this.setState({ invoice: nextProps.invoice })
  //   }
  // }

  // const handleFileChange = (event) => {
  //   invobj[event.target.name] = event.target.files[0];
  //   setFormData(invobj);
  // }

  const handleSubmit = (event) => {
    event.preventDefault()
    dispatch(saveReport({ data: formData, start: dateRange.date_start, end: dateRange.date_end })).unwrap()
    .then(() => {
      navigate('/report')
    })
    .catch((err) => {
      dispatch(errorLog(err))
    })
  }

  // const validateForm = () => {}

  const { location } = invoice
  const { date_start, date_end } = dateRange

  let countryData = [
    { value: 'agency', label: 'Agency' },
    { value: 'credit', label: 'Credit' },
    { value: 'omni', label: 'Omni' },
    { value: 'invoices', label: 'Invoices' }
  ];

  return (
    <React.Fragment>
      <div className="content">
        <div className="page-inner">
          <div className="page-header">
            <h4 className="page-title">Form Report</h4>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Report</div>
                </div>
                <ValidatorForm
                  ref="form"
                  method="post"
                  onSubmit={(e) => handleSubmit(e)}
                >
                  <div className="card-body">
                    <div className="form-group form-show-validation row">
                      <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">Type <span className="required-label">*</span></label>
                      <div className="col-lg-4 col-md-9 col-sm-8">
                        <SelectField
                          onChange={(values) => selectHandle('location', values)}
                          name="location"
                          options={countryData}
                          value={preValue(formData?.location, location)}
                          validators={['required']}
                          errorMessages={['This field is required']}
                          className="form-control"

                        />
                      </div>
                    </div>
                    <div className="form-group form-show-validation row">
                      <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">From <span className="required-label">*</span></label>
                      <div className="col-lg-4 col-md-9 col-sm-8">
                        <DateTimeField
                          name='date_start'
                          onChange={on.date('date_start')}
                          selected={date_start}
                          value={preValue(formData?.date_start, date_start)}
                          validators={['required']}
                          errorMessages={['Required']}
                          dateFormat='do MMMM, yyyy'
                          dropdownMode='select'
                          maxDate={date_end}
                        />
                      </div>
                    </div>
                    <div className="form-group form-show-validation row">
                      <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">To <span className="required-label">*</span></label>
                      <div className="col-lg-4 col-md-9 col-sm-8">
                        <DateTimeField
                          name='date_end'
                          onChange={on.date('date_end')}
                          selected={date_end}
                          value={date_end}
                          validators={['required']}
                          errorMessages={['Required']}
                          dateFormat='do MMMM, yyyy'
                          dropdownMode='select'
                          minDate={date_start}
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-action">
                    <div className="row">
                      <div className="col-md-12">
                        <input className="btn btn-success me-1" type="submit" value="Submit" />
                        <button className="btn btn-danger" onClick={() => navigate('/report')}>Cancel</button>
                      </div>
                    </div>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

  // error: state.transactions.error,
  // invoice: state.invoices.invoice,
  // lineitems: state.lineitems.revenue_line_items || []

export default ReportForm
