import React from 'react'
import { Provider } from 'react-redux'
import { store } from './state/store'

import Main from './router/index'

import './assets/css/atlantis.min.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import './assets/css/demo.css'
import './assets/css/responsive.css'
import './assets/css/sweetalert.css'
import './assets/css/form.css'

const provider = () => (
  <Provider store={store}>
  	<Main />
  </Provider>
)

export default provider;
