

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { saveLineItem, updateLineItem, getLineItem } from '../../state/lineitems/lineItemSlice';
import { preValue } from '../../helpers/tools'
import InputField from '../../shared/form-fields/input'
import SelectField from '../../shared/form-fields/select'
import { ValidatorForm } from 'react-form-validator-core';
import _ from 'lodash'
import { useLocation, useNavigate } from 'react-router';
import { errorLog } from '../../state/root/rootSlice';

export const ROUTE_PATHS = ['/line-items/new', '/line-items/edit']

let invobj = {}
const LineItemForm = () => {
  const location = useLocation();
  const [formData, setFormData] = useState({});
  // const [descriptions, setDescriptions] = useState([]);
  // const [lookups, setLookups] = useState([]);
  // const [farmerList, setFarmerList] = useState({});
  let descriptions = [];
  let lookups = [];
  let farmerList = {};
  const listId = location.search.replace('?id=', '')

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    listId && dispatch(getLineItem(listId));
  }, [dispatch, listId])


  const handleChange = (event) => {
    invobj[event.target.name] = event.target.value;
    setFormData(invobj);
  }

  const selectHandle = (name, data) => {
    if (!_.isEmpty(data)) {
      invobj[name] = data[0].value;
      setFormData(invobj);
    }
  }

  // componentDidUpdate(nextProps) {
  //   if (!_.isEmpty(nextProps.farmer_list)) {
  //     this.setState({ farmer_list: nextProps.farmer_list })
  //   }
  //   if (!_.isEmpty(nextProps.descriptions)) {
  //     this.setState({ descriptions: nextProps.descriptions })
  //   }

  //   if (!_.isEmpty(nextProps.lookups)) {

  //     this.setState({ lookups: nextProps.lookups })
  //   }
  // }



  const handleSubmit = (event) => {
    event.preventDefault()
    if (_.isEmpty(farmerList))
      dispatch(saveLineItem({
        ...formData,
        farmer_user_id: JSON.parse(sessionStorage.fuser).id
      })).unwrap()
      .then(() => {
        navigate('/payments')
      })
      .catch((err) => {
        dispatch(errorLog(err))
      })
    else
      dispatch(updateLineItem({ id: listId, data: {
        ...formData,
        farmer_user_id: JSON.parse(sessionStorage.fuser).id
      }})).unwrap()
      .then(() => {
        navigate('/line_items')
      })
      .catch((err) => {
        dispatch(errorLog(err))
      })
  }

  // const validateForm = () => {}

  let { price_unit, price, farmer_description_id, farming_lookup_list_id } =  {...farmerList}
  const options = descriptions?.map((item) => ({ value: item.id, label: item.island }))
  const options2 = lookups?.map((item) => ({ value: item.id, label: item.id }))

  return (
    <React.Fragment>
      <div className="content">
        <div className="page-inner">
          <div className="page-header">
            <h4 className="page-title">Form Inventory</h4>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Inventory</div>
                </div>
                <ValidatorForm
                  ref="form"
                  method="post"
                  onSubmit={(e) => handleSubmit(e)}
                >

                  <div className="card-body">
                    <div className="form-group form-show-validation row">
                      <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">Farmer Description <span className="required-label">*</span></label>
                      <div className="col-lg-4 col-md-9 col-sm-8">
                        <SelectField
                          onChange={(values) => selectHandle('farmer_description_id', values)}
                          name="farming_lookup_list_id"
                          options={options}
                          value={preValue(formData?.farmer_description_id, farmer_description_id)}
                          validators={['required']}
                          errorMessages={['This field is required']}
                          className="form-control"

                        />
                      </div>
                    </div>


                    <div className="form-group form-show-validation row">
                      <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">Farming Lookup <span className="required-label">*</span></label>
                      <div className="col-lg-4 col-md-9 col-sm-8">
                        <SelectField
                          onChange={(values) => selectHandle('farming_lookup_list_id', values)}
                          name="farming_lookup_list_id"
                          options={options2}
                          value={preValue(formData?.farming_lookup_list_id, farming_lookup_list_id)}
                          validators={['required']}
                          errorMessages={['This field is required']}
                          className="form-control"

                        />
                      </div>
                    </div>


                    <div className="form-group form-show-validation row">
                      <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">Price <span className="required-label">*</span></label>
                      <div className="col-lg-4 col-md-9 col-sm-8">
                        <InputField
                          onChange={handleChange}
                          name="price"
                          value={preValue(formData?.price, price)}
                          validators={['required', "isNumber"]}
                          errorMessages={['This field is required', "This field should be numeric"]}
                          className="form-control"
                          placeholder="price"
                        />
                      </div>
                    </div>

                    <div className="form-group form-show-validation row">
                      <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">Price Unit <span className="required-label">*</span></label>
                      <div className="col-lg-4 col-md-9 col-sm-8">
                        <InputField
                          onChange={handleChange}
                          name="price_unit"
                          value={preValue(formData?.price_unit, price_unit)}
                          validators={['required', "isNumber"]}
                          errorMessages={['This field is required', "This field should be numeric"]}
                          className="form-control"
                          placeholder="price unit"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-action">
                    <div className="row">
                      <div className="col-md-12">
                        <input className="btn btn-success me-1" type="submit" value="Submit" />
                        <button className="btn btn-danger" onClick={() => navigate('/inventory')}>Cancel</button>
                      </div>
                    </div>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>

  );
}

export default LineItemForm;
