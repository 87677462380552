import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios";
import moment from 'moment'

const format = (d) => {
  return moment(d).format("YYYY-MM-DD");
}

const headers = () => {
  let user = sessionStorage.getItem("user");
  user = JSON.parse(user);
  return {
    "Content-Type": "application/json",
    "Authorization": user && user.auth_token
  };
}

const initialState = {
  authenticated: false,
  loading: false,
  success: false,
  success_message: null,
  error: null,
  data: null, //[],
  transactions: null, //[],
  reports: null, //[],
}

const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    errorLog: (state, action) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = undefined;
    },
    stopLoader: (state) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
    // Get Reports
      .addCase(getReports.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReports.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.transactions = action.payload.data.transactions;
        // console.log(action);
      })
      .addCase(getReports.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.transactions = [];
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Save Report
      .addCase(saveReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveReport.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'The report is building. Please check your email for updates.';
        state.reports = action.payload.data;
        // console.log(action);
      })
      .addCase(saveReport.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.company = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Void Report
      .addCase(voidReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(voidReport.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Transaction successfully voided';
        // console.log(action);
      })
      .addCase(voidReport.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        // console.log('error', action);
        state.error = action.payload.error;
      })
  }
})

export const { clearError, stopLoader, errorLog } = reportSlice.actions
export default reportSlice.reducer;

//   case actionTypes.VOID_PARTICULAR_TRANSACTION:
//     return {
//       ...state,
//       loading: false,
//       success_message: "Transaction successfully voided",
//     }
 
export const getReports = createAsyncThunk(
  'reports/getReports',
  async (_t, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/reports`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const saveReport = createAsyncThunk(
  'reports/saveReport',
  async ({data, start, end}, { rejectWithValue }) => {
    try {
      const startDate = `start_date=${format(start)}`
      const endDate = `&end_date=${format(end)}`
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/reports/${data.location}?${startDate}${endDate}`, data, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const voidReport = createAsyncThunk(
  'reports/voidReport',
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.delete(`${process.env.REACT_APP_API_HOST}/api/v1/transactions/voidd/${id}`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export function downloadReports (fileurl) {
  fetch(process.env.REACT_APP_API_HOST + fileurl, {
    method: 'GET',
    headers: headers(),
  })
  .then((response) => response.blob())
  .then((blob) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${fileurl.split('?').slice(-1)[0]}.csv`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  });
}