import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import EmptyTable from '../../shared/empty-table'
import Loader from '../../shared/loader'
import { getReports } from '../../state/reports/reportSlice';
// import { voidTransaction } from '../../state/transactions/transactionSlice';
import appConstants from '../../constants/constants'
import ReportTable from './_table'
import PageIndex from '../../shared/pageIndex';

const Report = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [maxPages, setMaxPages] = useState(1);
  const [dataIndexed, setDataIndexed] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);

	const dispatch = useDispatch();
  const data = useSelector(state => state.transactions.transactions);
  const loading = useSelector(state => state.root.loading);

  useEffect(() => {
    dispatch(getReports())
  }, [dispatch])

  useEffect(() => {
    const startingIndex = (page - 1) * perPage;
    const endingIndex = startingIndex + perPage;
    dataFiltered.length ?
    setDataIndexed(dataFiltered?.map((x, i) => {
      if(i < endingIndex && i >= startingIndex){ return x }
      else return null
    }))
    :
    setDataIndexed(data?.map((x, i) => {
      if(i < endingIndex && i >= startingIndex){ return x }
      else return null
    }))
  }, [perPage, page, data, dataFiltered])
  // console.log('dataIndexed', dataIndexed);

  const table = !loading && dataIndexed && dataIndexed.length ? (
    <ReportTable
      data={dataIndexed}
    />
  ) : <EmptyTable columns={appConstants.REPORT_COLUMNS} />

  return (
    <React.Fragment>
      <Loader loading={loading}/>

      <div className="content">
        <div className="page-inner">
          <div className="page-header"></div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="card-title">Reports</h4>
                      <a className="btn btn-primary btn-round ms-auto" href="/report/generate" >
                        <i className="fa fa-plus"></i>
                        Generate Report
                      </a>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    {table}
                  </div>
                </div>
                <div className='card-footer'>
                  <PageIndex data={data} dataFiltered={dataFiltered} setDataFiltered={setDataFiltered} page={page} setPage={setPage} maxPages={maxPages} setMaxPages={setMaxPages} perPage={perPage} setPerPage={setPerPage}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

// const mapStateToProps = ({ transactions, reports }) => ({
//   data: transactions.transactions,
//   error: transactions.error,
//   success: transactions.success,
//   loading: transactions.loading,
//   success_message: transactions.success_message || reports.success_message,
// })

export default Report
