import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios";

const headers = () => {
  let user = sessionStorage.getItem("user");
  user = JSON.parse(user);
  return {
    "Content-Type": "application/json",
    "Authorization": user && user.auth_token
  };
}

const initialState = {
  authenticated: false,
  loading: false,
  success: false,
  success_message: undefined,
  error: undefined,
  data: [],
  search_target: ''
}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    errorLog: (state, action) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = undefined;
    },
    stopLoader: (state) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
    // Get Data
      .addCase(getData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.search_target = action.payload.input.search_target;
        state.data = action.payload.res;
        // console.log(action);
      })
      .addCase(getData.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.data = [];
        state.search_target = '';
        // console.log('error', action);
        state.error = action.payload.error;
      })
  }
})

export const { clearError, stopLoader, errorLog } = searchSlice.actions
export default searchSlice.reducer;

// case actionTypes.GET_SEARCH_DATA:
//   return {
//     ...state,
//     loading: false,
//     error: null,
//     search_target: payload.search_target,
//     data: payload.data,
//   };
// case actionTypes.GET_RESPONSE_ERROR:
//   return {
//     ...state,
//     loading: false,
//     error: null,
//     search_target: "",
//     data: [],
//   };

export const getData = createAsyncThunk(
  'search/getData',
  async (input, { rejectWithValue }) => {
    try {
      let searchTarget = '';
      let queryType = '';
      if (input.search_target === "transaction") {
        searchTarget = 'transactions'
      } else {
        searchTarget = 'invoices'
      }
    
      if (input.query_type === "last_name") {
        queryType = 'by_last_name'
      } else {
        queryType = 'by_invoice_number_scoped'
      }
      const query = encodeURIComponent(input.query);

      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/${searchTarget}/${queryType}/${query}`, { headers: headers() })
      // console.log(res)
      if (input.search_target == "transaction") {
        if (input.query_type == "last_name") {
          // data = mapTransactions(response.data.transactions);
          return { res: res.data.transactions, input }
        } else {
          return { res: res.data.transactions, input }
        }
      } else {
        return { res: res.data.invoices, input }
      }
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)
