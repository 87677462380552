import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios";

const headers = () => {
  let user = sessionStorage.getItem("user");
  user = JSON.parse(user);
  return {
    "Content-Type": "application/json",
    "Authorization": user && user.auth_token
  };
}

const initialState = {
  authenticated: false,
  loading: false,
  success: false,
  success_message: undefined,
  error: undefined,
  data: null, //[],
  head: null,
  heads: null, //[],
}

const headSlice = createSlice({
  name: 'heads',
  initialState,
  reducers: {
    errorLog: (state, action) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = undefined;
    },
    stopLoader: (state) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
    // Get Heads
      .addCase(getHeads.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHeads.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.heads = action.payload.data.heads;
        // console.log(action);
      })
      .addCase(getHeads.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.heads = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Get Head
      .addCase(getHead.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHead.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.head = action.payload.data.head;
        // console.log(action);
      })
      .addCase(getHead.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.head = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Save Head
      .addCase(saveHead.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveHead.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully created header.';
        state.head = action.payload.data;
        // console.log(action);
      })
      .addCase(saveHead.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.head = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Update Head
      .addCase(updateHead.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateHead.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully updated header.';
        state.head = action.payload.data;
        // console.log(action);
      })
      .addCase(updateHead.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.head = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
  }
})

export const { clearError, stopLoader, errorLog } = headSlice.actions
export default headSlice.reducer;
 
export const getHeads = createAsyncThunk(
  'heads/getHeads',
  async (_t, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/heads`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const getHead = createAsyncThunk(
  'heads/getHead',
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/heads/${id}`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const saveHead = createAsyncThunk(
  'heads/saveHead',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/heads`, data, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const updateHead = createAsyncThunk(
  'heads/updateHead',
  async ({id, data}, { rejectWithValue }) => {
    try {
      const res = await axios.patch(`${process.env.REACT_APP_API_HOST}/api/v1/heads/${id}`, { head: data }, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)
