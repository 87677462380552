const normalizePhone = phone => {
  var clean = String(phone).replace(/\D/g, '')
  var match = clean.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

  return match
    ? `${match[1] ? '+1 ' : ''}(${match[2]}) ${match[3]}-${match[4]}`
    : phone
}

export default normalizePhone;
