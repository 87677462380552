
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { resetPassword } from '../state/users/userSlice'
import { errorLog } from '../state/root/rootSlice'
import LandingPage from '../wrappers/landing-page'
import { useNavigate, useParams } from 'react-router'

export const ROUTE_OPEN = true
export const ROUTE_PATHS = ['/reset-password/:token']

const ResetPassword = () => {
  // const [open, toggleOpen] = useState(false);
  const [form, setForm] = useState({ password: '', password_confirmation: '' });
  const passwordRef = useRef(null);
  const passwordConfirmRef = useRef(null);
  const currentUser = useSelector((state) => state.users.currentUser);
  let { token } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(currentUser);
    currentUser?.roles && navigate('/dashboard')
  }, [currentUser, navigate])

  const handleSubmit = (ev) => {
    ev.preventDefault()

    const { password, password_confirmation } = form

    if (password && password_confirmation) {
      dispatch(resetPassword({
        ...form,
        reset_password_token: token
      })).unwrap()
      .then(() => {
        navigate('/login')
      })
      .catch((err) => {
        dispatch(errorLog(err))
      })
    } else dispatch(errorLog('Password did not match'))
  }

  const togglePasswordVisibility = (passtype = 'pass') => {
    const input = passtype === 'pass' ? passwordRef.current : passwordConfirmRef.current

    if (input) {
      const attr = input.getAttribute('type') == 'password' ? 'text' : 'password'
      input.setAttribute('type', attr)
    }
  }

  return (
    <LandingPage
      title='Open for Business 24x7'
      subtitle="The subway system of The Bahamas' financial services."
      // success_message={props.success_message}
      // error={state.error || props.error}
    >
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="password" className="placeholder bg-white"><b>Password</b></label>
          <div className="position-relative">
            <input
              ref={passwordRef}
              id="password"
              name="password"
              type="password"
              onChange={ev => setForm({ ...form, [ev.target.name]: ev.target.value })}
              value={form.password}
              className="form-control"
              required
            />
            <div className="show-password">
              <i className="fas fa-eye" onClick={() => togglePasswordVisibility()}></i>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="password" className="placeholder bg-white"><b>Confirm Password</b></label>
          <div className="position-relative">
            <input
              ref={passwordConfirmRef}
              id="password_confirmation"
              name="password_confirmation"
              type="password"
              onChange={ev => setForm({ ...form, [ev.target.name]: ev.target.value })}
              value={form.password_confirmation}
              className="form-control"
              required
            />
            <div className="show-password">
              <i className="fas fa-eye" onClick={() => togglePasswordVisibility('confirm')}></i>
            </div>
          </div>
        </div>
        <div className="form-group form-action-d-flex mb-3">
          <input type="submit" className="btn btn-secondary col-md-5 float-end mt-3 mt-sm-0 fw-bold" value="Update" />
        </div>
      </form>
    </LandingPage>
  )
}

export default ResetPassword
