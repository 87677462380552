

import React, { useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { ValidatorForm } from 'react-form-validator-core'

import { saveCashPayment } from '../state/cash/cashSlice'
import Loader from '../shared/loader'
import formatPhoneNumber from '../helpers/normalize-phone'
import InputField from '../shared/form-fields/input'

import appConstants from '../constants/constants'
import { useNavigate } from 'react-router'
import { errorLog } from '../state/root/rootSlice'

const Field = ({ name, label, required, children }) => (
  <div className='form-group form-show-validation row'>
    <label
      htmlFor={name}
      className='col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end'
    >
      {label}
      {required !== false ? <span className="required-label">*</span> : null}
    </label>
    <div className='col-lg-4 col-md-9 col-sm-8'>
      {children}
    </div>
  </div>
)


const Cheque = () => {
  const [form, setForm] = useState({ invoice_number: '', first_name: '', last_name: '', email: '' });
  const [amount, setAmount] = useState('');
  const [invoice, setInvoice] = useState({ long_desc: '' });
  const nonce = 0;
  const loading = useSelector(state => state.root.loading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const user = sessionStorage.user && JSON.parse(sessionStorage.user)
  const BASE_URL = process.env.REACT_APP_API_HOST + '/' + appConstants.API_V

  const fetch = {
    token: async () => {
      try {
        const { data } = await axios.get(BASE_URL + '/fetch_guest_token')
        return data.token
      } catch (err) {
        dispatch(errorLog(err.response.data.error));
      }
    },

    invoice: async ({ target }) => {
      const Authorization = await fetch.token()

      try {
        const { data } = await axios.get(
          `${BASE_URL}/revenue_line_items/by_invoice_number/${target.value}`,
          { headers: { Authorization } }
        )

        const invoice = data?.revenue_line_item
        if(invoice){
          setInvoice(invoice)
          setAmount(data.invoice_amount.toFixed(2))
        }
      } catch (err) {
        dispatch(errorLog(err.response.data.error));
      }
    },
  }

  const on = {
    change: ({ target }) => setForm({
      ...form,
      [target.name]: target.value,
    }),

    submit: ev => {
      ev.preventDefault()
      dispatch(saveCashPayment({
        ...form,
        mobile_number: formatPhoneNumber(form?.mobile_number),
        transaction_mode: 'agency',
        third_party_type: 'cheque'
      })).unwrap()
      .then(() => {
        navigate('/transactions')
      })
      .catch((err) => {
        dispatch(errorLog(err))
      })
    }
  }

  const useDefaultEmail = () => {
    setForm({...form, email: 'digipayusers@bahamas.gov.bs'});
  }

  return (
    <React.Fragment >
      <Loader loading={loading} />
      <div className="content" key={nonce}>
        <div className="page-inner">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Cheque</div>
                </div>
                <ValidatorForm method="post" onSubmit={on.submit} >
                  <div className="card-body">
                    <div>Invoice Details</div>
                    <hr />

                    <Field name='invoice_number' label='Agency Invoice Number'>
                      <InputField
                        onChange={on.change}
                        onBlur={fetch.invoice}
                        name="invoice_number"
                        value={form?.invoice_number || ''}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        className="form-control"
                        placeholder="Invoice Number"
                      />
                    </Field>

                    <Field name='revenue_line_item' label='Revenue Line Item'>
                      <InputField readOnly value={invoice?.long_desc} className="form-control" />
                    </Field>

                    <Field name='card_amount' label='Amount'>
                      <InputField readOnly value={amount} className="form-control" />
                    </Field>

                    <Field name='third_party_reference' label='Cheque Number'>
                      <InputField
                        onChange={on.change}
                        name="third_party_reference"
                        value={form?.third_party_reference || ''}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        className="form-control"
                        placeholder="Cheque Number"
                      />
                    </Field>

                    <div>Customer Info</div>
                    <hr />

                    <Field name='first_name' label='First Name'>
                      <InputField
                        onChange={on.change}
                        name="first_name"
                        value={form?.first_name || ''}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        className="form-control"
                        placeholder="First Name"
                      />
                    </Field>

                    <Field name='last_name' label='Last Name'>
                      <InputField
                        onChange={on.change}
                        name="last_name"
                        value={form?.last_name || ''}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        className="form-control"
                        placeholder="Last Name"
                      />
                    </Field>

                    <Field name='email' label='Email'>
                      <InputField
                        onChange={on.change}
                        name="email"
                        value={form?.email || ''}
                        validators={['required', 'isEmail']}
                        errorMessages={['This field is required', 'Must be a valid email address']}
                        className="form-control"
                        placeholder="Email"
                      />
                      <a role="button" onClick={useDefaultEmail}>Default Email</a>
                    </Field>

                    <Field name='sms' required={false} label='Mobile Number'>
                      <InputField
                        onChange={on.change}
                        name="mobile_number"
                        value={form?.mobile_number || ''}
                        className="form-control"
                        placeholder="Mobile Number"
                        type="number"
                      />
                      <p>All phone numbers must start with 1242</p>
                    </Field>

                    <Field name='sms' required={false} label='Additional Notes'>
                      <textarea
                        onChange={on.change}
                        name="notes"
                        maxLength="250"
                        value={form?.notes || ''}
                        className="form-control"
                        placeholder="Additional Notes"
                        type="text"
                      />
                    </Field>
                  </div>

                  <div className="card-action">
                    <div className="row">
                      <div className='col-lg-7 text-end'>
                        <input
                          style={{backgroundColor: '#00665d'}}
                          className={`btn me-2 text-white`}
                          type='submit'
                          value='Submit'
                          disabled={loading}
                        />
                      </div>
                    </div>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

// state => extractKeys(state.cash, 'loading'),
// { saveCashPayment }

export default Cheque
