import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios";
import moment from "moment";

const format = (d) => {
  return moment(d).format("YYYY-MM-DD");
}

const headers = () => {
  let user = sessionStorage.getItem("user");
  user = JSON.parse(user);
  return {
    "Content-Type": "application/json",
    "Authorization": user && user.auth_token
  };
}

const initialState = {
  authenticated: false,
  loading: false,
  success: false,
  success_message: null,
  error: null,
  data: [],
  invoices: [],
  invoice: null, //{},
  places: [],
}

const InvoiceSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    errorLog: (state, action) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = undefined;
    },
    stopLoader: (state) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
    // Get Invoices
      .addCase(getInvoices.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.invoices = action.payload.data.invoices;
        // console.log(action);
      })
      .addCase(getInvoices.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.invoices = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Get Invoice
      .addCase(getInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        // console.log(action);
        state.invoice = action.payload.data.places.invoice;
      })
      .addCase(getInvoice.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.invoice = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Get Invoice
      .addCase(getLocations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.places = action.payload.data.places;
        // console.log(action);
      })
      .addCase(getLocations.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.places = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Save Invoice
      .addCase(saveInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully created invoice';
        state.invoice = action.payload.data;
        // console.log(action);
      })
      .addCase(saveInvoice.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.invoice = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Update Invoice
      .addCase(updateInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully updated invoice';
        state.invoice = action.payload.data;
        // console.log(action);
      })
      .addCase(updateInvoice.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.invoice = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Remove Invoice
      .addCase(removeInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully removed invoice';
        // console.log(action);
        state.invoices = action.payload.data.filter((item) => item.id != action.payload.data.id);
      })
      .addCase(removeInvoice.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.invoices = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
  }
})

export const { clearError, stopLoader, errorLog } = InvoiceSlice.actions
export default InvoiceSlice.reducer;

export const getInvoices = createAsyncThunk(
  'invoices/getInvoices',
  async ({start, end}, { rejectWithValue }) => {
    // console.log('GET INVOICES');
    try {
      const startDate = `start_date=${format(start)}`;
      const endDate = `&end_date=${format(end)}`;
      // console.log(`${process.env.REACT_APP_API_HOST}/api/v1/invoices?${startDate}${endDate}`);
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/invoices?${startDate}${endDate}`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const getInvoice = createAsyncThunk(
  'invoices/getInvoice',
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/invoices/${id}`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const getLocations = createAsyncThunk(
  'invoices/getLocations',
  async (_t, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/locations`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const saveInvoice = createAsyncThunk(
  'invoices/saveInvoice',
  async (invoice, { rejectWithValue }) => {
    try {
      // Trim First & Last Name
      invoice.first_name = invoice.first_name?.trim()
      invoice.last_name = invoice.last_name?.trim()

      const res = await axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/invoices`, { invoice }, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const updateInvoice = createAsyncThunk(
  'invoices/updateInvoice',
  async ({id, data}, { rejectWithValue }) => {
    try {
      const res = await axios.patch(`${process.env.REACT_APP_API_HOST}/api/v1/invoices/${id}`,
        { invoice: data }, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)


export const removeInvoice = createAsyncThunk(
  'invoices/removeInvoice',
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.delete(`${process.env.REACT_APP_API_HOST}/api/v1/invoices/${id}`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export function downloadInvoices(fileurl) {
  fetch(process.env.REACT_APP_API_HOST + fileurl, {
    method: "GET",
    headers: headers(),
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileurl.split("?").slice(-1)[0]}.pdf`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
}