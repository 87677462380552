import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios";

const headers = () => {
  let user = sessionStorage.getItem("user");
  user = JSON.parse(user);
  return {
    "Content-Type": "application/json",
    "Authorization": user && user.auth_token
  };
}

const initialState = {
  authenticated: false,
  loading: false,
  success: false,
  success_message: undefined,
  error: undefined,
  data: null, //[],
  head: null,
  line_item: null,
  line_items: null, //[],
  revenue_line_items: null, //[]
}

const lineItemSlice = createSlice({
  name: 'lineitems',
  initialState,
  reducers: {
    errorLog: (state, action) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = undefined;
    },
    stopLoader: (state) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
    // Get Line Items
      .addCase(getLineItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLineItems.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.line_items = action.payload.data.line_items;
        // console.log(action);
      })
      .addCase(getLineItems.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.line_items = [];
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Get Revenue Line Items
      .addCase(getRevenueLineItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRevenueLineItems.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.revenue_line_items = action.payload.data;
        // console.log(action);
      })
      .addCase(getRevenueLineItems.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.revenue_line_items = [];
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Get Line Item
      .addCase(getLineItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLineItem.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.line_item = action.payload.data;
        // console.log(action);
      })
      .addCase(getLineItem.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.line_item = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Save Line Item
      .addCase(saveLineItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveLineItem.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully created line item.';
        state.head = action.payload.data;
        // console.log(action);
      })
      .addCase(saveLineItem.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.head = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Update Line Item
      .addCase(updateLineItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateLineItem.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Successfully updated line item.';
        state.line_item = action.payload.data;
        // console.log(action);
      })
      .addCase(updateLineItem.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.line_item = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Remove Line Item
      // .addCase(removeCompany.pending, (state) => {
      //   state.loading = true;
      // })
      // .addCase(removeCompany.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.success = true;
      //   state.success_message = 'Successfully removed company.';
      //   // console.log(action);
      //   state.companies = action.payload.data.filter((item) => item.id != action.payload.data.id);
      // })
      // .addCase(removeCompany.rejected, (state, action) => {
      //   state.loading = false;
      //   state.success = false;
      //   state.companies = null;
      //   // console.log('error', action);
      //   state.error = action.payload.error;
      // })
  }
})

export const { clearError, stopLoader, errorLog } = lineItemSlice.actions
export default lineItemSlice.reducer;

// case actionTypes.GET_LINE_ITEMS: 
// return {...state,loading: false, error: undefined,line_items: action.payload.response};
// case actionTypes.GET_REVENUE_LINE_ITEMS:
// return {...state,loading: false, revenue_line_items: action.payload.response}
// case actionTypes.CREATE_LINE_ITEM:
// return {...state,loading: false, error: undefined,success_message: "successfully payment", success: true, head: action.payload.response};
 
export const getLineItems = createAsyncThunk(
  'lineitems/getLineItems',
  async (_t, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/line_items`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const getRevenueLineItems = createAsyncThunk(
  'lineitems/getRevenueLineItems',
  async (_t, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/revenue_line_items`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)


export const getLineItem = createAsyncThunk(
  'lineitems/getLineItem',
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/line_items/${id}`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const saveLineItem = createAsyncThunk(
  'lineitems/saveLineItem',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/line_items`, data, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const updateLineItem = createAsyncThunk(
  'lineitems/updateLineItem',
  async ({id, data}, { rejectWithValue }) => {
    try {
      const res = await axios.patch(`${process.env.REACT_APP_API_HOST}/api/v1/line_items/${id}`, { 'head': data }, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)
