import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios";
import SimpleEncrypt from '../../helpers/encryption'

const headers = () => {
  let user = sessionStorage.getItem("user");
  user = JSON.parse(user);
  return {
    "Content-Type": "application/json",
    "Authorization": user && user.auth_token
  };
}

const initialState = {
  authenticated: false,
  loading: false,
  success: false,
  success_message: null,
  error: null,
  data: null, //[],
  line_items: null, //[],
}

const cashSlice = createSlice({
  name: 'cash',
  initialState,
  reducers: {
    errorLog: (state, action) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = undefined;
    },
    stopLoader: (state) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
    // Get Revenue Line Items
      .addCase(getAllRevenue.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllRevenue.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.line_items = action.payload.data.revenue_line_items;
        // console.log(action);
      })
      .addCase(getAllRevenue.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.line_items = [];
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Save Cash Payment
      .addCase(saveCashPayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveCashPayment.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Payment Successful';
        state.head = action.payload.data;
        // console.log(action);
      })
      .addCase(saveCashPayment.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.head = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Save Payee
      .addCase(savePayee.pending, (state) => {
        state.loading = true;
      })
      .addCase(savePayee.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Payment Successful';
        state.head = action.payload.data;
        // console.log(action);
      })
      .addCase(savePayee.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.head = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Replay SMS
      .addCase(saveReplaySms.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveReplaySms.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Your confirmation has been sent.';
        // console.log(action);
        state.head = action.payload.data;
      })
      .addCase(saveReplaySms.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.head = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Replay Email
      .addCase(saveReplayEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveReplayEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Your confirmation has been sent.';
        // console.log(action);
        state.head = action.payload.data;
      })
      .addCase(saveReplayEmail.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.head = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
  }
})

export const { clearError, stopLoader, errorLog } = cashSlice.actions
export default cashSlice.reducer;

// case actionTypes.CREATE_PAYEE:
//   return {
//     ...state,
//     loading: false,
//     success_message: "Payee Creation Successful",
//     success: true,
//     payee: payload.response
//   }
 
export const getAllRevenue = createAsyncThunk(
  'cash/getAllRevenue',
  async (_t, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/revenue_line_items`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const saveCashPayment = createAsyncThunk(
  'cash/saveCashPayment',
  async (data, { rejectWithValue }) => {
    try {
      const invoice_number = data.invoice_number.trim()
      delete data.invoice_number
  
      // Trim First & Last Name
      data.first_name = data.first_name?.trim()
      data.last_name = data.last_name?.trim()

      const res = await axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/transactions/by_reference/${invoice_number}`, data, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const savePayee = createAsyncThunk(
  'cash/savePayee',
  async (data, { rejectWithValue }) => {
    try {
      data["card-number"] = SimpleEncrypt.encrypt(data["card-number"])
      data["card-cvv"] = SimpleEncrypt.encrypt(data["card-cvv"])
      data["card-name"] = SimpleEncrypt.encrypt(data["card-name"])
      data["card-exp"] = SimpleEncrypt.encrypt(data["card-exp"])
      data["card-amount"] = SimpleEncrypt.encrypt(data["card-amount"])
      const res = await axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/transactions`, data, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const saveReplaySms = createAsyncThunk(
  'cash/saveReplaySms',
  async (data, { rejectWithValue }) => {
    try {
      delete data.invoice_number

      const res = await axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/replay/sms`, data, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const saveReplayEmail = createAsyncThunk(
  'cash/saveReplayEmail',
  async (data, { rejectWithValue }) => {
    try {
      delete data.invoice_number

      const res = await axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/replay/email`, data, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)
