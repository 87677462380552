import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ValidatorForm } from 'react-form-validator-core'

import { saveReplayEmail } from '../state/cash/cashSlice'
import Loader from '../shared/loader'
import Field from '../shared/form-fields'
import { errorLog } from '../state/root/rootSlice'

// const freshForm = () => ({
//   invoice_reference: '',
//   mobile_number: '',
//   type: '',
// })

const ReplayEmail = () => {
  const [form, setForm] = useState({ invoice_reference: '', mobile_number: '', type: '' });

  const loading = useSelector(state => state.root.loading);
  // const user = useSelector(state => state.users.currentUser);
  // const user_id = user?.id;
  const dispatch = useDispatch();

  const on = {
    change: ({ target }) => {
      setForm({ ...form, [target.name]: target.value })
    },

    select: name => ([selected]) => {
      selected && setForm({ ...form, [name]: selected.value })
    },

    submit: ev => {
      ev.preventDefault()

      dispatch(saveReplayEmail(form)).unwrap()
      .then(() => {
        setForm({})
      })
      .catch((err) => {
        dispatch(errorLog(err))
      })
    },
  }


  const options = ['Invoice', 'Receipt'].map(label => ({
    label,
    value: label.toLowerCase()
  }))

  return (
    <React.Fragment>
      <Loader loading={loading} />
      <div className='content'>
        <div className='page-inner'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='card'>
                <div className='card-header'>
                  <div className='card-title'>Replay SMS</div>
                </div>
                <ValidatorForm onSubmit={on.submit}>
                  <div className='card-body'>
                    <Field
                      type='text'
                      onChange={on.change}
                      name='invoice_reference'
                      label='Invoice Number'
                      value={form?.invoice_reference || ''}
                    />

                    <Field
                      type='text'
                      onChange={on.change}
                      name='email'
                      value={form?.email || ''}
                      validators={['required', 'isEmail']}
                          errorMessages={['This field is required', 'Must be a valid email address']}
                    />

                    <Field
                      onChange={on.select('type')}
                      name='type'
                      options={options}
                      value={form?.type || ''}
                    />
                  </div>

                  <div className='card-action'>
                    <div className='row'>
                      <div className='col-lg-7 text-end'>
                        <input
                          style={{backgroundColor: '#00665d'}}
                          className={`btn me-2 text-white`}
                          type='submit'
                          value='Submit'
                        />
                      </div>
                    </div>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

// state => extractKeys(state.cash, 'loading', 'error', 'success_message'),

export default ReplayEmail
