import React from "react";
import Swal from 'sweetalert2'
import CsvDownloader from "use-csv-downloader";
import appConstants from "../../constants/constants";
import { dateTimeOnly } from "../../helpers/tools";
import { voidTransaction, downloadTransactions } from "../../state/transactions/transactionSlice";

import "react-confirm-alert/src/react-confirm-alert.css";
import "../../assets/css/pagination.css";
import { useDispatch } from "react-redux";

const downloadCSV = CsvDownloader({});

const TransactionTable = ({ data, showTransaction, sortedBy, setSortedBy, sortByLatest, setSortByLatest }) => {
  const user = sessionStorage.user && JSON.parse(sessionStorage.user);
  const userRoles = user?.roles?.map(r => r.name);

  const dispatch = useDispatch();


  const downloadData = (url) => {
    downloadTransactions(url);
  }

  const confirmVoid = (e, item) => {
    e.preventDefault();
    Swal.fire({
      title: "Confirm",
      text: "Are you sure you'd like to void this transaction?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Void",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        dispatch(voidTransaction(item.id));
      }
    });
  }

  const actionItems = item => (
    <div>
      <a
        onClick={() => showTransaction(item)}
        data-action
        data-toggle="modal"
        data-target="#TransactionModal"
      >
        <i className="fa fa-eye"></i>
      </a>
      {
        !item.void && ['revenue', 'agency_accountant'].some(r => userRoles.includes(r)) && !userRoles.includes("read") ? (
          <React.Fragment>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a data-action onClick={(e) => confirmVoid(e, item)}>
              <i className="fa fa-ban"></i>
            </a>
          </React.Fragment>
        ) : null
      }
    </div>
  )

  const convertDate = date => new Date(date).toLocaleString()

  const generateCSV = () => {
    let { TRANSACTIONS_HEADERS } = appConstants;

    const csv = (data || data.reverse())
      .map((row) => ({
        ...row,
        created_at: dateTimeOnly(row.created_at),
      }))
      .map((row) =>
        TRANSACTIONS_HEADERS.reduce(
          (o, { label, key }) => ({
            ...o,
            [label]: key == "void" ? (row[key] ? "YES" : "NO") : row[key],
          }),
          {}
        )
      );
    // return console.log(csv);

    downloadCSV(csv, `transactions-${Date.now()}.csv`);
  };

  const checkmark = val => {
    const classes = !!val
      ? 'fas fa-check text-green-600'
      : 'fas fa-times text-red-600'

    return <div className='text-center'><i className={classes} /></div>
  }

  const tableRows = data => data.map((t) => {
    const isPaid = () => {
      if (t.third_party_type == 'credit') {
        return (t.m_status == 'success' ? convertDate(t.created_at) : 'N/A')
      } else if (['cash', 'cheque'].includes(t.third_party_type)) {
        return convertDate(t.created_at)
      } else {
        return (t.m_status == 'problem' ? 'N/A' : convertDate(t.created_at))
      }
    }

    return t && (
    <tr key={t.id}>
      <td>{t.invoice_reference}</td>
      <td>{t.invoice_reference}</td>
      <td>{t.m_status ? t.m_status : 'success'}</td>
      <td style={{minWidth: '300px'}}>{t.auth_dmsg}</td>
      <td>$ {t.amount_charged}</td>
      <td>{t.line_item_description}</td>
      <td>{t.invoice_email}</td>
      <td>{t.transaction_first_name}</td>
      <td>{t.transaction_last_name}</td>
      <td>{t.cashier}</td>
      <td>{t.invoice_location}</td>
      <td>{isPaid()}</td>
      <td>{convertDate(t.created_at)}</td>
      <td>{t.transaction_mode}</td>
      <td>{t.third_party_type}</td>
      <td>{t.third_party_reference}</td>
      <td>{t.void && checkmark(t.void)}</td>
      <td>{t.mobile_number}</td>
      <td>{t.notes}</td>
      <td>
        <button
          type="button"
          onClick={(e) => {
            downloadData(t.receipt);
          }}
          className="btn btn-link"
          data-original-title="Remove"
          data-action
        >
          <i className="fa fa-download"></i>
        </button>
      </td>
      <td>{actionItems(t)}</td>
    </tr>)
  })

  // Handle Sorting Functions
  const handleSort = (sortValue) => {
    setSortedBy(sortValue);

    // Reset Sort Direction to latest once a new column is selected
    if (sortedBy != sortValue) {
      setSortByLatest(true);
    } else {
      setSortByLatest(!sortByLatest);
    }
  }

  const floatStyle = {textAlign: 'start', backgroundColor: "transparent", border: '0', fontWeight: '600'}
  const exceptionColumn = ['download', 'notes', 'mobile_number']

  const showSortButton = (h) => {
    if (exceptionColumn.includes(h.data)) {
      return h.title
    } else {
      return <button
        style={floatStyle}
        data-value={h.data}
        onClick={(ev) => handleSort(ev.target.getAttribute('data-value')) }>
        {h.title}
        {sortedBy == h.data ? '↓' : '↑'}
      </button>
    }
  }

  const headings = appConstants.TRANSACTIONS_COLUMNS.map((h, i) => (
    <th key={`head-${i}`} className="th-custom" style={{position: 'relative'}}>
      {showSortButton(h)}
    </th>
  ));

  return (
    <React.Fragment>
      <button
        className="btn btn-sm btn-primary"
        onClick={generateCSV}
        style={{ marginBottom: "1.5rem" }}
      >
        <i className='fas fa-download' />
        &nbsp;&nbsp;&nbsp;&nbsp;Download CSV
      </button>

      <table
        id="basic-datatables"
        className="display table table-striped table-hover"
      >
        <thead>
          <tr>{headings}</tr>
        </thead>
        <tfoot>
          <tr>{headings}</tr>
        </tfoot>
        <tbody>{tableRows(data)}</tbody>
      </table>
    </React.Fragment>
  );
}

export default TransactionTable;
