import React from 'react';
// import { Link } from 'react-router-dom'
import appConstants from '../../constants/constants'
import '../../assets/css/pagination.css'

const HeadTable = ({data}) => {

	const viewData = (data) => {
		let list = data.map((head, index) => head && (
			<tr key={index}>
				<td>{head.id}</td>
				<td>{head.name}</td>
				{/*<td>{this.addActionData(head)}</td>*/}
			</tr>))
		return list
	}

		return (
			<table id="basic-datatables" className="display table table-striped table-hover" >
				<thead>
					<tr>
						{appConstants.HEADS_COLUMNS.map((item) => <th>{item.title}</th>)}
					</tr>
				</thead>
				<tfoot>
					<tr>
						{appConstants.HEADS_COLUMNS.map((item) => <th>{item.title}</th>)}
					</tr>
				</tfoot>
				<tbody>
					{viewData(data)}
				</tbody>
			</table>
		)

}
export default HeadTable
