import { configureStore } from "@reduxjs/toolkit";
// import { routerMiddleware } from 'react-router-redux'
// import { applyMiddleware, compose } from 'redux'
import rootReducer from './root/rootSlice';
import userReducer from './users/userSlice';
import companyReducer from './companies/companySlice';
import invoiceReducer from './invoices/invoiceSlice';
import transactionReducer from './transactions/transactionSlice';
import cashReducer from './cash/cashSlice';
import lineItemsReducer from './lineitems/lineItemSlice';
import dashboardReducer from './dashboard/dashboardSlice';
import reportReducer from './reports/reportSlice';
import headReducer from './heads/headSlice';
import searchReducer from './search/searchSlice';

export const store = configureStore({
  reducer: {
    root: rootReducer,
    dashboard: dashboardReducer,
    transactions: transactionReducer,
    reports: reportReducer,
    users: userReducer,
    heads: headReducer,
    invoices: invoiceReducer,
    companies: companyReducer,
    lineitems: lineItemsReducer,
    cash: cashReducer,
    search: searchReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
