import React from 'react';
// import { Link } from 'react-router-dom'
import appConstants from '../../constants/constants'
import '../../assets/css/pagination.css'

const LineItemTable = ({ data }) => {

const viewData = (data) => {
	let list = data.map((lineitem, index) => lineitem && (
		<tr key={index}>
			<td>{lineitem.id}</td>
			<td>{lineitem.name}</td>
			<td>{lineitem.head_name}</td>
			{/*<td>{this.addActionData(lineitem)}</td>*/}
		</tr>))
	return list
}

return (<table id="basic-datatables" className="display table table-striped table-hover" >
	<thead>
		<tr>
			{appConstants.LINE_ITEMS_COLUMNS.map((item) => <th>{item.title}</th>)}
		</tr>
	</thead>
	<tfoot>
		<tr>
			{appConstants.LINE_ITEMS_COLUMNS.map((item) => <th>{item.title}</th>)}
		</tr>
	</tfoot>
	<tbody>
		{viewData(data)}
	</tbody>
</table>)
}

export default (LineItemTable)
