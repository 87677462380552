import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveCompany, updateCompany, getCompany } from "../../state/companies/companySlice";
import { preValue } from "../../helpers/tools";
import InputField from "../../shared/form-fields/input";
import { ValidatorForm } from "react-form-validator-core";
import { useLocation, useNavigate } from "react-router";
import Swal from "sweetalert2";
import { errorLog } from "../../state/root/rootSlice";

export const ROUTE_PATHS = ['/ministries/new', '/ministries/edit']

const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast',
  },
  showConfirmButton: false,
  timer: 1500,
  timerProgressBar: true,
})

let invobj = {};
const CompanyForm = () => {
  const location = useLocation();
  const [formData, setFormData] = useState({ roles: [] });
  // const [company, setCompany] = useState(false);
  // const user = JSON.parse(sessionStorage.user);
  const user = sessionStorage.user && JSON.parse(sessionStorage.user);
  // const [error, setError] = useState(undefined);
  const listId = location.search.replace('?id=', "");

  const company = useSelector((state) => state.companies.company)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (listId){ dispatch(getCompany(listId))}
  }, [dispatch, listId])

  const handleChange = (event) => {
    invobj[event.target.name] = event.target.value;
    this.setState({ formData: invobj });
  }

  // const selectHandle = (name, data) => {
  //   if (!_.isEmpty(data)) {
  //     invobj[name] = data[0].value;
  //     setFormData(invobj);
  //   }
  // }

  // const componentDidUpdate = ({ company }) => {
  //   if (!_.isEmpty(company)) {
  //     this.setState({ company })
  //   }
  // }

  const handleFileChange = (event) => {
    let type = event.target.files[0].type;
    if (event.target.files[0] && type) {
      if (type.includes("image/png") || type.includes("image/jpeg") || type.includes("image/jpg")) {
        invobj[event.target.name] = event.target.files[0];
        setFormData(invobj);
      } else {
        // ToastsStore.error("Following File Type Is Not Allowed !");
        Toast.fire({
          icon: 'error',
          title: 'File Type Is Not Allowed!',
        })
      }
    }
  }

  const handleSubmit = ev => {
    ev.preventDefault()

    const form = new FormData()
    form.append('user_id', user?.id)

    for (let [key, val] of Object.entries(formData)) {
      const prop = key == 'logo' ? 'append' : 'set'
      form[prop](key, val)
    }

    if (company) {
      dispatch(updateCompany({ id: listId, data: form })).unwrap()
        .then(() => {
          navigate('/ministries')
        })
        .catch((err) => {
          dispatch(errorLog(err))
        })
    } else {
      dispatch(saveCompany(form)).unwrap()
        .then(() => {
          navigate('/ministries')
        })
        .catch((err) => {
          dispatch(errorLog(err))
        })
    }
  }

  // const validateForm = () => {}

  const {
    name_of_company,
    street,
    country,
    address,
    po_box,
    city,
    island,
    phone_number,
    email_address,
    first_name,
    last_name,
    website,
  } = { ...company };

  return (
    <React.Fragment>
      {/* <ToastsContainer position={ToastsContainerPosition.TOP_RIGHT} store={ToastsStore} /> */}
      <div className="content">
        <div className="page-inner">
          <div className="page-header">
            <h4 className="page-title">Form Ministries</h4>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Ministries</div>
                </div>
                <ValidatorForm ref="form" method="post" onSubmit={(e) => handleSubmit(e)}>
                  <div className="card-body">
                    <div className="form-group form-show-validation row">
                      <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">
                        Name of company <span className="required-label">*</span>
                      </label>
                      <div className="col-lg-4 col-md-9 col-sm-8">
                        <InputField
                          onChange={handleChange}
                          name="name_of_company"
                          value={preValue(formData?.name_of_company, name_of_company)}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          className="form-control"
                          placeholder="name_of_company"
                        />
                      </div>
                    </div>

                    <div className="form-group form-show-validation row">
                      <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">
                        Address <span className="required-label">*</span>
                      </label>
                      <div className="col-lg-4 col-md-9 col-sm-8">
                        <InputField
                          onChange={handleChange}
                          name="address"
                          value={preValue(formData?.address, address)}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          className="form-control"
                          placeholder="address"
                        />
                      </div>
                    </div>

                    <div className="form-group form-show-validation row">
                      <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">
                        Street <span className="required-label">*</span>
                      </label>
                      <div className="col-lg-4 col-md-9 col-sm-8">
                        <InputField
                          onChange={handleChange}
                          name="street"
                          value={preValue(formData?.street, street)}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          className="form-control"
                          placeholder="street"
                        />
                      </div>
                    </div>

                    <div className="form-group form-show-validation row">
                      <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">
                        City <span className="required-label">*</span>
                      </label>
                      <div className="col-lg-4 col-md-9 col-sm-8">
                        <InputField
                          onChange={handleChange}
                          name="city"
                          value={preValue(formData?.city, city)}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          className="form-control"
                          placeholder="city"
                        />
                      </div>
                    </div>
                    <div className="form-group form-show-validation row">
                      <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">
                        Po box <span className="required-label">*</span>
                      </label>
                      <div className="col-lg-4 col-md-9 col-sm-8">
                        <InputField
                          onChange={handleChange}
                          name="po_box"
                          value={preValue(formData?.po_box, po_box)}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          className="form-control"
                          placeholder="po_box"
                        />
                      </div>
                    </div>

                    <div className="form-group form-show-validation row">
                      <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">
                        Country <span className="required-label">*</span>
                      </label>
                      <div className="col-lg-4 col-md-9 col-sm-8">
                        <InputField
                          onChange={handleChange}
                          name="country"
                          value={preValue(formData?.country, country)}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          className="form-control"
                          placeholder="country"
                        />
                      </div>
                    </div>

                    <div className="form-group form-show-validation row">
                      <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">
                        Island <span className="required-label">*</span>
                      </label>
                      <div className="col-lg-4 col-md-9 col-sm-8">
                        <InputField
                          onChange={handleChange}
                          name="island"
                          value={preValue(formData?.island, island)}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          className="form-control"
                          placeholder="island"
                        />
                      </div>
                    </div>

                    <div className="form-group form-show-validation row">
                      <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">
                        Phone Number <span className="required-label">*</span>
                      </label>
                      <div className="col-lg-4 col-md-9 col-sm-8">
                        <InputField
                          onChange={handleChange}
                          name="phone_number"
                          value={preValue(formData?.phone_number, phone_number)}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          className="form-control"
                          placeholder="phone_number"
                        />
                      </div>
                    </div>

                    <div className="form-group form-show-validation row">
                      <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">
                        Email Address <span className="required-label">*</span>
                      </label>
                      <div className="col-lg-4 col-md-9 col-sm-8">
                        <InputField
                          onChange={handleChange}
                          name="email_address"
                          value={preValue(formData?.email_address, email_address)}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          className="form-control"
                          placeholder="email_address"
                        />
                      </div>
                    </div>

                    <div className="form-group form-show-validation row">
                      <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">
                        First Name <span className="required-label">*</span>
                      </label>
                      <div className="col-lg-4 col-md-9 col-sm-8">
                        <InputField
                          onChange={handleChange}
                          name="first_name"
                          value={preValue(formData?.first_name, first_name)}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          className="form-control"
                          placeholder="first_name"
                        />
                      </div>
                    </div>

                    <div className="form-group form-show-validation row">
                      <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">
                        Last Name <span className="required-label">*</span>
                      </label>
                      <div className="col-lg-4 col-md-9 col-sm-8">
                        <InputField
                          onChange={handleChange}
                          name="last_name"
                          value={preValue(formData?.last_name, last_name)}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          className="form-control"
                          placeholder="last_name"
                        />
                      </div>
                    </div>

                    <div className="form-group form-show-validation row">
                      <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">
                        Logo
                      </label>
                      <div className="col-lg-4 col-md-9 col-sm-8">
                        <InputField
                          type="file"
                          onChange={handleFileChange}
                          name="avatar"
                          className="form-control"
                          placeholder="avatar"
                        />
                      </div>
                    </div>

                    <div className="form-group form-show-validation row">
                      <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">
                        Website <span className="required-label">*</span>
                      </label>
                      <div className="col-lg-4 col-md-9 col-sm-8">
                        <InputField
                          onChange={handleChange}
                          name="website"
                          value={preValue(formData?.website, website)}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          className="form-control"
                          placeholder="website"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-action">
                    <div className="row">
                      <div className="col-md-12">
                        <input className="btn btn-success me-1" type="submit" value="Submit" />
                        <button className="btn btn-danger" onClick={() => navigate('/companies')}>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CompanyForm
