import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import EmptyTable from "../../shared/empty-table";
import Loader from "../../shared/loader";
// import { downloadInvoices } from "../../state/invoices/invoiceSlice";
// import { downloadTransactions } from "../../state/transactions/transactionSlice";
import { getData } from "../../state/search/searchSlice";
import appConstants from "../../constants/constants";
import InvoiceTable from "./_invoice.table";
import { useLocation, useNavigate } from "react-router";
import PageIndex from "../../shared/pageIndex";

const SearchInvoice = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [maxPages, setMaxPages] = useState(1);
  const [dataIndexed, setDataIndexed] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [sortedBy, setSortedBy] = useState('created_at');
  const [sortByLatest, setSortByLatest] = useState(true);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector(state => state.search.data);
  const loading = useSelector(state => state.root.loading);
  // const search_target = useSelector(state => state.search.search_target);

  // Data Sorter by Both column data values and latest or oldest record (alphabetical order or opposite)
  const sortByFunction = useCallback(() => {
    const sortedFunc = (column, _column_2) => {
      if (_column_2) {
        if (sortByLatest) return dataFiltered?.toSorted(function(a, b) {
          return (a[column][_column_2]===null)-(b[column][_column_2]===null) || +(a[column][_column_2]>b[column][_column_2])||-(a[column][_column_2]<b[column][_column_2]);
        });
        return dataFiltered?.toSorted(function(a, b) {
          return (a[column][_column_2]===null)-(b[column][_column_2]===null) || -(a[column][_column_2]>b[column][_column_2])||+(a[column][_column_2]<b[column][_column_2]);
        });
      } else {
        if (sortByLatest) return dataFiltered?.toSorted(function(a, b) {
          return (a[column]===null)-(b[column]===null) || +(a[column]>b[column])||-(a[column]<b[column]);
        });
        return dataFiltered?.toSorted(function(a, b) {
          return (a[column]===null)-(b[column]===null) || -(a[column]>b[column])||+(a[column]<b[column]);
        });
      }
    }

    switch(sortedBy) {
      case 'created_at':
        if (sortByLatest) return dataFiltered?.toSorted((a, b) => new Date(b?.created_at) - new Date(a?.created_at))
        return dataFiltered?.toSorted((a, b) => new Date(a?.created_at) - new Date(b?.created_at))
      case 'invoice_reference':
        return sortedFunc('invoice_reference')
      case 'reference_code':
        return sortedFunc('reference_code')
      case 'location':
        return sortedFunc('location')
      case 'revenue_line_item':
        return sortedFunc('revenue_line_item', 'long_desc')
      case 'email':
        return sortedFunc('email')
      case 'first_name':
        return sortedFunc('first_name')
      case 'last_name':
        return sortedFunc('last_name')
      case 'amount':
        if (sortByLatest) return dataFiltered?.toSorted((a, b) => a?.amount - b?.amount)
        return dataFiltered?.toSorted((a, b) => b?.amount- a?.amount)
      case 'status':
        return sortedFunc('status')
      case 'paid_date':
        const distantFuture = new Date(8640000000000000)

        if (sortByLatest) {
          return dataFiltered?.toSorted((a, b) => {
            const a_date = a?.status == 'paid' ? a.updated_at : distantFuture
            const b_date = b?.status == 'paid' ? b.updated_at : distantFuture

            return new Date(b_date) - new Date(a_date)
          })
        }
        return dataFiltered?.toSorted((a, b) => {
          const a_date = a?.status == 'paid' ? a.updated_at : distantFuture
          const b_date = b?.status == 'paid' ? b.updated_at : distantFuture

          return new Date(a_date) - new Date(b_date)
        })
      // case 'paid_date':
      //   const distantFuture = new Date(8640000000000000)

      //   if (sortByLatest) {
      //     return dataFiltered?.toSorted((a, b) => {
      //       const a_date = a?.transaction ? a.transaction.updated_at : distantFuture
      //       const b_date = b?.transaction ? b.transaction.updated_at : distantFuture

      //       return new Date(b_date) - new Date(a_date)
      //     })
      //   }
      //   return dataFiltered?.toSorted((a, b) => {
      //     const a_date = a?.transaction ? a.transaction.updated_at : distantFuture
      //     const b_date = b?.transaction ? b.transaction.updated_at : distantFuture

      //     return new Date(a_date) - new Date(b_date)
      //   })
      case 'void':
        return sortedFunc('void')
      default:
        if (sortByLatest) return dataFiltered?.toSorted((a, b) => new Date(b?.created_at) - new Date(a?.created_at))
        return dataFiltered?.toSorted((a, b) => new Date(a?.created_at) - new Date(b?.created_at))
    }
  }, [dataFiltered, sortedBy, sortByLatest])

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = {
      query_type: params.get('query_type'),
      query: params.get('query'),
      search_target: 'invoice',
    };

    if (!query.query_type || !query.query) {
      navigate('/search');
    }

    dispatch(getData(query));
  }, [dispatch, navigate, location])

  useEffect(() => {
    const startingIndex = (page - 1) * perPage;
    const endingIndex = startingIndex + perPage;
    if (dataFiltered && dataFiltered.length) {
      let dataFilterSorted = sortByFunction();
      // console.log('DataFilterSorted', dataFilterSorted);

      if (dataFilterSorted) {
        setDataIndexed(dataFilterSorted?.map((x, i) => {
          if(i < endingIndex && i >= startingIndex){ return x }
          else return null
        }))
      }
    } else {
      let dataSorted = sortByFunction();
      // console.log('dataSorted', dataSorted);

      if (dataSorted) {
        setDataIndexed(dataSorted?.map((x, i) => {
          if(i < endingIndex && i >= startingIndex){ return x }
          else return null
        }))
      }
    }
  }, [perPage, page, data, dataFiltered, sortByFunction])

  // useEffect(() => {
  //   const startingIndex = (page - 1) * perPage;
  //   const endingIndex = startingIndex + perPage;
  //   dataFiltered.length ?
  //   setDataIndexed(dataFiltered?.map((x, i) => {
  //     if(i < endingIndex && i >= startingIndex){ return x }
  //     else return null
  //   }))
  //   :
  //   setDataIndexed(data?.map((x, i) => {
  //     if(i < endingIndex && i >= startingIndex){ return x }
  //     else return null
  //   }))
  // }, [perPage, page, data, dataFiltered])

  const table = !loading && dataIndexed && dataIndexed.length ? (
      <InvoiceTable
        data={dataIndexed}
        // fullData={data}
        sortedBy={sortedBy}
        setSortedBy={setSortedBy}
        sortByLatest={sortByLatest}
        setSortByLatest={setSortByLatest}
        // downloadInvoices={downloadInvoices}
      />
    ) : (
      <EmptyTable columns={appConstants.INVOICES_COLUMNS} />
    );

  return (
    <React.Fragment>
      <Loader loading={loading} />

      <div className="content">
        <div className="page-inner">
          <div className="page-header"></div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <h4 className="card-title">Results</h4>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">{table}</div>
                </div>
                <div className='card-footer'>
                  <PageIndex data={data} dataFiltered={dataFiltered} setDataFiltered={setDataFiltered} page={page} setPage={setPage} maxPages={maxPages} setMaxPages={setMaxPages} perPage={perPage} setPerPage={setPerPage}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

// const mapStateToProps = ({ search }) => ({
//   data: search.data,
//   error: search.error,
//   success_message: search.success_message,
//   success: search.success,
//   search_target: search.search_target,
//   loading: search.loading,
// });

export default SearchInvoice
