export default [
  'New Providence (Nassau)',
  'Grand Bahama (Freeport)',
  'Abaco',
  'Eleuthera',
  'Exuma',
  'Acklins',
  'Andros',
  'Berry Islands',
  'Bimini',
  'Cat Island',
  'Crooked Island',
  'Inagua',
  'Long Island',
  'Mayaguana',
  'Ragged Island',
  'Rum Cay',
  'San Salvador',
].map(i => ({ value: i, label: i }))