

import React, { useState } from 'react'
// import { useSelector } from 'react-redux'
import { ValidatorForm } from 'react-form-validator-core'
// import _ from 'lodash'

import { preValue } from '../../helpers/tools'
import InputField from '../../shared/form-fields/input'
// import { saveCashPayment, getAllRevenue } from '../../state/cash/cashSlice'

let invobj = {}
const PayeeForm = () => {
	const [formData, setFormData] = useState({});
	// const [payee, setPayee] = useState({});
	const payee = useState({});

	// const line_items = useSelector(state => state.cash.line_items);

	// useEffect(() => {
	// 	//dispatch(getAllRevenue());
	// }, [])

	const handleChange = (event) => {
		invobj[event.target.name] = event.target.value
    setFormData(invobj);
	}

	// const selectHandle = (name, data) => {
	// 	if (!_.isEmpty(data)) {
	// 		invobj[name] = data[0].value
	// 		setFormData(invobj);
	// 	}
	// }

	// componentDidUpdate(nextProps) {
	// 	if (!_.isEmpty(nextProps.line_items)) {
	// 		this.setState({ line_items: nextProps.line_items })
	// 	}
	// }

	const handleSubmit = (event) => {
		event.preventDefault()
	}

	// const validateForm = () => {}

	const { invoice_number, first_name, last_name, email, value, status, type } = payee

	return (
		<React.Fragment>
			<div className="content">
				<div className="page-inner">
					<div className="page-header">
						<h4 className="page-title">Payment</h4>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="card">
								<div className="card-header">
									<div className="card-title">Payee</div>
								</div>
								<ValidatorForm
									ref="form"
									method="post"
									onSubmit={(e) => handleSubmit(e)}
								>
									<div className="card-body">
										<div className="form-group form-show-validation row">
											<label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">Invoice Number <span className="required-label">*</span></label>
											<div className="col-lg-4 col-md-9 col-sm-8">
												<InputField
													onChange={handleChange}
													name="invoice_number"
													value={preValue(formData?.invoice_number, invoice_number)}
													validators={['required']}
													errorMessages={['This field is required', "This field should be numeric"]}
													className="form-control"
													placeholder="Invoice Number"
												/>
											</div>
										</div>
										<div className="form-group form-show-validation row">
											<label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">First Name <span className="required-label">*</span></label>
											<div className="col-lg-4 col-md-9 col-sm-8">
												<InputField
													onChange={handleChange}
													name="first_name"
													value={preValue(formData?.first_name, first_name)}
													validators={['required']}
													errorMessages={['This field is required']}
													className="form-control"
													placeholder="First Name"
												/>
											</div>
										</div>

										<div className="form-group form-show-validation row">
											<label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">Last Name <span className="required-label">*</span></label>
											<div className="col-lg-4 col-md-9 col-sm-8">
												<InputField
													onChange={handleChange}
													name="last_name"
													value={preValue(formData?.last_name, last_name)}
													validators={['required']}
													errorMessages={['This field is required']}
													className="form-control"
													placeholder="Last Name"
												/>
											</div>
										</div>

										<div className="form-group form-show-validation row">
											<label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">Email <span className="required-label">*</span></label>
											<div className="col-lg-4 col-md-9 col-sm-8">
												<InputField
													onChange={handleChange}
													name="email"
													value={preValue(formData?.email, email)}
													validators={['required', "isEmail"]}
													errorMessages={['This field is required', "Invalid email"]}
													className="form-control"
													placeholder="Email"
												/>
											</div>
										</div>

										<div className="form-group form-show-validation row">
											<label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">Value <span className="required-label">*</span></label>
											<div className="col-lg-4 col-md-9 col-sm-8">
												<InputField
													onChange={handleChange}
													name="value"
													value={preValue(formData?.value, value)}
													validators={['required', 'isFloat']}
													errorMessages={['This field is required', "This field should be decimal"]}
													className="form-control"
													placeholder="Value"
												/>
											</div>
										</div>

										<div className="form-group form-show-validation row">
											<label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">Status <span className="required-label">*</span></label>
											<div className="col-lg-4 col-md-9 col-sm-8">
												<InputField
													onChange={handleChange}
													name="status"
													value={preValue(formData?.status, status)}
													validators={['required']}
													errorMessages={['This field is required']}
													className="form-control"
													placeholder="Status"
												/>
											</div>
										</div>

										<div className="form-group form-show-validation row">
											<label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-end">Type <span className="required-label">*</span></label>
											<div className="col-lg-4 col-md-9 col-sm-8">
												<InputField
													onChange={handleChange}
													name="type"
													value={preValue(formData?.type, type)}
													validators={['required']}
													errorMessages={['This field is required']}
													className="form-control"
													placeholder="Type"
												/>
											</div>
										</div>
									</div>
									<div className="card-action">
										<div className="row">
											<div className="col-md-12">
												<input className="btn btn-success me-1" type="submit" value="Submit" />
												{/*<a className="btn btn-danger" onClick={()=> navigate('/inventory')} href="javascript:void(0)">Cancel</a>*/}
											</div>
										</div>
									</div>
								</ValidatorForm>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}


// function mapStateToProps(state) {
// 	return {
// 		error: state.cash.error,
// 		success_message: state.cash.success_message,
// 		line_items: state.cash.line_items

// 	}
// }


export default PayeeForm
