import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios";
import { encryptRsakey } from '../../helpers/rsa-encryption'
import moment from "moment";

const format = (d) => {
  return moment(d).format("YYYY-MM-DD");
}

const headers = () => {
  let user = sessionStorage.getItem("user");
  user = JSON.parse(user);
  return {
    "Content-Type": "application/json",
    "Authorization": user && user.auth_token
  };
}

const initialState = {
  authenticated   : false,
  loading         : false,
  success         : false,
  error           : null,
  success_message : null,
  transactions    : [],
  transaction     : null,
}

const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    errorLog: (state, action) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.loading = false;
      state.success = false;
      state.success_message = undefined;
      state.error = undefined;
    },
    stopLoader: (state) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
    // Get Transactions
      .addCase(getTransactions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.error = undefined
        state.transactions = action.payload.data.transactions;
        // console.log(action);
      })
      .addCase(getTransactions.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.transactions = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Get Transaction
      .addCase(getTransaction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTransaction.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = undefined;
        state.error = undefined
        state.transactions = action.payload.data;
        state.transaction = action.payload.data;
        // console.log(action);
      })
      .addCase(getTransaction.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.transactions = null;
        state.transaction = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Save Transaction
      .addCase(saveTransaction.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveTransaction.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Payment Successful';
        state.error = undefined
        state.transaction = action.payload.data;
        // console.log(action);
      })
      .addCase(saveTransaction.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.transactions = null;
        state.transaction = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Void Transactions
      .addCase(voidTransaction.pending, (state) => {
        state.loading = true;
      })
      .addCase(voidTransaction.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Transaction successfully voided';
        state.error = undefined
        state.transaction = action.payload.data;
        // console.log(action);
      })
      .addCase(voidTransaction.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.transactions = null;
        state.transaction = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
    // Update Transactions
      .addCase(updateTransaction.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTransaction.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.success_message = 'Transaction successfully updated';
        state.error = undefined
        state.transaction = action.payload.data;
        // console.log(action);
      })
      .addCase(updateTransaction.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.transactions = null;
        state.transaction = null;
        // console.log('error', action);
        state.error = action.payload.error;
      })
  }
})

export const { clearError, stopLoader, errorLog } = transactionSlice.actions
export default transactionSlice.reducer;

 
export const getTransactions = createAsyncThunk(
  'transactions/getTransactions',
  async ({start, end}, { rejectWithValue }) => {
    // console.log('GET Transactions');
    try {
      const startDate = `start_date=${format(start)}`
      const endDate = `&end_date=${format(end)}`
      // console.log(`${process.env.REACT_APP_API_HOST}/api/v1/invoices?${startDate}${endDate}`);
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/transactions?${startDate}${endDate}`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const getTransaction = createAsyncThunk(
  'transactions/getTransaction',
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/transactions/${id}`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const voidTransaction = createAsyncThunk(
  'transactions/voidTransaction',
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/transactions/void/${id}`, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const saveTransaction = createAsyncThunk(
  'transactions/saveTransaction',
  async (data, { rejectWithValue }) => {
    delete data["publisher-name"];
    let res;

    try {
      res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/fetch_rsa_key`, { headers: headers() })
      // console.log('Fetch RSA KEY', res)
      if (!res.data.rsa_key) return rejectWithValue({error: 'No RSA KEY Found!'})
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
    const { public_key, rsa_token } = res.data.rsa_key;

    data["card-number"] = await encryptRsakey(public_key, data["card-number"]);
    data["card-cvv"] = await encryptRsakey(public_key, data["card-cvv"]);
    data["card-name"] = await encryptRsakey(public_key, data["card-name"]);
    data["card-exp"] = await encryptRsakey(public_key, data["card-exp"]);
    data["card-amount"] = await encryptRsakey(public_key, data["card-amount"]);
    data["rsa_token"] = rsa_token;

    // Trim First & Last Name & Invoice Number
    data.first_name = data.first_name?.trim()
    data.last_name = data.last_name?.trim()
    data.invoice_number = data.invoice_number?.trim()

    try {
      res = await axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/transactions`, data, { headers: headers() });

      if (res.data && !res.data.error) {
        if (res.data.status === 400) {
          return rejectWithValue({error: `Transaction Failed! (${res?.data?.error})`})
        }
        return res
      } else {
        return rejectWithValue({error: `Transaction Failed! (${res.data.error})`})
      }
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const updateTransaction = createAsyncThunk(
  'transactions/updateTransaction',
  async ({id, data}, { rejectWithValue }) => {
    try {
      const res = await axios.patch(`${process.env.REACT_APP_API_HOST}/api/v1/transactions/${id}`,
        { transaction: data }, { headers: headers() })
      // console.log(res)
      return res
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const downloadTransactions = (fileurl) => {
  fetch(process.env.REACT_APP_API_HOST + fileurl, {
    method: "GET",
    headers: headers(),
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileurl.split("?").slice(-1)[0]}.pdf`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
}
