import React from 'react'

import { Link } from 'react-router-dom'

const SidePane = ({user}) => {
  // const [open, setOpen] = useState(false);
  const roles = new Set(user?.roles?.map(r => r.name))

  const links = [
    {
      path: '/dashboard',
      icon: 'home',
      badge: true,
      allow: ['agency_accountant'],
    },
    {
      path: '/invoices',
      icon: 'file-invoice-dollar',
      badge: true,
      allow: [
        'treasury',
        'revenue',
        'agency_cashier',
        'agency_accountant',
        'agency_invoice',
        'read',
      ],
    },
    {
      path: '/transactions',
      icon: 'dollar-sign',
      allow: [
        'treasury',
        'revenue',
        'agency_cashier',
        'agency_accountant',
        'read',
      ],
    },
    {
      path: '/cash',
      icon: 'money-bill-alt',
      allow: ['agency_cashier'],
    },
    {
      path: '/cheque',
      icon: 'money-check-alt',
      allow: ['agency_cashier'],
    },
    {
      path: '/credit-card',
      icon: 'credit-card',
      allow: ['agency_cashier'],
    },
    {
      path: '/swipe',
      icon: 'cut',
      allow: ['agency_cashier'],
    },
    {
      path: process.env.REACT_APP_SANDDOLLAR_PYMT || '/',
      label: 'Sand Dollar',
      icon: 'hourglass-start',
      allow: ['agency_cashier'],
    },
    // {
    //   path: '/report',
    //   icon: 'chart-line',
    //   allow: ['revenue', 'treasury'],
    // },
    // {
    // 	path: '/line-items',
    // 	icon: 'user',
    // 	allow: ['treasury', 'revenue']
    // },
    {
      path: '/revenue-line-items',
      icon: 'list',
      allow: [
        'treasury',
        'revenue',
        'agency_accountant',
        'agency_invoice',
        'technology'
      ],
    },
    {
      path: '/replay-email',
      icon: 'envelope',
      allow: [
        'agency_cashier',
        'agency_invoice',
        'technology',
        'revenue'
      ],
    },
    {
      path: '/replay-sms',
      icon: 'comment',
      allow: [
        'agency_cashier',
        'agency_invoice',
        'technology',
        'revenue'
      ],
    },
    {
      path: '/users',
      icon: 'users',
      allow: ['technology', 'revenue'],
    },
    {
      path: '/search',
      icon: 'search',
      allow: [
        'treasury',
        'revenue',
        'agency_cashier',
        'agency_accountant',
        'read'
      ],
    },
  ].map((link, key) => {
    const props = { ...link, key, userRoles: roles }
    return <NavLink {...props} />
  })

  return user ? (
    <div className='sidebar sidebar-style-2'>
      <div className='sidebar-wrapper scrollbar scrollbar-inner'>
        <div className='sidebar-content'>
          <div className='user'>
            <div className='info'>
              <a
                data-toggle='collapse'
                href='#collapseExample'
                aria-expanded='true'
                style={{textDecoration: 'none'}}
              >
                <span>{user?.first_name} {user?.last_name}</span>
              </a>
            </div>
          </div>

          <ul className='nav nav-primary'>
            {links}
          </ul>
        </div>
      </div>
    </div>
  ) : null
}

const matches = (path) => {
  return window.location.pathname.startsWith(path) ? 'active' : ''
}

function initialCaps (str) {
  if (str == 'replay-sms') {
    return 'Replay SMS'
  } else {
    return str
      .trim()
      .split(/[ _-]/)
      .map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
      .join(' ')
  }
}

const NavLink = ({ path, icon, label, badge, allow, userRoles }) => {
  if (allow && !allow.some((role) => userRoles.has(role))) return null

  const linkCls = ['nav-item', matches(path)].join(' ')
  const iconCls = /^fas? fa-/.test(icon) ? icon : `fas fa-${icon}`
  const name = label || initialCaps(path.slice(path.lastIndexOf('/') + 1))

  if (/^https?/.test(path)) return (
    <li className={linkCls} key={path}>
      <a href={path} target='blank'>
        <i className={iconCls}></i>
        <p>{name}</p>
        {badge === true ? <span className='badge badge-info'></span> : null}
      </a>
    </li>
  )

  return (
    <li className={linkCls} key={path}>
      <Link to={path} style={{textDecoration: 'none'}}>
        <i className={iconCls}></i>
        <p>{name}</p>
        {badge === true ? <span className='badge badge-info'></span> : null}
      </Link>
    </li>
  )
}

export default SidePane
