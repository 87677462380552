import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../shared/loader';
import appConstants from '../constants/constants'
import { saveTransaction } from '../state/transactions/transactionSlice';
// import CreditCardInput from 'react-credit-card-input';
import { PaymentInputsContainer } from 'react-payment-inputs';
import { useNavigate } from 'react-router';
import { errorLog } from '../state/root/rootSlice';
var creditCardType = require('credit-card-type');

let obj = {}

const Payment = () => {
  const [isValid, setIsValid] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCVC: false,
  });
  const [formData, setFormData] = useState({});
  const loading = useSelector((state) => state.root.loading);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCardNumberChange = (event) => {
    let type = creditCardType(event.target.value)
    obj['card-number'] = event.target.value;
    obj['card-name'] = type[0].type;
    let isValid = Object.assign({}, this.state.isValid)
    isValid["cardNumber"] = true
    setFormData(obj);
    setIsValid(isValid);
  }
  const handleCardExpiryChange = (event) => {
    obj['card-expiry'] = event.target.value;
    let isValid = Object.assign({}, this.state.isValid)
    isValid["cardExpiry"] = true
    setFormData(obj);
    setIsValid(isValid);
  }
  const handleCardCVCChange = (event) => {
    obj['card-cvv'] = event.target.value;
    let isValid = Object.assign({}, this.state.isValid)
    isValid["cardCVC"] = true
    setFormData(obj);
    setIsValid(isValid);
  }
  // const handleError = (e) => {
  //   let isValid = Object.assign({}, this.state.isValid)
  //   isValid[e.inputName] = false
  //   this.setState({ error: e.error, isValid: isValid })
  // }
  const inputChangeHandler = (event) => {
    obj[event.target.name] = event.target.value;
    setFormData(obj);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!Object.values(isValid).includes(false)) {
      dispatch(saveTransaction({
        ...formData,
        // 'company_id': 1,
        'publisher-name': 'pnpdemo',
        'api_key': appConstants.api_key,
      })).unwrap()
        .then(() => {
          navigate('/transactions')
        })
        .catch((err) => {
          dispatch(errorLog(err))
        })
    }
  }

  if (loading) return (
    <Loader loading={true} />
  )

  return (
    <div className="login">
      <div className="wrapper wrapper-login ">
        <div className="container container-login animated fadeIn">
          <h3 className="text-center">Payment</h3>
          <div className="login-form">
            <form onSubmit={(event) => handleSubmit(event)} method="post">
              <div className="form-group form-floating-label">
                <input id="card-amount" name="card-amount" onChange={(event) => inputChangeHandler(event)} type="number" className="form-control input-border-bottom" required />
                <label htmlFor="card-amount" className="placeholder">Amount</label>
              </div>
              {/* <CreditCardInput
                cardNumberInputProps={{ value: null, onChange: this.handleCardNumberChange.bind(this) }}
                cardExpiryInputProps={{ value: null, onChange: this.handleCardExpiryChange.bind(this) }}
                cardCVCInputProps={{ value: null, onChange: this.handleCardCVCChange.bind(this) }}
                onError={(e) => this.handleError(e)}
                fieldClassName="input"
              /> */}
              {/* <div className='input'>
                <input {...getCardNumberProps({ onChange: this.handleCardNumberChange.bind(this) })} value={null} />
                <input {...getExpiryDateProps({ onChange: this.handleCardExpiryChange.bind(this) })} value={null} />
                <input {...getCVCProps({ onChange: this.handleCardCVCChange.bind(this) })} value={null} />
                {this.state.payments.meta.isTouched && this.state.payments.meta.error && <span>Error: {this.state.payments.meta.error}</span>}
              </div> */}
              <PaymentInputsContainer>
                {({ meta, getCardNumberProps, getExpiryDateProps, getCVCProps }) => (
                  <div className='input'>
                    <input {...getCardNumberProps({ onChange: handleCardNumberChange })} value={null} />
                    <input {...getExpiryDateProps({ onChange: handleCardExpiryChange })} value={null} />
                    <input {...getCVCProps({ onChange: handleCardCVCChange })} value={null} />
                    {meta.isTouched && meta.error && <span>Error: {meta.error}</span>}
                  </div>
                )}
              </PaymentInputsContainer>
              <div className="form-action mb-3">
                <input type="submit" className="btn btn-primary btn-rounded btn-login" value="Pay" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Payment
